/* eslint-disable no-dupe-keys */
import TooltipComp2 from "../TooltipComp2";
import { Line } from "@ant-design/plots";

const CircleLine = ({ color, Data, TooltipUnit }) => {
  const data = [
    {
      year: "06.10.22",
      value: 3,
    },
    {
      year: "07.10.22",
      value: 4,
    },
    {
      year: "08.10.22",
      value: 3.5,
    },
    {
      year: "09.10.22",
      value: 5,
    },
    {
      year: "10.10.22",
      value: 4.9,
    },
    {
      year: "11.10.22",
      value: 6,
    },
    {
      year: "12.10.22",
      value: 7,
    },
  ];
  const config = {
    data: Data ? Data : data,
    xField: Data ? "time" : "year",
    yField: "value",
    color: color ? color : "#0099FF",
    label: false,
    meta: {
      value: {
        alias: TooltipUnit,
      },
    },
    point: {
      size: 7,
      shape: "circle",
      style: {
        fill: "white",
        stroke: color ? color : "#0099FF",
        lineWidth: 4,
      },
    },
    lineStyle: {
      style: {
        lineWidth: 60,
      },
    },
    tooltip: {
      showMarkers: false,
    },

    xAxis: {


      grid: {
        line: {
          style: {
            stroke: "black",
            lineWidth: 0,
          },
        },
      },
    },

    tooltip: {
      domStyles: {
        "g2-tooltip": {
          background: "white",
          color: "black",
          opacity: 1,
          borderRadius: "8px",
        },
      },
      customContent: (title, data) => {
        return (
          <TooltipComp2
            data={data}
            withoutTitle
            units={["€"]}
            fields={["Earnings"]}
            hollow
          />
        );
      },
    },

    yAxis: {
      title: {
        text: "€",
        style: {
          stroke: "#000000",
          fontSize: 12,
          color: "#000000",
          letterSpacing: "1px",
          fontWeight: 300,
          opacity: 0.9,
          shadowBlur: 0,
          strokeOpacity: 0,
        },
      },
      grid: {
        line: {
          style: {
            stroke: "black",
            lineWidth: 0.1,
          },
        },
        alignTick: true,
        closed: true,
      },
    },
  };
  return <Line {...config} />;
};

export default CircleLine;
