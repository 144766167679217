import { privateAPI, attachToken } from "../../API";
import { SET_CONTROL_NODES, SET_DATA_CHANNELS } from "../types/homeTypes";

export const createLog = async (payload) => {
  try {
    attachToken();
    await privateAPI.post(`/logs/create`, payload);
  } catch (err) {
    console.log("err", err);
  }
};

export const getElectricConsumption = async () => {
  try {
    attachToken();
    const res = await privateAPI.get(`/home/get-electric-consumption`);
    if (res) {
      return res.data;
    }
  } catch (err) {
    console.log("err", err);
  }
};

export const getControlNodes = (payload) => {
  return async (dispatch) => {
    try {
      attachToken();
      const res = await privateAPI.post(`/home/get-control-nodes`, payload);

      if (res) {
        dispatch({
          type: SET_CONTROL_NODES,
          payload: res.data.data,
        });
        return res.data.data;
      }
    } catch (err) {
      console.log("err", err);
    }
  };
}

export const getDataChannels = (payload) => {
  return async (dispatch) => {
    try {
      attachToken();
      const res = await privateAPI.post(`/home/get-data-channels`, payload);

      if (res) {
        dispatch({
          type: SET_DATA_CHANNELS,
          payload: res.data.data,
        });
        return res.data.data;
      }
    } catch (err) {
      console.log("err", err);
    }
  };
}

export const getEnergyCost = async (payload) => {
  try {
    attachToken();
    const res = await privateAPI.post(`/home/get-energy-cost`, payload);
    if (res) {
      return res.data;
    }
  } catch (err) {
    console.log("err", err);
  }
};

export const getElectricConsumptionGraph = async (payload) => {
  try {
    attachToken();
    const res = await privateAPI.post(
      `/home/get-total-electric-consumption`,
      payload
    );
    if (res) {
      return res.data;
    }
  } catch (err) {
    console.log("err", err);
  }
};

export const getRoundChartData = async (payload) => {
  try {
    attachToken();
    const res = await privateAPI.post(
      `/home/get-round-electric-consumption`,
      payload
    );
    if (res) {
      return res.data;
    }
  } catch (err) {
    console.log("err", err);
  }
};

export const getRoundChartDataSubchannels = async (payload) => {
  try {
    attachToken();
    const res = await privateAPI.post(
      `/home/get-round-electric-consumption-subchannels`,
      payload
    );
    if (res) {
      return res.data;
    }
  } catch (err) {
    console.log("err", err);
  }
};

export const getHeatmap = async (payload) => {
  try {
    attachToken();
    const res = await privateAPI.post(`/home/get-heat-map`, payload);
    if (res) {
      return res.data;
    }
  } catch (err) {
    console.log("err", err);
  }
};

export const getSubChannelHeatmap = async (payload) => {
  try {
    attachToken();
    const res = await privateAPI.post(
      `/home/get-heat-map-subchannels`,
      payload
    );
    if (res) {
      console.log("response : ", res.data);
      return res.data;
    }
  } catch (err) {
    console.log("err", err);
  }
};

export const getPvProduction = async (payload) => {
  try {
    attachToken();
    const res = await privateAPI.post(`/home/get-pv-production`, payload);
    if (res) {
      return res.data;
    }
  } catch (err) {
    console.log("err", err);
  }
};

export const getPvProductionOnClick = async (payload) => {
  try {
    attachToken();
    const res = await privateAPI.post(
      `/home/get-pv-production-consumption`,
      payload
    );
    if (res) {
      return res.data;
    }
  } catch (err) {
    console.log("err", err);
  }
};

export const getPowerQualtiy = async () => {
  try {
    attachToken();
    const res = await privateAPI.get(`/home/get-power-qualtiy`);
    if (res) {
      return res.data;
    }
  } catch (err) {
    console.log("err", err);
  }
};
