import { privateAPI, attachToken } from "../../API";

export const getSolarProductionLivePage = async () => {
  try {
    attachToken();
    const res = await privateAPI.get(`/live/get-solar-production`);

    if (res) {
      return res.data;
    }
  } catch (err) {
    console.log("err", err);

  }
};

export const getCo2Consumption = async () => {
  try {
    attachToken();
    const res = await privateAPI.get(`/live/get-co2-consumption`);

    if (res) {
      return res.data;
    }
  } catch (err) {
    console.log("err", err);

  }
};

export const getHeatConsumption = async () => {
  try {
    attachToken();
    const res = await privateAPI.get(`/live/get-heat-consumption`);

    if (res) {
      return res.data;
    }
  } catch (err) {
    console.log("err", err);

  }
};
export const getWaterConsumption = async () => {
  try {
    attachToken();
    const res = await privateAPI.get(`/live/get-water-consumption`);

    if (res) {
      return res.data;
    }
  } catch (err) {
    console.log("err", err);

  }
};
export const getBatteryLevels = async () => {
  try {
    attachToken();
    const res = await privateAPI.get(`/live/get-battery-levels`);

    if (res) {
      return res.data;
    }
  } catch (err) {
    console.log("err", err);

  }
};
export const getPvProductionLivePage = async () => {
  try {
    attachToken();
    const res = await privateAPI.get(`/live/get-pv-production`);

    if (res) {
      return res.data;
    }
  } catch (err) {
    console.log("err", err);

  }
};
export const getCurrentlySave = async () => {
  try {
    attachToken();
    const res = await privateAPI.get(`/live/get-currently-save`);

    if (res) {
      return res.data;
    }
  } catch (err) {
    console.log("err", err);

  }
};
