import React from "react";
import { Typography, Modal } from "antd";
import PvProductionChart from "../charts/PvProductionChart";
import { useTranslation } from "react-i18next";

const App = ({
  pvProduction,
  data,
  showModal,
  isModalOpen,
  handleOk,
  handleCancel,
  pvFilter,
}) => {
  const { t } = useTranslation()
  return (
    <>
      <Typography.Title onClick={showModal} className="title" level={5}>
        {pvProduction ? t('pv_production') : "Power Quality"}
      </Typography.Title>
      <Modal
        footer={false}
        width="80%"
        centered
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        maskClosable={true}
        closeIcon={<></>}
        mask={false}
        style={{ margin: "100px 0 0 200px" }}
      >
        <PvProductionChart pvdata={data} val={pvFilter?.value} />
      </Modal>
    </>
  );
};
export default App;
