import React from "react";
import { Gauge } from "@ant-design/plots";
import { BsArrowUp } from "react-icons/bs";

const DemoGauge = () => {
  const config = {
    percent: 0.2,
    radius: 0.65,
    range: {
      width: 10,
      color: ["#D9D9D9", "#EE3D29"],
    },
    gaugeStyle: {
      lineCap: "round",
    },
    width: 200,
    height: 200,
    startAngle: Math.PI,
    endAngle: 2 * Math.PI,
    indicator: null,
    statistic: {
      title: {
        offsetY: -25,
        style: {
          fontSize: "25px",
          color: "#000000",
          fontWeight: "600"
        },
        formatter: () => "13%",
      },
      content: {
        offsetY: -25,
        style: {
          fontSize: "0.60rem",
          lineHeight: "44px",
          color: "#131523",
        },
        customHtml: () => {
          return (
            <div style={{ display: "flex", alignItems: "center" }}>
              <BsArrowUp color={"#DD243B"} />
              <span style={{ width: "fit-content" }}>Than Last Day</span>
            </div>
          );
        },
      },
    },
  };
  return <Gauge {...config} />;
};

export default DemoGauge;
