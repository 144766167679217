import { combineReducers } from "redux";

import userReducer from "./reducers/userReducer";
import analysis from "./reducers/analysisReducer";
import homeReducer from "./reducers/homeReducer";

const rootReducer = combineReducers({
  userReducer,
  analysis,
  homeReducer
});

export default rootReducer;
