import React from "react";
import { Sankey } from "@ant-design/plots";

const DemoSankey = ({ Data }) => {
  // const dummy2 = [
  //   {
  //     main: "Grid",
  //     child1: "",
  //     child2: "",
  //     end: "CHP/BHKW",
  //     value: 357,
  //   },
  //   {
  //     main: "Grid",
  //     child1: "Spa",
  //     child2: "",
  //     end: "",
  //     value: 275,
  //   },
  //   {
  //     main: "Grid",
  //     child1: "Spa",
  //     child2: "",
  //     end: "CHP/BHKW",
  //     value: 75,
  //   },
  //   {
  //     main: "",
  //     child1: "Spa",
  //     child2: "Battery",
  //     end: "",
  //     value: 177,
  //   },
  //   {
  //     main: "",
  //     child1: "",
  //     child2: "Battery",
  //     end: "PV",
  //     value: 325,
  //   },
  //   {
  //     main: "Grid",
  //     child1: "E-Mobility",
  //     child2: "",
  //     end: "",
  //     value: 436,
  //   },
  //   {
  //     main: "",
  //     child1: "E-Mobility",
  //     child2: "Battery",
  //     end: "",
  //     value: 230,
  //   },
  //   {
  //     main: "Grid",
  //     child1: "Restaurant",
  //     child2: "",
  //     end: "",
  //     value: 152,
  //   },
  //   {
  //     main: "",
  //     child1: "Restaurant",
  //     child2: "Battery",
  //     end: "",
  //     value: 114,
  //   },

  //   {
  //     main: "Grid",
  //     child1: "Laundry",
  //     child2: "",
  //     end: "",
  //     value: 132,
  //   },
  //   {
  //     main: "",
  //     child1: "Laundry",
  //     child2: "Battery",
  //     end: "",
  //     value: 7,
  //   },
  //   {
  //     main: "Grid",
  //     child1: "",
  //     child2: "",
  //     end: "PV",
  //     value: 450,
  //   },
  // ];

  const sankeyData = [];
  const keys = ["main", "child1", "child2", "end"];
  Data.forEach((d) => {
    keys.reduce((a, b) => {
      if (a && b) {
        sankeyData.push({
          source: d[a],
          target: d[b],
          value: d.value,
          path: `${d[keys[0]]} -> ${d[keys[1]]} -> ${d[keys[2]]} -> ${d[keys[3]]
            }`,
        });
      }

      return b;
    });
  });
  const config = {
    data: sankeyData,
    sourceField: "source",
    targetField: "target",
    weightField: "value",
    nodeWidthRatio: 0.01,
    nodePaddingRatio: 0.03,
    nodeDraggable: false,
    rawFields: ["path"],
    tooltip: {
      fields: ["path", "value"],
      formatter: ({ path, value }) => {
        return {
          name: path,
          value: value,
        };
      },
      domStyles: {
        "g2-tooltip": {
          background: "white",
          color: "black",
          opacity: 1,
          borderRadius: "8px",
        },
      },
      customContent: (title, data) => {
        return (
          <div className="tooltip-comp">
            <div className="tooltip-bottom" />

            {data?.map((item, index) => (
              <div className="tooltip-item" key={index}>
                <div className="tooltip-left">
                  {/* {console.log("item ", item)} */}
                  <div
                    className="circle-dot"
                    style={{ backgroundColor: `${item.color}` }}
                  ></div>
                  <span className="head">{item?.name}:</span>
                </div>
                <span className="span-value">{`${Number(
                  Number(item.value)?.toFixed(2)
                )?.toLocaleString("de-DE")}`}</span>
              </div>
            ))}
          </div>
        );
      },
    },
  };

  return <Sankey {...config} />;
};

export default DemoSankey;
