/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import { LogoutOutlined } from "@ant-design/icons";
import { AiOutlineHome } from "react-icons/ai";
import { HiOutlineStatusOnline } from "react-icons/hi";
import { BiLineChart, BiWorld } from "react-icons/bi";
import { FiSettings } from "react-icons/fi";
import { RiChargingPileLine } from "react-icons/ri";

import { AiFillControl } from "react-icons/ai";
import { BsBatteryHalf } from "react-icons/bs";
import { VscGitCompare } from 'react-icons/vsc';
import { Menu } from "antd";
import energy from "../Assets/vectors/energy.png";
import co2 from "../Assets/vectors/co2.png";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getClient, getSidebarOfUser } from "../redux";

const MainMenu = ({ active }) => {
  const { t } = useTranslation();
  const [data, setData] = useState();
  const dispatch = useDispatch();
  const history = useHistory();
  const [loader, setLoader] = useState(false);
  useEffect(() => {

  }, [active]);

  const getData = async () => {
    setLoader(true);
    const data = await dispatch(getClient());
    const temp = await getSidebarOfUser({ id: data?._id });
    setData(temp);
    setLoader(false);
  }

  useEffect(() => {
    getData();
  }, [])


  return (
    <div>
      <Menu
        mode="inline"
        defaultSelectedKeys={active}
        style={{
          height: "100vh",
          background: "var(--lightBackground)",
          padding: "10px 10px 10px 0",
        }}
      >
        <Menu.Item
          key="dashboard"
          icon={<AiOutlineHome className="icon-size-22" />}
          onClick={() => history.push("/dashboard")}
        >
          {t('dashboard')}
        </Menu.Item>
        {data?.live && (
          <Menu.Item
            key="live"
            icon={<HiOutlineStatusOnline className="icon-size-22" />}
            onClick={() => history.push("/live")}
          >
            {t('live')}
          </Menu.Item>
        )}
        {data?.energy && (
          <Menu.Item
            key="energy-production"
            icon={<img src={energy} width="22px" height={"22px"} />}
          >
            <Link to={"/energy-production"}>{t('energy_production')}</Link>
          </Menu.Item>
        )}
        {data?.loadPeak && (
          <Menu.Item
            key="load-peak"
            icon={<BiLineChart className="icon-size-22" />}
          >
            <Link to={"/load-peak"}>{t('load_peak')}</Link>
          </Menu.Item>
        )}
        {data?.digitalization && (
          <Menu.Item
            key="digitalization"
            icon={<BiWorld className="icon-size-22" />}
          >
            <Link to={"/digitalization"}>{t('digitalization')}</Link>
          </Menu.Item>
        )}

        {data?.emobility && <Menu.Item
          key="emobility"
          icon={<RiChargingPileLine className="icon-size-22" />}
        >
          <Link to={"/emobility"}>{t('e_mobility')}</Link>
        </Menu.Item>}

        {data?.analysis && <Menu.Item
          key="analysis"
          icon={<AiFillControl className="icon-size-22" />}
        >
          <Link to={"/analysis"}>{t('analysis')}</Link>
        </Menu.Item>}

        {data?.co2emission && <Menu.Item
          key="co2emission"
          icon={<img src={co2} width="22px" height={"22px"} alt="" />}
        >
          <Link to={"/co2emission"}>{t('co2_emission')}</Link>
        </Menu.Item>}

        {data?.details && <Menu.Item
          key="details"
          icon={<VscGitCompare className="icon-size-22" />}
        >
          <Link to={"/details"}>{t('details')}</Link>
        </Menu.Item>}

        <Menu.Item
          key="battery"
          icon={<BsBatteryHalf className="icon-size-22" />}
        >
          <Link to={"/battery"}>Battery</Link>
        </Menu.Item>

        <Menu.Item
          key="settings"
          icon={<FiSettings className="icon-size-22" />}
        >
          <Link to={"/settings"}>{t('settings')}</Link>
        </Menu.Item>

        <Menu.Item
          key="logout"
          icon={<LogoutOutlined className="icon-size-22" />}
        >
          <Link
            to="/"
            onClick={() => {
              localStorage.clear();
              // window.location.reload();
            }}
          >
            {t('logout')}
          </Link>
        </Menu.Item>
      </Menu>
    </div>
  );
};

export default MainMenu;
