import { DualAxes } from "@ant-design/plots";
import TooltipComp from "../TooltipComp";

const DemoDualAxes = () => {
  const data = [
    {
      hour: "1",
      cost: 0,
      usage: 0,
    },
    {
      hour: "2",
      cost: 0,
      usage: 0,
    },
    {
      hour: "3",
      cost: 0,
      usage: 0,
    },
    {
      hour: "4",
      cost: 0,
      usage: 0,
    },
    {
      hour: "5",
      cost: 0,
      usage: 0,
    },
    {
      hour: "6",
      cost: 2.5,
      usage: 6,
    },
    {
      hour: "7",
      cost: 5,
      usage: 12,
    },
    {
      hour: "8",
      cost: 9,
      usage: 22,
    },
    {
      hour: "9",
      cost: 6,
      usage: 18,
    },
    {
      hour: "10",
      cost: 1,
      usage: 6,
    },
    {
      hour: "11",
      cost: 0,
      usage: 0,
    },
    {
      hour: "12",
      cost: 0,
      usage: 0,
    },
    {
      hour: "13",
      cost: 1,
      usage: 6,
    },
    {
      hour: "14",
      cost: 2,
      usage: 28,
    },
    {
      hour: "15",
      cost: 2,
      usage: 26,
    },
    {
      hour: "16",
      cost: 1,
      usage: 6,
    },
    {
      hour: "17",
      cost: 0,
      usage: 6,
    },
    {
      hour: "18",
      cost: 10,
      usage: 24,
    },
    {
      hour: "19",
      cost: 11,
      usage: 27,
    },
    {
      hour: "20",
      cost: 12,
      usage: 28,
    },
    {
      hour: "21",
      cost: 6,
      usage: 14,
    },
    {
      hour: "22",
      cost: 5,
      usage: 12,
    },
    {
      hour: "23",
      cost: 3,
      usage: 6,
    },
    {
      hour: "24",
      cost: 3,
      usage: 6,
    },
  ];
  const config = {
    data: [data, data],
    // data: [data],
    xField: "hour",
    yField: ["cost", "usage"],
    // yField: ["cost"],
    legend: false,
    xAxis: {
      tickCount: 5,
    },
    tooltip: {
      // position: "top",
      domStyles: {
        "g2-tooltip": {
          background: "white",
          // background: "#131523",
          color: "black",
          opacity: 1,
          borderRadius: "8px",
        },
      },
      customContent: (title, data) => {
        console.log("line",data);
        return (
          <TooltipComp
            data={data}
            units={["€", "kWh"]}
            // units={["€"]}
            fields={["Cost", "Usage"]}
            // fields={["Cost"]}
          />
        );
      },
    },
    yAxis: {
      tickCount: 5,

      cost: {
        title: {
          text: "€",
          // style: {
          //   stroke: "#131523",
          //   fontSize: 10,
          //   letterSpacing: "1px",
          //   fontWeight: "normal",
          //   opacity: 0.5,
          //   shadowBlur: 0,
          //   strokeOpacity: 0,
          // },
          style: {
            stroke: "#000000",
            fontSize: 10,
            color: "#000000",
            letterSpacing: "1px",
            fontWeight: 300,
            opacity: 0.9,
            shadowBlur: 0,
            strokeOpacity: 0,
          },
        },
      },
      usage: {
        title: {
          text: "kWh",
          // style: {
          //   stroke: "#131523",
          //   fontSize: 10,
          //   letterSpacing: "1px",
          //   fontWeight: "normal",
          //   opacity: 0.5,
          //   shadowBlur: 0,
          //   strokeOpacity: 0,
          // },
          style: {
            stroke: "#000000",
            fontSize: 10,
            color: "#000000",
            letterSpacing: "1px",
            fontWeight: 300,
            opacity: 0.9,
            shadowBlur: 0,
            strokeOpacity: 0,
          },
        },
      },
    },
    geometryOptions: [
      {
        geometry: "line",
        smooth: false,
        color: "#F99600",

        lineStyle: {
          lineWidth: 3,
          lineDash: [5, 5],
        },
      },
      {
        geometry: "line",
        smooth: true,
        color: "#099CFF",
        lineStyle: {
          lineWidth: 3,
        },
      },
    ],
  };
  return <DualAxes {...config} />;
};

export default DemoDualAxes;
