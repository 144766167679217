/* eslint-disable no-unused-vars */
import React from "react";
import Layout from "../../adminLayout/Layout";
import '../../Styles/pages/servicesDashboard.css'
import { useEffect } from "react";
import { deleteClient, getAllClients } from "../../redux/actions/servicesApi";
import { useState } from "react";
import { Button, Modal, Table } from "antd";
import ServicesCreateClients from "../ServicesCreateClients";
import { deleteDynamic, deleteSidebar, getAllSubnodes } from "../../redux";

const AdminDashboard = () => {
    const [clients, setClients] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
    const [currentUser, setCurrentUser] = useState();
    const [subnodes, setSubnodes] = useState([]);

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
        setIsUpdateModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
        setIsUpdateModalOpen(false);
        getClients();
    };

    const getClients = async () => {
        const temp = await getAllClients();
        setClients(temp);
        const temp2 = await getAllSubnodes();
        let temp3 = temp2?.map((item, idx) => ({
            label: item?.name,
            value: item?._id,
        }));
        setSubnodes(temp3);
    }

    const deleteHandler = async (val) => {
        await deleteClient({ id: val?._id });
        await deleteDynamic({ id: val?._id });
        await deleteSidebar({ id: val?._id });
        await getClients();
    }

    useEffect(() => {
        getClients();
    }, [])

    const columns = [
        {
            title: 'Name',
            dataIndex: 'userName',
            key: 'userName',
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'Language',
            dataIndex: 'language',
            key: 'language',
        }, {
            title: "Action",
            dataIndex: "action",
            render: (_, record) => {
                return (
                    <div style={{ display: 'flex', gap: '10px' }}>
                        <Button type="link" onClick={() => { setIsUpdateModalOpen(true); setCurrentUser(record) }} >
                            Update
                        </Button>
                        <Button type="link" onClick={() => { deleteHandler(record) }} >
                            Delete
                        </Button>
                    </div>
                )
            },
        },
    ];

    return (
        <Layout active="dashboard">
            <div className="home-main">
                <Modal title="Create Client" open={isModalOpen} onOk={handleOk} onCancel={handleCancel} footer={false} width={800}>
                    <ServicesCreateClients handleClose={handleCancel} subnodes={subnodes} />
                </Modal>
                <Modal title="Update Client" open={isUpdateModalOpen} onOk={handleOk} onCancel={handleCancel} footer={false} width={800}>
                    <ServicesCreateClients data={currentUser} handleClose={handleCancel} subnodes={subnodes} />
                </Modal>

                <div className="services-dashboard-create">
                    <Button onClick={showModal}>Create Client</Button>
                </div>

                <Table columns={columns} dataSource={clients} />

            </div>
        </Layout>
    );
};

export default AdminDashboard;
