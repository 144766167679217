/* eslint-disable no-unused-vars */
import Layout from "../Layout/MainLayout";
import {
  Typography,
  Row,
  Col,
  Select,
  Switch,
  Form,
  Input,
  Button,
  Tabs,
  TimePicker,
  Checkbox,
} from "antd";
import { MinusCircleOutlined } from "@ant-design/icons";
import CUSTOMHM from "../Components/charts/ChargingHeatmap";
import ChargingStationCard from "../Components/cards/ChargingStationCard";
import ColumnLineDualAxis1 from "../Components/charts/ColumnLineDualAxis1";
import DualLine from "../Components/charts/DualLine";
import GaugeProgress from "../Components/charts/GaugeProgress";
import GaugeProgressSingle from "../Components/charts/GaugeProgressSingle";
import { useEffect, useState, useRef } from "react";
import { FaAngleLeft, FaAngleRight, FaPlus } from "react-icons/fa";
import { useTranslation } from "react-i18next";

const LoadPeak = () => {
  const { t } = useTranslation();
  const onFinish = (values) => {
  };
  const [height, setHeight] = useState(120);
  const [Heatmapheight, setHeatmapHeight] = useState(120);
  const [activeNode, setActiveNode] = useState("all");
  const [stationActiveNode, setstationActiveNode] = useState("station1");
  const heightRef = useRef(null);
  const heatMapRef = useRef(null);

  let Data = [
    {
      title: "All",
      key: "all",
    },
    {
      title: "Monday",
      key: "monday",
    },
    {
      title: "Tuesday",
      key: "tuesday",
    },
    {
      title: "Wednesday",
      key: "wednesday",
    },
    {
      title: "Thursday",
      key: "thursday",
    },
    {
      title: "Friday",
      key: "friday",
    },
    {
      title: "Saturday",
      key: "saturday",
    },
    {
      title: "Sunday",
      key: "sunday",
    },
  ];

  let chargingData = [
    {
      title: "Station 1",
      key: "station1",
    },
    {
      title: "Station 2",
      key: "station2",
    },
    {
      title: "Station 3",
      key: "station3",
    },
    {
      title: "Station 4",
      key: "station4",
    },
  ];

  useEffect(() => {
    setHeight(heightRef?.current?.offsetHeight);
  }, [heightRef?.current?.offsetHeight]);

  useEffect(() => {
    setHeatmapHeight(heatMapRef?.current?.offsetHeight);
  }, [heatMapRef?.current?.offsetHeight]);

  return (
    <Layout active="emobility">
      <div className="live-main energy-main" style={{ marginBottom: "30px" }}>

        <Row gutter={[30, 30]}>
          <Col xs={24} md={24} lg={8}>
            <ChargingStationCard setHeight={setHeight} />
          </Col>

          <Col xs={24} md={12} lg={8}>
            <div
              className="live-card white-card"
              style={{
                height: "100%",
              }}
            >
              <Typography.Title
                className="title main-title"
                level={4}
                style={{ fontWeight: "500", marginBottom: "20px", fontSize: "15px" }}
              >
                {t('usage')} & {t('cost')}
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div
                    style={{
                      width: 8,
                      height: 8,
                      background: "#F99600",
                      borderRadius: "50%",
                      marginRight: 5,
                    }}
                  />
                  <p
                    style={{
                      fontWeight: 600,
                      fontSize: "0.75rem",
                      color: "black",
                      margin: 0,
                    }}
                  >
                    76,5 €
                  </p>

                  <div
                    style={{
                      width: 8,
                      height: 8,
                      background: "#099CFF",
                      borderRadius: "50%",
                      margin: "0 5px 0 10px",
                    }}
                  />
                  <p
                    style={{
                      fontWeight: 600,
                      fontSize: "0.75rem",
                      margin: 0,
                      color: "black",
                    }}
                  >
                    247 kWh
                  </p>
                </div>
                <Select defaultValue="day" style={{ width: "max-content" }}>
                  <Select.Option value="day">1 {t("day")}</Select.Option>
                  <Select.Option value="week">1 {t("week")}</Select.Option>
                  <Select.Option value="month">1 {t("month")}</Select.Option>
                  <Select.Option value="year">1 {t("year")}</Select.Option>
                </Select>
              </Typography.Title>

              <div
                className="chart-wrapper"
                style={{
                  width: "100%",
                  height: 100,
                }}
              >
                <DualLine />
              </div>
            </div>
          </Col>

          <Col xs={24} md={12} lg={8}>
            <div
              className="live-card white-card"
              style={{
                height: "100%",
              }}
            >
              <Typography.Title
                className="title main-title"
                level={4}
                style={{ fontWeight: "500", fontSize: "15px" }}
              >
                {t('carbon_footprint_reduction')}
                <Select defaultValue="day" style={{ width: "max-content" }}>
                  <Select.Option value="day">1 {t("day")}</Select.Option>
                  <Select.Option value="week">1 {t("week")}</Select.Option>
                  <Select.Option value="month">1 {t("month")}</Select.Option>
                  <Select.Option value="year">1 {t("year")}</Select.Option>
                </Select>
              </Typography.Title>

              <div className="head-bottom">
                <div
                  style={{ position: "relative", height: 150, width: "50%" }}
                >
                  <div
                    style={{
                      position: "absolute",
                      top: "-25%",
                      left: "50%",
                      transform: "translate(-50%, 0)",
                    }}
                  >
                    <GaugeProgress />
                  </div>
                  <div
                    style={{
                      position: "absolute",
                      top: "-25%",
                      left: "50%",
                      transform: "translate(-50%, 0)",
                    }}
                  >
                    <GaugeProgressSingle />
                  </div>
                </div>

                <div className="head-right-cfp">
                  <div
                    className="fuel"
                    style={{ display: "flex", flexDirection: "column" }}
                  >
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <div
                        style={{
                          background: "#EE3D29",
                          width: 15,
                          height: 15,
                          borderRadius: "50%",
                        }}
                      />
                      &nbsp;
                      <p
                        style={{
                          fontSize: "0.60rem",
                          fontWeight: "500",
                          margin: 0,
                        }}
                      >
                        {t('co2_saved')}
                      </p>
                    </div>

                    <p
                      style={{
                        fontSize: "1rem",
                        fontWeight: "600",
                        margin: 0,
                        marginLeft: "0.75rem",
                      }}
                    >
                      34 Kg
                    </p>
                  </div>
                  <div
                    className="fuel"
                    style={{ display: "flex", flexDirection: "column" }}
                  >
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <div
                        style={{
                          background: "#07A0E0",
                          width: 15,
                          height: 15,
                          borderRadius: "50%",
                        }}
                      />
                      &nbsp;
                      <p
                        style={{
                          fontSize: "0.60rem",
                          fontWeight: "500",
                          margin: 0,
                        }}
                      >
                        {t('fuel_replaced')}
                      </p>
                    </div>

                    <p
                      style={{
                        fontSize: "1rem",
                        fontWeight: "600",
                        margin: 0,
                        marginLeft: "0.75rem",
                      }}
                    >
                      257 L
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Col>

          <Col xs={24}>
            <div className="white-card">
              <Typography.Title
                className="title main-title"
                level={4}
                style={{ fontWeight: "600", marginBottom: "30px" }}
              >
                {t('consumption')}
                <Select defaultValue="week" style={{ width: "max-content" }}>
                  <Select.Option value="week">1 {t("week")}</Select.Option>
                  <Select.Option value="month">1 {t("month")}</Select.Option>
                  <Select.Option value="year">1 {t("year")}</Select.Option>
                </Select>
              </Typography.Title>

              <div
                style={{
                  width: "100%",
                }}
              >
                <ColumnLineDualAxis1
                  leftUnit="kWh"
                  rightUnit="kWh"
                  //   xUnit="Days"
                  alias={["Energy Production", "Total"]}
                  dot={false}
                  isStack
                  radius={[4, 4, 0, 0]}
                />
              </div>
            </div>
          </Col>

          <Col xs={24} lg={12} xl={12}>
            <div className="live-card white-card" ref={heatMapRef}>
              <Typography.Title
                className="title main-title"
                level={4}
                style={{ fontWeight: "500" }}
              >
                {t('smart_charging_and_charging_profiles')}
              </Typography.Title>


              <div>
                <Form layout="vertical">
                  <Row gutter={[10, 5]}>
                    <Col xs={24} md={12}>
                      <Form.Item name="name" label="Charging profile name">
                        <Input />
                      </Form.Item>
                    </Col>

                    <Col xs={24} md={12}>
                      <Form.Item name={"current"} label="Maximum Current (A)">
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col xs={24}>
                      <Form.Item name={"renewable"}>
                        <div className="flex-between">
                          <Checkbox
                            style={{
                              color: "#2EC276",
                            }}
                          >
                            {t('use_only_renewable_energy')}
                          </Checkbox>
                          <Button type="primary" htmlType="submit">
                            {t('save')}
                          </Button>
                        </div>
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </div>

              <Tabs defaultActiveKey="1" animated>
                <Tabs.TabPane tab="Days" key="1">
                  <Row gutter={[10, 20]}>
                    <Col lg={4} className="Days-names-wrapper">
                      {Data.map((data, index) => (
                        <div
                          key={index}
                          className="flex-between"
                          style={
                            activeNode === data.key ? { color: "#0098FD" } : {}
                          }
                          onClick={() => {
                            setActiveNode(data.key);
                          }}
                        >
                          <span
                            style={{ fontWeight: "bold", width: "max-content", fontSize: "8px" }}
                          >
                            {data.title}&nbsp;
                          </span>
                          {activeNode === data.key ? (
                            <FaAngleLeft />
                          ) : (
                            <FaAngleRight />
                          )}
                        </div>
                      ))}
                    </Col>
                    <Col lg={14}>
                      <div className="white-card">
                        <Form
                          layout="vertical"
                          onFinish={onFinish}
                          autoComplete="off"
                        >
                          <Row gutter={[20, 20]} align="middle">
                            <Col xs={24} md={12} xl={6}>
                              <Form.Item name="from" label="From">
                                <TimePicker
                                />
                              </Form.Item>
                            </Col>
                            <Col xs={24} md={12} lg={6}>
                              <Form.Item name="to" label="To">
                                <TimePicker />
                              </Form.Item>
                            </Col>
                            <Col xs={24} md={12} lg={6}>
                              <Form.Item name="power" label="Power">
                                <Input type="number" style={{ height: 32 }} />
                              </Form.Item>
                            </Col>
                            <Col xs={24} md={12} lg={6}>
                              <Form.Item name="unit" label="Power Unit">
                                <Switch
                                  checkedChildren="kW"
                                  unCheckedChildren="%"
                                  defaultChecked={false}
                                />
                              </Form.Item>
                            </Col>

                            <Form.List name={activeNode}>
                              {/*  */}
                              {(fields, { add, remove }) => (
                                <>
                                  {fields.map(
                                    ({ key, name, ...restField }, index) => (
                                      <>
                                        <Col xs={24} md={12} lg={6} key={index}>
                                          <Form.Item
                                            {...restField}
                                            name={[name, "from"]}
                                            label="From"
                                          >
                                            <TimePicker />
                                          </Form.Item>
                                        </Col>
                                        <Col xs={24} md={12} lg={6}>
                                          <Form.Item
                                            {...restField}
                                            name={[name, "to"]}
                                            label="To"
                                          >
                                            <TimePicker />
                                          </Form.Item>
                                        </Col>
                                        <Col xs={24} md={12} lg={6}>
                                          <Form.Item
                                            {...restField}
                                            name={[name, "power"]}
                                            label="Power"
                                          >
                                            <Input
                                              type="number"
                                              style={{ height: 32 }}
                                            />
                                          </Form.Item>
                                        </Col>
                                        <Col xs={24} md={12} lg={3}>
                                          <Form.Item
                                            {...restField}
                                            name={[name, "unit"]}
                                            label="Power Unit"
                                          >
                                            <Switch
                                              checkedChildren="kW"
                                              unCheckedChildren="%"
                                              defaultChecked={false}
                                            />
                                          </Form.Item>
                                        </Col>
                                        <Col xs={24} md={12} lg={3}>
                                          <MinusCircleOutlined
                                            size={20}
                                            onClick={() => remove(name)}
                                          />
                                        </Col>
                                      </>
                                    )
                                  )}
                                  <Col xs={24}>
                                    <Form.Item>
                                      <Button
                                        shape="circle"
                                        style={{
                                          backgroundColor: "#0098FD",
                                          display: "flex",
                                          border: "50%",
                                          justifyContent: "center",
                                          alignItems: "center",
                                        }}
                                        icon={<FaPlus color="#fff" />}
                                        onClick={() => add()}
                                      ></Button>
                                    </Form.Item>
                                  </Col>
                                </>
                              )}
                            </Form.List>
                            <Col xs={24}>
                              <Form.Item>
                                <Button block type="primary" htmlType="submit">
                                  {t('submit')}
                                </Button>
                              </Form.Item>
                            </Col>
                          </Row>
                        </Form>
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <h3 style={{ opacity: "50%", fontSize: "0.8rem" }}>
                          12am
                        </h3>
                        <h3 style={{ opacity: "50%", fontSize: "0.8rem" }}>
                          12pm
                        </h3>
                        <h3 style={{ opacity: "50%", fontSize: "0.8rem" }}>
                          12am
                        </h3>
                      </div>
                      <div className="smart-charging-progressbar-wrapper">
                        {[1, 2, 3, 2, 5, 4, 1, 3].map((per, index) => (
                          <div
                            key={index}
                            style={{
                              width: "100%",
                              height: "1rem",
                              backgroundColor: "#0098FD",
                              borderRadius: "1rem",
                              position: "relative",
                            }}
                          >
                            {" "}
                            <div
                              style={{
                                width: `calc(100%/3)`,
                                height: "1rem",
                                backgroundColor: "#0063A5",
                                top: "0",
                                left: `${per}0%`,
                                borderRadius: "1rem",
                                position: "absolute",
                              }}
                            ></div>
                          </div>
                        ))}
                      </div>
                    </Col>
                  </Row>
                </Tabs.TabPane>
                <Tabs.TabPane tab="Charging station" key="2">
                  {/* <Collapse accordion ghost bordered={false}> */}
                  <Row gutter={[10, 20]}>
                    <Col lg={4} className="Days-names-wrapper">
                      {chargingData.map((data, index) => (
                        <div
                          key={index}
                          className="flex-between"
                          style={
                            stationActiveNode === data.key
                              ? { color: "#0098FD" }
                              : {}
                          }
                          onClick={() => {
                            setstationActiveNode(data.key);
                          }}
                        >
                          <span
                            style={{
                              fontWeight: "bold",
                              width: "max-content",
                              fontSize: "10px"
                            }}
                          >
                            {data.title}&nbsp;
                          </span>
                          {stationActiveNode === data.key ? (
                            <FaAngleLeft />
                          ) : (
                            <FaAngleRight />
                          )}
                        </div>
                      ))}
                    </Col>
                    <Col lg={14}>
                      <div className="white-card">
                        <Form
                          layout="vertical"
                          onFinish={onFinish}
                          autoComplete="off"
                        >
                          <Row gutter={[20, 20]} align="middle">
                            <Col xs={24} md={12} xl={6}>
                              <Form.Item name="from" label="From">
                                <TimePicker
                                />
                              </Form.Item>
                            </Col>
                            <Col xs={24} md={12} lg={6}>
                              <Form.Item name="to" label="To">
                                <TimePicker />
                              </Form.Item>
                            </Col>
                            <Col xs={24} md={12} lg={6}>
                              <Form.Item name="power" label="Power">
                                <Input type="number" style={{ height: 32 }} />
                              </Form.Item>
                            </Col>
                            <Col xs={24} md={12} lg={6}>
                              <Form.Item name="unit" label="Power Unit">
                                <Switch
                                  checkedChildren="kW"
                                  unCheckedChildren="%"
                                  defaultChecked={false}
                                />
                              </Form.Item>
                            </Col>

                            <Form.List name={activeNode}>
                              {/*  */}
                              {(fields, { add, remove }) => (
                                <>
                                  {fields.map(
                                    ({ key, name, ...restField }, index) => (
                                      <>
                                        <Col xs={24} md={12} lg={6} key={index}>
                                          <Form.Item
                                            {...restField}
                                            name={[name, "from"]}
                                            label="From"
                                          >
                                            <TimePicker />
                                          </Form.Item>
                                        </Col>
                                        <Col xs={24} md={12} lg={6}>
                                          <Form.Item
                                            {...restField}
                                            name={[name, "to"]}
                                            label="To"
                                          >
                                            <TimePicker />
                                          </Form.Item>
                                        </Col>
                                        <Col xs={24} md={12} lg={6}>
                                          <Form.Item
                                            {...restField}
                                            name={[name, "power"]}
                                            label="Power"
                                          >
                                            <Input
                                              type="number"
                                              style={{ height: 32 }}
                                            />
                                          </Form.Item>
                                        </Col>
                                        <Col xs={24} md={12} lg={3}>
                                          <Form.Item
                                            {...restField}
                                            name={[name, "unit"]}
                                            label="Power Unit"
                                          >
                                            <Switch
                                              checkedChildren="kW"
                                              unCheckedChildren="%"
                                              defaultChecked={false}
                                            />
                                          </Form.Item>
                                        </Col>
                                        <Col xs={24} md={12} lg={3}>
                                          <MinusCircleOutlined
                                            size={20}
                                            onClick={() => remove(name)}
                                          />
                                        </Col>
                                      </>
                                    )
                                  )}
                                  <Col xs={24}>
                                    <Form.Item>
                                      <Button
                                        shape="circle"
                                        style={{
                                          backgroundColor: "#0098FD",
                                          display: "flex",
                                          border: "50%",
                                          justifyContent: "center",
                                          alignItems: "center",
                                        }}
                                        icon={<FaPlus color="#fff" />}
                                        onClick={() => add()}
                                      ></Button>
                                    </Form.Item>
                                  </Col>
                                </>
                              )}
                            </Form.List>
                            <Col xs={24}>
                              <Form.Item>
                                <Button block type="primary" htmlType="submit">
                                  {t('submit')}
                                </Button>
                              </Form.Item>
                            </Col>
                          </Row>
                        </Form>
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <h3 style={{ opacity: "50%", fontSize: "0.8rem" }}>
                          12am
                        </h3>
                        <h3 style={{ opacity: "50%", fontSize: "0.8rem" }}>
                          12pm
                        </h3>
                        <h3 style={{ opacity: "50%", fontSize: "0.8rem" }}>
                          12am
                        </h3>
                      </div>
                      <div className="smart-charging-progressbar-wrapper">
                        {[1, 2, 3, 4, 5, 6, 7, 8].map((per, index) => (
                          <div
                            key={index}
                            style={{
                              width: "100%",
                              height: "1rem",
                              backgroundColor: "#0098FD",
                              borderRadius: "1rem",
                              position: "relative",
                            }}
                          >
                            {" "}
                            <div
                              style={{
                                width: `${per}0%`,
                                height: "1rem",
                                backgroundColor: "#0063A5",
                                top: "0",
                                left: `0%`,
                                borderRadius: "1rem",
                                position: "absolute",
                              }}
                            ></div>
                          </div>
                        ))}
                      </div>
                    </Col>
                  </Row>
                </Tabs.TabPane>
              </Tabs>
            </div>
          </Col>

          <Col xs={24} lg={12} xl={12}>
            <div
              className="live-card heatmap-card"
              style={{
                height: Heatmapheight,
              }}
            >
              <Typography.Title
                className="title main-title"
                level={4}
                style={{ fontWeight: "500", marginBottom: "30px" }}
              >
                {t('popular_charging_time')}
              </Typography.Title>
              <CUSTOMHM />
            </div>
          </Col>
        </Row>
      </div>
    </Layout>
  );
};

export default LoadPeak;
