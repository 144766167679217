import { LogoutOutlined } from "@ant-design/icons";
import { AiFillHdd } from "react-icons/ai";
import { Menu } from "antd";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { useEffect } from "react";
import { ControlOutlined, SettingOutlined, FlagOutlined, UserOutlined } from '@ant-design/icons';

const MainMenu = ({ active }) => {
  const history = useHistory();
  useEffect(() => {
  }, [active]);

  return (
    <div>
      <Menu
        mode="inline"
        defaultSelectedKeys={active}
        style={{
          height: "100vh",
          background: "var(--lightBackground)",
          padding: "10px 10px 10px 0",
        }}
      >
        <Menu.Item
          key="dashboard"
          icon={<UserOutlined className="icon-size-22" />}
          onClick={() => history.push("/admin-dashboard")}
        >
          Clients Informations
        </Menu.Item>
        <Menu.Item
          key="language-manager"
          icon={<FlagOutlined className="icon-size-22" />}
        >
          <Link to={"/admin-language"}>Langugage Manager</Link>
        </Menu.Item>
        <Menu.Item
          key="dynamic-switches"
          icon={<AiFillHdd className="icon-size-22" />}
        >
          <Link to={"/admin-switches"}>Dynamic Graphs</Link>
        </Menu.Item>
        <Menu.Item
          key="admin-subnodes"
          icon={<ControlOutlined className="icon-size-22" />}
        >
          <Link to={"/admin-subnodes"}>SubNodes Control</Link>
        </Menu.Item>
        <Menu.Item
          key="admin-sidebar"
          icon={<SettingOutlined className="icon-size-22" />}
        >
          <Link to={"/admin-sidebar"}>Users Sidebar</Link>
        </Menu.Item>
        <Menu.Item
          key="logout"
          icon={<LogoutOutlined className="icon-size-22" />}
        >
          <Link
            to="/admin"
            onClick={() => {
              sessionStorage.clear();
              // window.location.reload();
            }}
          >
            Logout
          </Link>
        </Menu.Item>
      </Menu>
    </div>
  );
};

export default MainMenu;
