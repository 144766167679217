/* eslint-disable react-hooks/exhaustive-deps */
import { Progress } from "antd";
import React, { useEffect, useState } from "react";
import { getRoundChartDataSubchannels } from "../../redux";
import Loader from "../Loader";
const HoverCard = ({ label, trigger, total, perc, filter, strokeColor }) => {
  const [electricConsumption, setElectricConsumption] = useState(null);
  const [loader, setLoader] = useState(false);
  const getData = async () => {
    setLoader(true);
    const result = await getRoundChartDataSubchannels({
      name: label,
      total: total,
      perc: perc,
      filter: filter,
    });
    setElectricConsumption(result);
    setLoader(false);
  };
  useEffect(() => {
    getData();
  }, [trigger]);
  return (
    <div className="hover-container">
      {!loader ? (
        electricConsumption?.length > 0 &&
        electricConsumption?.map((item) => (
          <>
            {console.log(item)}
            <div className="hover-wrapper">
              <div
                style={{
                  background: strokeColor,
                  width: "10px",
                  height: "10px",
                  borderRadius: "50%",
                }}
              />
              <p style={{ width: "120px" }}>{item?.name}</p>
              <Progress
                style={{
                  width: "130px",
                }}
                strokeLinecap="square"
                percent={item?.value}
                strokeColor={strokeColor}
              />
            </div>

            {/* </Col>
              <Col xs={24} md={12}> */}

            {/* </Col> */}
          </>
        ))
      ) : (
        <Loader />
      )}
      {/* <Col xs={24} md={6}>
          <div className="hover-wrapper">
            <div className="blue" />
            <p>Suana 2</p>
          </div>
        </Col>
        <Col xs={24} md={18}>
          <div className="bar-graph">
            <Progress
              strokeLinecap="square"
              percent={30}
              strokeColor="#5100FD"

              // strokeColor={colors?.[index]}
              // percent={electricConsumption?.dataChannels[
              //     index
              // ].percentage?.toFixed()}
              // strokeWidth={6}
            />
          </div>
        </Col>
        <Col xs={24} md={6}>
          <div className="hover-wrapper">
            <div className="blue" />
            <p>Suana 3</p>
          </div>
        </Col>
        <Col xs={24} md={18}>
          <div className="bar-graph">
            <Progress
              strokeLinecap="square"
              percent={30}
              strokeColor="#5100FD"

              // strokeColor={colors?.[index]}
              // percent={electricConsumption?.dataChannels[
              //     index
              // ].percentage?.toFixed()}
              // strokeWidth={6}
            />
          </div>
        </Col>
        <Col xs={24} md={6}>
          <div className="hover-wrapper">
            <div className="blue" />
            <p>Pool 1</p>
          </div>
        </Col>
        <Col xs={24} md={18}>
          <div className="bar-graph">
            <Progress
              strokeLinecap="square"
              percent={30}
              strokeColor="#5100FD"

              // strokeColor={colors?.[index]}
              // percent={electricConsumption?.dataChannels[
              //     index
              // ].percentage?.toFixed()}
              // strokeWidth={6}
            />
          </div>
        </Col>
        <Col xs={24} md={6}>
          <div className="hover-wrapper">
            <div className="blue" />
            <p>Pool 2</p>
          </div>
        </Col>
        <Col xs={24} md={18}>
          <div className="bar-graph">
            <Progress
              strokeLinecap="square"
              percent={30}
              strokeColor="#5100FD"

              // strokeColor={colors?.[index]}
              // percent={electricConsumption?.dataChannels[
              //     index
              // ].percentage?.toFixed()}
              // strokeWidth={6}
            />
          </div>
        </Col>
        <Col xs={24} md={6}>
          <div className="hover-wrapper">
            <div className="blue" />
            <p>Pool 3</p>
          </div>
        </Col>
        <Col xs={24} md={18}>
          <div className="bar-graph">
            <Progress
              strokeLinecap="square"
              percent={30}
              strokeColor="#5100FD"

              // strokeColor={colors?.[index]}
              // percent={electricConsumption?.dataChannels[
              //     index
              // ].percentage?.toFixed()}
              // strokeWidth={6}
            />
          </div>
        </Col> */}
      {/* </Row> */}
    </div>
  );
};
export default HoverCard;
