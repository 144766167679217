import React, { useState } from "react";

import {
  Button,
  Badge,
  Avatar,
  Drawer,
} from "antd";
import { FaAngleDown } from "react-icons/fa";
import { BellOutlined } from "@ant-design/icons";
import { MenuOutlined } from "@ant-design/icons";
import { BiArrowBack } from "react-icons/bi";

import MainMenu from "./MainMenu";
import logo from "../Assets/logo2.png";

const MenuStyle = {
  cursor: "pointer",
  background: "transparent",
  color: "var(--SecondaryColor)",
  border: "none",
  outline: "none",
  fontSize: "20px",
  height: "auto",
};

const MenuDrawer = ({ active }) => {
  const [visible, setVisible] = useState(false);

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  return (
    <>
      <div className="m-header">
        <div className="header-row">
          <Button onClick={showDrawer} style={MenuStyle}>
            <MenuOutlined />
          </Button>

          <div className="header-right">
            <Badge count={4}>
              <Avatar
                icon={<BellOutlined />}
                style={{ color: "var(--text)", background: "transparent" }}
              />
            </Badge>
            <div style={{ display: "flex", alignItems: "center" }}>
              <span className="name">
                {" "}
                {localStorage.getItem("name")
                  ? localStorage.getItem("name")
                  : ""}
              </span>
              <FaAngleDown style={{ color: "var(--secondaryText)" }} />
            </div>
          </div>
        </div>
      </div>
      <Drawer
        className="mobile-menu-drawer"
        placement="left"
        closable={false}
        onClose={onClose}
        visible={visible}
      >
        <div>
          <div className="m-drawer">
            <BiArrowBack
              onClick={() => setVisible(false)}
              className="backArrow"
            />
            <div className="logo">
              <img src={logo} alt="logo" width="50%" />
            </div>
          </div>
          <MainMenu active={active} setVisible={setVisible} visible={visible} />
        </div>
      </Drawer>
    </>
  );
};

export default MenuDrawer;
