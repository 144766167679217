import {
  SET_USER_COLORS,
  SET_USER_RESTRICTIONS,
  SET_BAR_COLORS,
  SET_LIVE_PAGE_CONFIG,
  DISPLAY_MASK,
  SET_DISPLAY_ANNOTATION,
  SET_DISPLAY_ANNOTATION_SUB,
  SET_MEAN_NODES,
  SET_DYNAMIC_UI,
} from "../types/generalTypes";

const initialState = {
  colors: null,
  barColors: null,
  restrictions: null,
  livePageConfig: null,
  displayMask: false,
  displayAnnotation: false,
  displayAnnotationSub: false,
  measNodes: [],
  dynamicUI: {}
};

const productReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_USER_COLORS:
      return {
        ...state,
        colors: payload,
      };
    case SET_USER_RESTRICTIONS:
      return {
        ...state,
        restrictions: payload,
      };
    case DISPLAY_MASK:
      return {
        ...state,
        displayMask: !state.displayMask,
      };
    case SET_BAR_COLORS:
      return {
        ...state,
        barColors: payload,
      };
    case SET_LIVE_PAGE_CONFIG:
      return {
        ...state,
        livePageConfig: payload,
      };
    case SET_DISPLAY_ANNOTATION: {
      return {
        ...state,
        displayAnnotation: payload,
      };
    }
    case SET_DISPLAY_ANNOTATION_SUB: {
      return {
        ...state,
        displayAnnotationSub: payload,
      };
    }
    case SET_MEAN_NODES: {
      return {
        ...state,
        measNodes: payload,
      };
    }
    case SET_DYNAMIC_UI: {
      return {
        ...state,
        dynamicUI: payload,
      };
    }

    default:
      return state;
  }
};

export default productReducer;
