import React, { useEffect, useRef } from "react";
import Chart from "chart.js/auto";
import { getDataArrray } from "../../Util/lineChartUtil";

export default function LineCharts({ dataValues, colorss, switches, analysisByDates }) {
    const canvasEl = useRef(null);

    useEffect(() => {
        const ctx = canvasEl?.current?.getContext("2d");

        let { length, datasetArray } = getDataArrray(dataValues, colorss, switches, analysisByDates);


        const labels = Array(length).fill().map((_, i) => i + 1);

        const data = {
            labels: labels,
            datasets: datasetArray
        };

        const customLegend = {
            id: "customLegend",
            afterDraw: (chart, args, option) => {
                const { _metasets, ctx } = chart;
                ctx.save();
                _metasets.foreach((meta) => {
                    ctx.font = "bolder 12px Arial";
                    ctx.fillStyle = meta._detaset.borderColor;
                    ctx.textBaseLine = "middle";
                    ctx.fillText(meta._detaset.label, meta.data[meta.data.length - 1].x + 6, meta.data[meta.data.length - 1].y + 6)
                })
            }
        }

        // const footer = (tooltipItems) => {
        //     let sum = 0;

        //     tooltipItems.forEach(function (tooltipItem) {
        //         sum += tooltipItem.parsed.y;
        //     });
        // };

        const config = {
            type: "line",
            data: data,
            options: {
                responsive: true,
                maintainAspectRatio: true,
                tension: 0.5,
                interaction: {
                    intersect: false,
                    mode: 'index',
                },
                plugins: {
                    legend: {
                        display: false,
                        position: "right",
                        align: "middle"
                    },
                    tooltip: {
                        xAlign: 'center',
                        yAlign: 'bottom',
                        padding: 10,
                        backgroundColor: "#fff",
                        bodyColor: "#000000",
                        titleColor: "#000000",
                        titleFont: { weight: 'bold' },
                        titleMarginBottom: 10,
                        bodySpacing: 2,
                        label: true,
                        usePointStyle: true,
                        callbacks: {
                            label: function (context) {
                                return `    ${context?.dataset?.label}:   ${context.formattedValue}`;
                            }
                        }
                    }

                },

                scales: {
                    x: {
                        border: {
                            display: false,
                        },
                        grid: {
                            display: true,
                        },
                        title: {
                            display: true,
                            text: 'Hours'
                        }
                    },
                    y: {
                        border: {
                            display: false,
                        },
                        grid: {
                            display: true,
                        },
                        title: {
                            display: true,
                            text: 'kW'
                        }
                    },
                }
            },
            plugin: [customLegend]
        };

        const myLineChart = new Chart(ctx, config);

        return function cleanup() {
            myLineChart.destroy();
        };
    });

    return (
        <canvas id="myChart" ref={canvasEl} height="100" />
    );
}
