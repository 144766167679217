import React, { useEffect, useRef } from "react";
import Chart from "chart.js/auto";
import { getDataArrray } from "../../Util/lineChartUtil";

export default function LineChartsForEC({ dataValues, colorss, switches, analysisByDates }) {
    const canvasEl = useRef(null);

    useEffect(() => {
        const ctx = canvasEl?.current?.getContext("2d");

        let { length, datasetArray } = getDataArrray(dataValues, colorss, switches, analysisByDates);


        // const labels = dataValues[0]?.values.map((item) => item?.time.slice(11, 16));
        const labels = Array(length).fill().map((_, i) => i + 1);

        const data = {
            labels: labels,
            datasets: datasetArray
        };

        const customLegend = {
            id: "customLegend",
            afterDraw: (chart, args, option) => {
                const { _metasets, ctx } = chart;
                ctx.save();
                _metasets.foreach((meta) => {
                    ctx.font = "bolder 12px Arial";
                    ctx.fillStyle = meta._detaset.borderColor;
                    ctx.textBaseLine = "middle";
                    ctx.fillText(meta._detaset.label, meta.data[meta.data.length - 1].x + 6, meta.data[meta.data.length - 1].y + 6)
                })
            }
        }

        const config = {
            type: "line",
            data: data,
            options: {
                responsive: true,
                maintainAspectRatio: true,
                tension: 0.5,
                plugins: {
                    legend: {
                        display: false,
                        position: "right",
                        align: "middle"
                    },

                },

                scales: {
                    x: {
                        border: {
                            display: false,
                        },
                        grid: {
                            display: true,
                        },
                        title: {
                            display: true,
                            text: 'Hours'
                        }
                    },
                    y: {
                        border: {
                            display: false,
                        },
                        grid: {
                            display: true,
                        },
                        title: {
                            display: true,
                            text: 'kW'
                        }
                    },
                }
            },
            plugin: [customLegend]
        };

        const myLineChart = new Chart(ctx, config);

        return function cleanup() {
            myLineChart.destroy();
        };
    });

    return (
        <canvas id="myChart" ref={canvasEl} height="100" />
    );
}
