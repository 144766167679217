import React from "react";
import Layout from "../servicesLayout/Layout";
import '../Styles/pages/servicesDashboard.css'
import { useEffect } from "react";
import { getAllClients } from "../redux/actions/servicesApi";
import { useState } from "react";
import { Modal } from "antd";
import ServicesCreateClients from "./ServicesCreateClients";

const ServicesDashboard = () => {
    const [clients, setClients] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const getClients = async () => {
        const temp = await getAllClients();
        setClients(temp);
    }

    useEffect(() => {
        getClients();
    }, [])

    return (
        <Layout active="dashboard">
            <div className="home-main">
                <Modal title="Create Client" open={isModalOpen} onOk={handleOk} onCancel={handleCancel} footer={false}>
                    <ServicesCreateClients />
                </Modal>

                <div className="services-dashboard-create">
                    <button className="services-dashboard-button" onClick={showModal}>Create Client</button>
                </div>

                <div className="service-dashboard-table">
                    <div className="services-dashboard-header">
                        <div className="services-dashboard-head-col">username</div>
                        <div className="services-dashboard-head-col-2">Email</div>
                        <div className="services-dashboard-head-col">Actions</div>
                    </div>
                    <div className="services-dashboard-body">
                        {
                            clients.length > 0 && clients?.map((item) => (
                                <div className="services-dashboard-row" >
                                    <div className="services-dashboard-col">
                                        {item?.userName}
                                    </div>
                                    <div className="services-dashboard-col-2">
                                        {item?.email}
                                    </div>
                                    <div className="services-dashboard-action">
                                        <button >Update</button>
                                        <button>Delete</button>
                                    </div>
                                </div>
                            ))
                        }

                    </div>
                </div>

            </div>
        </Layout>
    );
};

export default ServicesDashboard;
