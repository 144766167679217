/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useRef, useEffect, useLayoutEffect } from "react";
import { getSubChannelHeatmap } from "../../redux";
import moment from "moment";
import Loader from "../Loader";
import { Empty } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { SET_DISPLAY_ANNOTATION_SUB } from "../../redux/types/generalTypes";
export default function SubChannelsHeatmap({ filter, name }) {
  const ref = useRef(null);
  const [height, setHeight] = useState(50);
  const labelRef = useRef(null);
  const [clientX, setClientx] = useState(0);
  const [clientY, setClientY] = useState(0);
  const [currentInd, setCurrentInd] = useState(null);
  const [selectedItem, setItem] = useState("");
  const [heatMapData, setHeatMapData] = useState([]);
  const [maxNum, setMaxNum] = useState(0);
  const [empty, setEmpty] = useState(false);

  const dispatch = useDispatch();
  const displayAnnotationSub = useSelector(
    (state) => state.userReducer.displayAnnotationSub
  );

  const setDate = () => {
    var getDateArray = function (start, end) {
      var arr = [];
      let startDate = new Date(start);
      let endDate = new Date(end);
      while (startDate <= endDate) {
        arr.push(new Date(startDate).toISOString().slice(0, 10));
        startDate.setDate(startDate.getDate() + 1);
      }
      return arr;
    };
    var dateArr = getDateArray(
      moment().subtract(5, "days").format("YYYY-MM-DD"),
      moment().format("YYYY-MM-DD")
    );
    let intervals = ["0-6", "6-12", "12-18", "18-24"];
    let hours = [];
    //Mon 0-6
    dateArr?.map((date) => {
      intervals?.map((interval, ind) => {
        hours.push(moment(date).startOf("day").format("ddd") + " " + interval);
      });
    });
    return hours;
  };

  const getLast24Days = () => {
    var getDateArray = function (start, end) {
      var arr = [];
      let startDate = new Date(start);
      let endDate = new Date(end);
      while (startDate <= endDate) {
        arr.push(moment(startDate).format("DD"));
        startDate.setDate(startDate.getDate() + 1);
      }
      return arr;
    };
    var dateArr = getDateArray(
      moment().subtract(23, "days").format("YYYY-MM-DD"),
      moment().format("YYYY-MM-DD")
    );

    return dateArr;
  };

  const getData = async () => {
    const result = await getSubChannelHeatmap({
      filter: filter.key,
      name: name,
    });
    if (result?.maxNum === 0) {
      setEmpty(true);
    }
    setMaxNum(result?.maxNum);
    setHeatMapData([
      ...result?.result,
      filter.key === "24h"
        ? {
          name: "",
          values: [
            1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
            20, 21, 22, 23, 24,
          ],
        }
        : filter.key === "6d"
          ? {
            name: "",
            values: setDate(),
          }
          : {
            name: "",
            values: getLast24Days(),
          },
    ]);
  };

  useEffect(() => {
    setEmpty(false);
    setHeatMapData([]);
    getData();
    setHeight(ref?.current?.offsetWidth * 1.33);
  }, [filter, name]);


  useLayoutEffect(() => {
    setHeight(ref?.current?.offsetWidth * 1.33);
  }, [ref?.current?.offsetWidth]);

  const Annotation = ({ name, totalLength }) => {
    return (
      <>
        {displayAnnotationSub && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              flex: 1,
              height: 65,
              width: 250,
              boxShadow: "rgb(174 174 174) 0px 0px 10px",
              transition:
                "left 0.4s cubic-bezier(0.23, 1, 0.32, 1) 0s, top 0.4s cubic-bezier(0.23, 1, 0.32, 1) 0s",
              color: "rgb(89, 89, 89)",
              backgroundColor: "white",
              fontFamily: "Poppins",
              left: clientX - 100,
              top: clientY - 100,
              position: "fixed",
              borderRadius: "4px",
              padding: "0 20px",
              fontSize: "10px",
              paddingTop: "5px",
              opacity: selectedItem.name === "" ? 0 : 1,
              zIndex: 100000,
            }}
          >
            <div
              className="tooltip-position"
              style={{ bottom: "-59px", marginLeft: "-15px" }}
            />

            <p
              style={{
                fontWeight: "bold",
                fontSize: "13px",
                marginBottom: "5px",
                color: "black",
              }}
            >
              {selectedItem.title}
            </p>
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <p
                style={{
                  background: "#37A1DB",
                  width: 11,
                  height: 11,
                  borderRadius: "50%",
                  opacity: selectedItem.opacity,
                  marginBottom: 0,
                  marginRight: 10,
                }}
              />
              <p
                style={{
                  marginBottom: 0,
                  width: "35px",
                  fontWeight: "500",
                  fontSize: "13px",
                  color: "black",
                }}
              >
                {selectedItem.name}
              </p>
              <p
                style={{
                  color: "black",
                  fontSize: "12px",
                  fontWeight: "400",
                  marginBottom: 0,
                }}
              >
                {selectedItem.value?.toLocaleString("de-DE")} kW
              </p>
            </div>
          </div>
        )}
      </>
    );
  };

  const BOX = ({
    item,
    value,
    numberOnly,
    totalLength,
    itemObj,
    arrayLength,
  }) => {
    return (
      <div
        onMouseOver={(e) => {
          setCurrentInd(value);
          dispatch({ type: SET_DISPLAY_ANNOTATION_SUB, payload: true });
          setClientx(e.clientX);
          setClientY(e.clientY);
          setItem({
            ...itemObj,
            opacity: item / totalLength,
          });
        }}
        ref={ref}
        style={{
          width: `calc(100% / 24)`,
          height: numberOnly && filter.key === "6d" ? "80px" : "40px",
          backgroundColor: numberOnly ? "#fff" : "#37A1DB",
          opacity: numberOnly ? 1 : item / maxNum,
          borderRadius: "6px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          border: "1px solid white",
          color: "#7E84A3",
          margin: 1.5,
          textAlign: "center",
          fontSize: "14px",
          writingMode:
            numberOnly && filter.key === "6d" ? "vertical-rl" : "horizontal-tb",
        }}
      >
        <span>{numberOnly ? value : ""}</span>
      </div>
    );
  };

  return (
    <>
      {empty ? (
        <Empty />
      ) : heatMapData?.length > 0 ? (
        <>
          <Annotation />
          <div style={{ overflow: "auto" }}>
            <div style={{ minWidth: 600 }}>
              {heatMapData?.map((item, index) => {
                return (
                  <div style={{ display: "flex" }} key={index}>
                    <div
                      ref={labelRef}
                      style={{
                        minWidth: 100,
                        maxWidth: 100,
                        display: "flex",
                        alignItems: "center",
                        color: "#131523",
                        marginRight: "10px",
                        fontSize: "12px",
                      }}
                      onMouseOver={(e) => {
                        dispatch({
                          type: SET_DISPLAY_ANNOTATION_SUB,
                          payload: false,
                        });
                      }}
                    >
                      {item.name}
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flex: 1,
                        flexDirection: "row",
                        justifyContent: "space-evenly",
                      }}
                    >
                      {item.values.map((itemC, ind) => {
                        return (
                          <>
                            <BOX
                              key={ind}
                              item={itemC.value ? Number(itemC.value) : itemC}
                              value={itemC.value ? Number(itemC.value) : itemC}
                              numberOnly={item.name === ""}
                              arrayLength={item.values.length}
                              totalLength={Math.max(
                                ...item.values.map((o) => Number(o.value))
                              )}
                              itemObj={{
                                name: item.name,
                                value: itemC.value
                                  ? Number(itemC.value)
                                  : itemC,
                                title: itemC?.time,
                              }}
                            />
                          </>
                        );
                      })}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </>
      ) : (
        <div className="loading">
          <Loader />
        </div>
      )}
    </>
  );
}
