/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React, { useEffect } from "react";
import Layout from "../../adminLayout/Layout";
import "../../Styles/pages/adminDynamicSwitches.css";
import { Select, Table } from 'antd';
import { useState } from "react";
import { getSidebar, updateSidebar } from "../../redux";
import AdminSwitch from "../../Components/Switch";
const { Option } = Select;

const AdminSidebar = () => {
    const [data, setData] = useState([]);
    const [selectedValue, setSelectedValue] = useState(0);
    const [currentData, setCurrentData] = useState();

    const handleChange = (value) => {
        setSelectedValue(value);
    };

    const getData = async () => {
        const temp = await getSidebar();
        setData(temp);
    }

    const currentDataHandler = async (val) => {
        const temp2 = Object.entries(data[val])
            ?.filter(([key]) => key !== '_id' && key !== '__v' && key !== 'clientId')
            ?.map(([key, value], index) => ({ key, value }));
        setCurrentData(temp2)
    }

    useEffect(() => {
        getData();
    }, [])

    useEffect(() => {
        currentDataHandler(selectedValue)
    }, [data, selectedValue])

    const toggleSwitch = async (key, value) => {
        let temp = data[selectedValue];
        temp[key] = value;
        await updateSidebar(temp)
        getData();
    };

    const columns = [
        {
            title: "Key",
            dataIndex: "key",
            key: "key",
            render: (_, record) => (
                <>
                    {record?.key?.charAt(0).toUpperCase() + record?.key?.slice(1)}
                </>

            ),
        },
        {
            title: 'Operation',
            dataIndex: 'operation',
            render: (_, record) => {
                return (
                    <AdminSwitch
                        value={record?.value}
                        handleSwitchChange={(val) => toggleSwitch(record?.key, val)}
                    />
                )
            },
        },
    ]

    return (
        <Layout active="admin-sidebar">
            <div className="admin-container-switches">
                <Select defaultValue={0} style={{ width: 200 }} onChange={handleChange}>
                    {data?.map((item, idx) => (
                        <Option value={idx} key={idx}>{item?.clientId?.userName}</Option>
                    ))}
                </Select>
                {data &&
                    <Table
                        dataSource={currentData}
                        columns={columns}
                    />
                }

            </div>
        </Layout>
    );
};

export default AdminSidebar;

