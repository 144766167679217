/* eslint-disable no-unused-vars */
import { useState } from "react";
import { Button, Form, Input, Typography } from "antd";
import { useHistory, Redirect, Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { ManagerLogin } from "../redux/index";
import logo from "../Assets/logo2.png";

const Service = () => {
    const history = useHistory();

    const dispatch = useDispatch();

    const [loading, setLoading] = useState(false);
    const [demoLoading, setDemoLoading] = useState(false);

    const clientLoginFnc = async (values, demo = false) => {
        const { email, password } = values;
        if (demo) {
            setDemoLoading(true);
            await dispatch(ManagerLogin({ email, password }, history, true));
            setDemoLoading(false);
        } else {
            setLoading(true);
            await dispatch(ManagerLogin({ email, password }, history));
            setLoading(false);
        }
    };

    if (localStorage.hasOwnProperty("token")) {
        return <Redirect to="/services-dashboard" />;
    } else {
        return (
            <div className="temp-login-main">
                <div className="login-main">
                    <img src={logo} alt="" />
                    <p>Sign in to your account to continue</p>
                    <Form
                        layout="vertical"
                        onFinish={clientLoginFnc}
                        className="login-form"
                    >
                        <Typography.Title level={2}>Welcome!</Typography.Title>

                        <Form.Item name={"email"} label={"Email"}>
                            <Input />
                        </Form.Item>
                        <Form.Item name={"password"} label={"Password"}>
                            <Input type="password" />
                        </Form.Item>
                        <div className="remember-check">
                            <span />
                            {/* <Checkbox>Remember Me</Checkbox> */}
                            <Link to={"/service"}>Forgot Password?</Link>
                        </div>
                        <Form.Item>
                            <Button type="primary" loading={loading} htmlType="submit">
                                Login
                            </Button>
                        </Form.Item>

                        <div className="signup">
                            Don’t have an account?{" "}
                            <Link to="/service-signup">
                                <span>Sign up</span>
                            </Link>
                        </div>
                    </Form>
                </div>
            </div>
        );
    }
};

export default Service;
