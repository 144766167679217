/* eslint-disable no-dupe-keys */
import TooltipComp2 from "../TooltipComp2";
import { Column } from "@ant-design/plots";

const DemoTinyColumn = ({
  Data,
  alias,
  tooltip,
  unit,
  color,
  radius,
  fields,
}) => {

  const data = [
    {
      title: "1",
      value: 9.2,
    },
    {
      title: "2",
      value: 11.8,
    },
    {
      title: "3",
      value: 6.4,
    },
    {
      title: "4",
      value: 4.8,
    },
    {
      title: "5",
      value: 7.5,
    },
    {
      title: "6",
      value: 5.7,
    },
    {
      title: "7",
      value: 8.3,
    },
  ];

  const config = {
    xField: fields ? fields?.[0] : "title",
    yField: fields ? fields?.[1] : "value",
    data: Data ? Data : data,
    color: color ? color : "#37A1DB",
    meta: {
      value: {
        alias: alias ? alias : "Value",
      },
    },
    tooltip: tooltip,
    xAxis: {
      grid: {
        line: {
          style: {
            stroke: "black",
            lineWidth: 0,
          },
        },
        alignTick: true,
        closed: false,
        nice: false,
      },
    },

    yAxis: {
      title: {
        text: unit ? unit : "tons",
        style: {
          stroke: "#000000",
          fontSize: 12,
          color: "#000000",
          letterSpacing: "1px",
          fontWeight: 300,
          opacity: 0.9,
          shadowBlur: 0,
          strokeOpacity: 0,
        },
      },
      grid: {
        line: {
          style: {
            stroke: "black",
            lineWidth: 0.1,
          },
        },
        alignTick: true,
        closed: true,
      },
    },
    columnStyle: {
      radius: radius ? radius : [20, 20, 0, 0],
    },
    tooltip: {
      domStyles: {
        "g2-tooltip": {
          background: "white",
          color: "white",
          opacity: 1,
          borderRadius: "8px",
        },
      },
      customContent: (title, data) => {
        return (
          <TooltipComp2
            data={data}
            withoutTitle
            units={["kg"]}
            fields={["CO2 Savings"]}
            hollow
          />
        );
      },
    },
  };
  return <Column {...config} />;
};

export default DemoTinyColumn;
