import { publicAPI } from "../../API";

export const getSidebar = async () => {
    try {
        const res = await publicAPI.get(`/sidebar/`);
        if (res) {
            return res?.data;
        }
    } catch (err) {
        console.log("err", err);
    }
};

export const updateSidebar = async (payload) => {
    try {
        const res = await publicAPI.put(`/sidebar/update`, payload);
        if (res) {
            return res?.data;
        }
    } catch (err) {
        console.log("err", err);
    }
};

export const getSidebarOfUser = async (payload) => {
    try {
        const res = await publicAPI.post(`/sidebar/user`, payload);
        if (res) {
            return res.data;
        }
    } catch (err) {
        console.log("err", err);

    }
};

export const setSidebar = async (payload) => {
    try {
        const res = await publicAPI.post(`/sidebar/create`, payload);
        if (res) {
            return res.data;
        }
    } catch (err) {
        console.log("err", err);

    }
};

export const deleteSidebar = async (payload) => {
    try {
        const res = await publicAPI.post(`/sidebar/delete`, payload);
        if (res) {
            return res.data;
        }
    } catch (err) {
        console.log("err", err);

    }
};