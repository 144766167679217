import { Alert, Button, Input } from 'antd'
import React from 'react'
import { useState } from 'react'
import { createLanguage } from '../../redux/actions/languagesActions';

const CreateLanguage = ({ handleClose }) => {
    const [alert, setAlert] = useState({ type: '', message: '' });
    const [data, setData] = useState({
        key: '',
        en: '',
        de: '',
        it: '',
    });

    const handleCreate = async () => {
        if (data.key !== '' && data.en !== '' && data.de !== '' && data.it !== '') {
            await createLanguage(data);
            setAlert({ type: 'success', message: 'Created Language' });
            handleClose();
        } else {
            setAlert({ type: 'danger', message: 'Fill All Field!' });
        }
    }

    return (
        <>
            <Alert
                message={alert.message}
                type={alert.type}
                closable
            />
            <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
                <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                    <label style={{ width: '70px' }}>Key: </label>
                    <Input style={{ flex: '1' }} value={data?.key} onChange={(e) => setData({ ...data, key: e.target.value })} />
                </div>
                <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                    <label style={{ width: '70px' }}>English: </label>
                    <Input style={{ flex: '1' }} value={data?.en} onChange={(e) => setData({ ...data, en: e.target.value })} />
                </div>
                <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                    <label style={{ width: '70px' }}>German: </label>
                    <Input style={{ flex: '1' }} value={data?.de} onChange={(e) => setData({ ...data, de: e.target.value })} />
                </div>
                <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                    <label style={{ width: '70px' }}>Italian: </label>
                    <Input style={{ flex: '1' }} value={data?.it} onChange={(e) => setData({ ...data, it: e.target.value })} />
                </div>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Button style={{ background: 'grey', color: 'white', width: '200px' }} onClick={handleCreate}>Create Language</Button>
                </div>

            </div>
        </>
    )
}

export default CreateLanguage