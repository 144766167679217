/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import Layout from "../Layout/MainLayout";
import { Typography, Row, Col, Select, Tooltip } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import CircleLine from "../Components/charts/CircleLine";
import StackedAreaPlot from "../Components/charts/StackedAreaPlot";
import AverageConsumptionChart from "../Components/charts/AverageConsumptionChart";
import { AiOutlineFlag } from "react-icons/ai";
import Image from "../Assets/Vector.png";
import React, { useEffect, useState } from "react"
import DemoTinyLine from "../Components/charts/TinyArea";
import Co2Consumption from "../Components/charts/Co2Consumption";
import MultiView from "../Components/charts/MultiView";
import {
  getActualCo2Emission,
  getCountryCo2Emission,
  getAverageConsumption,
  getCo2Saved,
  getCo2EmissionConsumption,
} from "../redux";
import { useTranslation } from "react-i18next";

const Co2Emission = () => {
  let text = "This box represents the total conusmed energy by the client";

  const { t } = useTranslation();
  const [actual, setActual] = useState([]);
  const [countryCo2, setCountryCo2] = useState(null);
  const [averageConsumption, setAvergaeConsumption] = useState(null);
  const [actualFilter, setActualFilter] = useState("1week");
  const [co2Saved, setco2Saved] = useState(null);
  const [co2SavedFilter, setco2SavedFilter] = useState("1week");
  const [co2Consumption, setCO2Consumption] = useState(null);

  const onActualFilterChange = async (val) => {
    setActualFilter(val);
    let res = await getActualCo2Emission({ range: val });
    setActual(res);
  };

  const onCo2FilterChange = async (val) => {
    setco2SavedFilter(val);
    let res = await getCo2Saved({ range: val });
    setco2Saved(res);
  };

  const getActualData = async () => {
    let res = await getActualCo2Emission({ range: actualFilter });
    setActual(res);
  };

  const getcountryCo2Data = async () => {
    let cco2 = await getCountryCo2Emission();
    setCountryCo2(cco2);
  };

  const getavergaeConsumption = async () => {
    let res = await getAverageConsumption();
    setAvergaeConsumption(res);
  };

  const getco2Saved = async () => {
    let res = await getCo2Saved({ range: co2SavedFilter });
    setco2Saved(res);
  };
  const getco2Consumption = async (e) => {
    let res = await getCo2EmissionConsumption({ range: e ? e : 'thisweek' });
    setCO2Consumption(res?.data);
  };

  useEffect(() => {
    getActualData();
    getcountryCo2Data();
    getavergaeConsumption();
    getco2Saved();
    getco2Consumption()
  }, []);

  return (
    <Layout active="co2emission">
      <div className="live-main energy-main" style={{ marginBottom: "30px" }}>
        <Row gutter={[30, 30]}>
          <Col xs={24} md={12} xl={8}>
            <div className="live-card white-card">
              <Typography.Title
                className="title main-title"
                level={5}
                style={{
                  fontWeight: "500",
                  marginBottom: "20px",
                  height: "10px",
                }}
              >
                <span
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexWrap: "wrap",
                    fontSize: "13px"
                  }}
                >
                  {t('actual_co2_emission')}
                  <Tooltip title={text} color={"#37A1DB"}>
                    <InfoCircleOutlined
                      style={{ marginLeft: "5px" }}
                    />
                  </Tooltip>
                </span>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginLeft: 0,
                    height: "10px",
                  }}
                >
                  <div
                    style={{
                      background: "#4F99FD",
                      width: 10,
                      height: 10,
                      borderRadius: "50%",
                      marginRight: "5px",
                    }}
                  />
                  <p
                    style={{
                      fontSize: "0.75rem",
                      fontWeight: 600,
                      height: "16px",
                      marginBottom: "0px",
                    }}
                  >
                    132 kg
                  </p>
                </div>
                <Select
                  value={actualFilter}
                  style={{ width: "max-content" }}
                  onChange={onActualFilterChange}
                >
                  <Select.Option value="1week">{t('one_week')}</Select.Option>
                  <Select.Option value="thismonth">{t('one_month')}</Select.Option>
                </Select>
              </Typography.Title>

              <div className="chart-wrapper">
                <DemoTinyLine
                  TooltipUnit="Kg/day"
                  unit={"Kg"}
                  Data={actual}
                  color={{
                    hex: "#4F99FD",
                    rgb: [126, 202, 254],
                  }}
                  fields={["date", "value"]}
                />
              </div>
            </div>
          </Col>

          <Col xs={24} md={12} xl={8}>
            <div className="live-card white-card">
              <Typography.Title
                className="title main-title"
                level={5}
                style={{
                  fontWeight: "500",
                  marginBottom: "20px",
                  height: "10px",
                }}
              >
                <Row align="middle" style={{ width: "100%" }}>
                  <Col xs={24} md={16} xl={15}>
                    <span
                      style={{
                        display: "flex",
                        alignItems: "center",
                        flexWrap: "wrap",
                        fontSize: "13px"
                      }}
                    >
                      {t('country_co2_emission')}
                      <Tooltip title={text} color={"#37A1DB"}>
                        <InfoCircleOutlined
                          style={{ marginLeft: "5px" }}
                        />
                      </Tooltip>
                    </span>
                  </Col>
                  <Col xs={24} md={8} xl={9}>
                    <div
                      style={{
                        display: "flex",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginRight: "5px",
                        }}
                      >
                        <div
                          style={{
                            background: "#F99600",
                            width: 10,
                            height: 10,
                            borderRadius: "50%",
                            marginRight: 5,
                          }}
                        />
                        <span
                          style={{
                            fontSize: "0.75rem",
                            fontWeight: 600,
                            height: "16px",
                          }}
                        >
                          {t('renewable')}
                        </span>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginLeft: 0,
                        }}
                      >
                        <div
                          style={{
                            background: "#DD243B",
                            width: 10,
                            height: 10,
                            borderRadius: "50%",
                            marginRight: 5,
                          }}
                        />
                        <span
                          style={{
                            fontSize: "0.75rem",
                            fontWeight: 600,
                            height: "16px",
                          }}
                        >{t('co2_consumption')}
                        </span>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Typography.Title>
              <div
                className="flex-between"
                style={{ justifyContent: "center" }}
              >
                <div
                  className="chart-wrapper"
                  style={{
                    width: "100%",
                  }}
                >
                  <StackedAreaPlot
                    lineTwoColor="#F99600"
                    lineOneColor="#DD243B"
                    from="countryCo2"
                    chartData={countryCo2}
                    yFields={["co2_value", "renewable_value"]}
                  />
                </div>
              </div>
            </div>
          </Col>

          <Col xs={24} md={12} xl={8}>
            <div className="live-card white-card">
              <Typography.Title
                className="title main-title"
                level={5}
                style={{
                  fontWeight: "500",
                  marginBottom: "20px",
                  height: "10px",
                }}
              >
                <Row align="middle" style={{ width: "100%" }}>
                  <Col xs={24} md={16} xl={15}>
                    <span
                      style={{
                        display: "flex",
                        alignItems: "center",
                        flexWrap: "wrap",
                        fontSize: "13px"
                      }}
                    >
                      {t('average_consumption')}
                      <Tooltip title={text} color={"#37A1DB"}>
                        <InfoCircleOutlined
                          style={{ marginLeft: "5px", marginRight: 15 }}
                        />
                      </Tooltip>
                    </span>
                  </Col>
                  <Col xs={24} md={8} xl={9}>
                    <div
                      style={{
                        display: "flex",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginRight: "5px",
                        }}
                      >
                        <div
                          style={{
                            background: "#4F99FD",
                            width: 10,
                            height: 10,
                            borderRadius: "50%",
                            marginRight: 5,
                          }}
                        />
                        <span
                          style={{
                            fontSize: "0.75rem",
                            fontWeight: 600,
                            height: "16px",
                          }}
                        >
                          {t('average')}
                        </span>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginLeft: 0,
                        }}
                      >
                        <div
                          style={{
                            background: "#DD243B",
                            width: 10,
                            height: 10,
                            borderRadius: "50%",
                            marginRight: 5,
                          }}
                        />
                        <span
                          style={{
                            fontSize: "0.75rem",
                            fontWeight: 600,
                            height: "16px",
                          }}
                        >
                          {t('today')}
                        </span>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Typography.Title>
              <div
                className="flex-between"
                style={{ justifyContent: "center" }}
              >
                <div
                  className="chart-wrapper"
                  style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <div style={{ width: "100%" }}>
                    <AverageConsumptionChart
                      lineTwoColor="#DD243B"
                      lineOneColor="#4F99FD"
                      chartData={averageConsumption}
                      yFields={["average_value", "today_value"]}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col xs={24}>
            <div className="white-card live-big-card">
              <Typography.Title
                className="title main-title"
                level={4}
                style={{ fontWeight: "600", marginBottom: "30px" }}
              >
                <span style={{ display: "flex", alignItems: "center" }}>
                  {t('co2_consumption')}
                  <Tooltip title={text} placement="right" color={"#37A1DB"}>
                    <InfoCircleOutlined style={{ marginLeft: "5px" }} />
                  </Tooltip>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "3px",
                      marginLeft: "10px",
                    }}
                  >
                    <div
                      style={{
                        background: "#DD243B",
                        width: 10,
                        height: 10,
                        borderRadius: "50%",
                        marginRight: 2,
                      }}
                    />
                    <span
                      style={{
                        fontSize: "0.75rem",
                        fontWeight: 600,
                        height: "16px",
                      }}
                    >
                      2022
                    </span>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "3px",
                      marginLeft: "10px",
                    }}
                  >
                    <div
                      style={{
                        background: "#4F99FD",
                        width: 10,
                        height: 10,
                        borderRadius: "50%",
                        marginRight: 2,
                      }}
                    />
                    <span
                      style={{
                        fontSize: "0.75rem",
                        fontWeight: 600,
                        height: "16px",
                      }}
                    >
                      2023
                    </span>
                  </div>
                </span>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "20px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "3px",
                      marginLeft: "10px",
                    }}
                  >
                    <img
                      src={Image}
                      style={{
                        width: "14px",
                        height: "14px",
                      }}
                    />
                    <span
                      style={{
                        fontSize: "0.75rem",
                        fontWeight: 600,
                        height: "16px",
                      }}
                    >
                      {t('goal')}:- 25%
                    </span>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "3px",
                      marginLeft: "10px",
                    }}
                  >

                    <AiOutlineFlag
                      style={{
                        fontSize: "14px",
                        color: "#4F99FD",
                      }}
                    />
                    <span
                      style={{
                        fontSize: "0.75rem",
                        fontWeight: 600,
                        height: "16px",
                      }}
                    >
                      YTD:- 15%
                    </span>
                  </div>

                  <Select
                    defaultValue={"thisweek"}
                    style={{ width: "max-content" }}
                    onChange={getco2Consumption}
                  >
                    <Select.Option value="thisweek" >{t('this_week')}</Select.Option>
                    <Select.Option value="thismonth" >{t('this_month')}</Select.Option>
                    <Select.Option value="thisyear" >{t('this_year')}</Select.Option>
                    <Select.Option value="lastweek" >{t('last_week')}</Select.Option>
                    <Select.Option value="lastmonth" >{t('last_month')}</Select.Option>
                    <Select.Option value="lastyear" >{t('last_year')}</Select.Option>
                  </Select>
                </div>
              </Typography.Title>
              <div
                style={{
                  width: "100%",
                }}
              >
                {co2Consumption && <Co2Consumption leftUnit="kg" rightUnit="kWh" data={co2Consumption} />}
              </div>
            </div>
          </Col>
          <Col xs={24} lg={12}>
            <div className="white-card tiny-column-card">
              <Typography.Title
                className="title main-title"
                level={5}
                style={{ fontWeight: "500", marginBottom: "10px" }}
              >
                <span style={{ display: "flex", alignItems: "center" }}>
                  {t('co2_consumption')}
                  <Tooltip title={text} color={"#37A1DB"}>
                    <InfoCircleOutlined style={{ marginLeft: "5px" }} />
                  </Tooltip>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "3px",
                      marginLeft: "10px",
                    }}
                  >
                    <div
                      style={{
                        background: "#2EC276",
                        width: 10,
                        height: 10,
                        borderRadius: "50%",
                        marginRight: 2,
                      }}
                    />
                    <span
                      style={{
                        fontSize: "0.75rem",
                        fontWeight: 600,
                      }}
                    >
                      {t('enexpert')}
                    </span>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "3px",
                      marginLeft: "10px",
                    }}
                  >
                    <div
                      style={{
                        background: "#DD243B",
                        width: 10,
                        height: 10,
                        borderRadius: "50%",
                        marginRight: 2,
                      }}
                    />
                    <span
                      style={{
                        fontSize: "0.75rem",
                        fontWeight: 600,
                      }}
                    >
                      {t('market')}
                    </span>
                  </div>
                </span>

                <Select
                  defaultValue={"lastweek"}
                  style={{ width: "max-content" }}
                >
                  <Select.Option value="thisweek" >{t('this_week')}</Select.Option>
                  <Select.Option value="thismonth" >{t('this_month')}</Select.Option>
                  <Select.Option value="thisyear" >{t('this_year')}</Select.Option>
                  <Select.Option value="lastweek" >{t('last_week')}</Select.Option>
                  <Select.Option value="lastmonth" >{t('last_month')}</Select.Option>
                  <Select.Option value="lastyear" >{t('last_year')}</Select.Option>
                </Select>
              </Typography.Title>
              <div className="tinycolumn">
                <MultiView />
              </div>
            </div>
          </Col>
          <Col xs={24} lg={12}>
            <div className="white-card tiny-column-card">
              <Typography.Title
                className="title main-title"
                level={5}
                style={{ fontWeight: "500", marginBottom: "10px" }}
              >
                <span style={{ display: "flex", alignItems: "center" }}>
                  {t('co2_saved')}
                  <Tooltip title={text} color={"#37A1DB"}>
                    <InfoCircleOutlined style={{ marginLeft: "5px" }} />
                  </Tooltip>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "3px",
                      marginLeft: "10px",
                    }}
                  >
                    <div
                      style={{
                        background: "#DD243B",
                        width: 10,
                        height: 10,
                        borderRadius: "50%",
                        marginRight: 2,
                      }}
                    />
                    <span
                      style={{
                        fontSize: "0.75rem",
                        fontWeight: 600,
                        height: "16px",
                      }}
                    >
                      {t('co2')}
                    </span>
                  </div>
                </span>

                <Select
                  defaultValue={co2SavedFilter}
                  style={{ width: "max-content" }}
                  onChange={onCo2FilterChange}
                >
                  <Select.Option value="1week">{t('one_week')}</Select.Option>
                  <Select.Option value="thismonth">{t('one_month')}</Select.Option>
                </Select>
              </Typography.Title>
              <div className="tinycolumn">
                <CircleLine
                  TooltipUnit={"Kg"}
                  unit={`Kg`}
                  Data={co2Saved}
                  color={"#DD243B"}
                />
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </Layout>
  );
};

export default Co2Emission;
