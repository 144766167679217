/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Col, Form, Input, Row, Select } from 'antd'
import React, { useEffect, useState } from 'react'
import AdminSwitch from './Switch'
import { CloseOutlined } from '@ant-design/icons';
const { Option } = Select;

const AdminModal = ({ subnodes, Value, setValue, positions, data, id, selectedValue, section }) => {
    const [modalValue, setModalValue] = useState();
    const [position, setPosition] = useState();
    const [form, setForm] = useState();

    console.log(modalValue)
    useEffect(() => {
        setModalValue(Value)
        setPosition(Value?.position);
        setForm(data[selectedValue])

    }, [Value])

    const handleSwitchChange = (e) => {
        setModalValue({ ...modalValue, switch: e })
    };

    const saveHandler = () => {
        let temp = form;
        temp[section] = temp[section]?.map((item) => {
            if (item?._id === id) {
                console.log(section)
                return { ...modalValue };
            } else {
                return item;
            }
        })

        setValue(temp);
    }

    const updatePosition = (e) => {
        setModalValue({
            ...modalValue, position: e,
            // measNodes: [
            //     {
            //         subNodes: ["64560de0a0faf60012ccf6e8", "64560eaea0faf60012ccf6ea", "64560f36a0faf60012ccf6ec",
            //             "64560f5ba0faf60012ccf6ee", "645628b7230d5a001282c33c", "645628c8230d5a001282c33e"],
            //         code: "5175",
            //         name: "Anlagen",
            //         Type: "power"
            //     },
            //     {
            //         subNodes: ["645613a2135fc90012a8c63c", "645613d0135fc90012a8c63e", "64561405135fc90012a8c641",
            //             "64562f43482778001123207f"],
            //         code: "5176",
            //         name: "Bildung",
            //         Type: "power"
            //     },
            //     {
            //         subNodes: ["6456143d135fc90012a8c664", "6456145b135fc90012a8c6a8", "64561478135fc90012a8c6aa",
            //             "6456149a135fc90012a8c6ac"],
            //         code: "5177",
            //         name: "Gebäude",
            //         Type: "power"
            //     },
            //     {
            //         subNodes: ["6456238e230d5a001282c317", "64561549135fc90012a8c6ae", "64561551135fc90012a8c6b0",
            //             "64561558135fc90012a8c6b2"],
            //         code: "5178",
            //         name: "Kinder und Jugend",
            //         Type: "power"
            //     }]
        });
        let temp = form;
        temp[section] = temp[section].map((item) => {
            if (item?._id === id) {
                return {
                    ...modalValue, position: e,
                    // measNodes: [
                    //     {
                    //         subNodes: ["64560de0a0faf60012ccf6e8", "64560eaea0faf60012ccf6ea", "64560f36a0faf60012ccf6ec",
                    //             "64560f5ba0faf60012ccf6ee", "645628b7230d5a001282c33c", "645628c8230d5a001282c33e"],
                    //         code: "5175",
                    //         name: "Anlagen",
                    //         Type: "power"
                    //     },
                    //     {
                    //         subNodes: ["645613a2135fc90012a8c63c", "645613d0135fc90012a8c63e", "64561405135fc90012a8c641",
                    //             "64562f43482778001123207f"],
                    //         code: "5176",
                    //         name: "Bildung",
                    //         Type: "power"
                    //     },
                    //     {
                    //         subNodes: ["6456143d135fc90012a8c664", "6456145b135fc90012a8c6a8", "64561478135fc90012a8c6aa",
                    //             "6456149a135fc90012a8c6ac"],
                    //         code: "5177",
                    //         name: "Gebäude",
                    //         Type: "power"
                    //     },
                    //     {
                    //         subNodes: ["6456238e230d5a001282c317", "64561549135fc90012a8c6ae", "64561551135fc90012a8c6b0",
                    //             "64561558135fc90012a8c6b2"],
                    //         code: "5178",
                    //         name: "Kinder und Jugend",
                    //         Type: "power"
                    //     }]
                };
            }
            if (e < position) {
                if (item?._id !== id && item.position >= e && item.position <= position) {
                    return { ...item, position: item?.position + 1 > positions ? 1 : item?.position + 1 }
                } else {
                    return item;
                }
            } else {
                if (item?._id !== id && item.position < e && item.position > position) {
                    return item;
                }
                else {
                    return { ...item, position: item?.position + 1 > positions ? 1 : item?.position + 1 }
                }
            }
        })
        setForm(temp);
    }

    const handleSelectChange = (value, name, index) => {
        const updatedMeasNodes = [...modalValue?.measNodes];
        updatedMeasNodes[index][name] = value;
        setModalValue({ ...modalValue, measNodes: updatedMeasNodes });
    };

    const addNewMeasNode = () => {
        setModalValue((prevState) => ({
            ...prevState,
            measNodes: [
                ...prevState.measNodes,
                {
                    name: '',
                    code: '',
                    Type: '',
                    subNodes: [],
                },
            ],
        }));
    };

    const removeMeasNode = (index) => {
        const updatedMeasNodes = [...modalValue?.measNodes];
        updatedMeasNodes.splice(index, 1);
        setModalValue({ ...modalValue, measNodes: updatedMeasNodes });
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
            <div style={{ display: 'flex', gap: '10px' }}>
                <div>Title:</div>  {modalValue?.title}
            </div>
            <div style={{ display: 'flex', gap: '10px' }}>
                <div>Switch:</div>
                <AdminSwitch value={modalValue?.switch} handleSwitchChange={handleSwitchChange} />
            </div>
            <div style={{ display: 'flex', gap: '10px' }}>
                <div>Position:</div>
                <Select value={modalValue?.position} style={{ width: 70 }} onChange={updatePosition}>
                    {
                        Array.from({ length: positions }).map((_, idx) => (
                            <Option value={idx + 1} key={idx + 1}>{idx + 1}</Option>
                        ))
                    }
                </Select>
            </div>
            <div style={{ display: 'flex', gap: '10px' }}>
                <div>Width:</div>
                <div>sm:</div>
                <div>
                    <Select value={modalValue?.width?.sm} style={{ width: 70 }} onChange={(e) => setModalValue({ ...modalValue, width: { ...modalValue.width, sm: e } })}>
                        <Option value={24} key='24'>24</Option>
                        <Option value={16} key='16'>16</Option>
                        <Option value={12} key='12'>12</Option>
                        <Option value={11} key='11'>11</Option>
                        <Option value={10} key='10'>10</Option>
                        <Option value={9} key='9'>9</Option>
                        <Option value={8} key='8'>8</Option>
                        <Option value={7} key='7'>7</Option>
                        <Option value={6} key='6'>6</Option>
                    </Select>
                </div>
                <div>md:</div>
                <div>
                    <Select value={modalValue?.width?.md} style={{ width: 70 }} onChange={(e) => setModalValue({ ...modalValue, width: { ...modalValue.width, md: e } })}>
                        <Option value={24} key='24'>24</Option>
                        <Option value={16} key='16'>16</Option>
                        <Option value={12} key='12'>12</Option>
                        <Option value={11} key='11'>11</Option>
                        <Option value={10} key='10'>10</Option>
                        <Option value={9} key='9'>9</Option>
                        <Option value={8} key='8'>8</Option>
                        <Option value={7} key='7'>7</Option>
                        <Option value={6} key='6'>6</Option>
                    </Select>
                </div>
                <div>lg:</div>
                <div>
                    <Select value={modalValue?.width?.lg} style={{ width: 70 }} onChange={(e) => setModalValue({ ...modalValue, width: { ...modalValue.width, lg: e } })}>
                        <Option value={24} key='24'>24</Option>
                        <Option value={16} key='16'>16</Option>
                        <Option value={12} key='12'>12</Option>
                        <Option value={11} key='11'>11</Option>
                        <Option value={10} key='10'>10</Option>
                        <Option value={9} key='9'>9</Option>
                        <Option value={8} key='8'>8</Option>
                        <Option value={7} key='7'>7</Option>
                        <Option value={6} key='6'>6</Option>
                    </Select>
                </div>
            </div>
            <Row gutter={16}>
                {modalValue?.measNodes?.map((node, index) => (
                    <div
                        key={index}
                        style={{
                            border: '1px solid #ccc',
                            borderRadius: '4px',
                            padding: '16px',
                            marginBottom: '16px',
                        }}
                    >
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <h4 style={{ marginBottom: '16px' }}>MeasNode {index + 1}</h4>
                            {/* {index > 0 && ( */}
                            <CloseOutlined
                                style={{ fontSize: '15px', cursor: 'pointer', color: 'grey' }}
                                onClick={() => removeMeasNode(index)}
                            />
                            {/* )} */}
                        </div>
                        <Row gutter={16}>
                            <Col span={8}>
                                <Form.Item label="Name">
                                    <Input
                                        value={node?.name}
                                        onChange={(e) =>
                                            handleSelectChange(e.target.value, 'name', index)
                                        }
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item label="Code">
                                    <Input
                                        value={node?.code}
                                        onChange={(e) =>
                                            handleSelectChange(e.target.value, 'code', index)
                                        }
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item label="Type">
                                    <Input
                                        value={node?.Type}
                                        onChange={(e) =>
                                            handleSelectChange(e.target.value, 'Type', index)
                                        }
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item label="SubNodes">
                                    <Select
                                        mode="multiple"
                                        allowClear
                                        style={{ width: '100%' }}
                                        placeholder="Please select"
                                        value={node?.subNodes}
                                        options={subnodes}
                                        onChange={(value) => handleSelectChange(value, 'subNodes', index)}
                                    />
                                </Form.Item>
                            </Col>

                        </Row>
                    </div>
                ))}
            </Row>

            {modalValue?.measNodes[modalValue?.measNodes?.length - 1]?.name !== '' &&
                modalValue?.measNodes[modalValue?.measNodes?.length - 1]?.code !== '' &&
                modalValue?.measNodes[modalValue?.measNodes?.length - 1]?.Type !== '' && (
                    <Form.Item>
                        <Button onClick={addNewMeasNode} type="dashed">
                            Add MeasNode
                        </Button>
                    </Form.Item>
                )}
            <Button onClick={saveHandler}>Save</Button>
        </div>
    )
}

export default AdminModal