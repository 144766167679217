import React from "react";
import { DualAxes } from "@ant-design/plots";
import TooltipComp from "../TooltipComp";

const StackedAreaPlot = ({
  lineOneColor,
  lineTwoColor,
  chartData,
  yFields,
  from,
}) => {
  const config = {
    data: chartData ? [chartData.co2, chartData.renewable] : [[], []],
    xField: "time",
    yField: [
      yFields?.[0] ? yFields?.[0] : "value",
      yFields?.[1] ? yFields?.[1] : "value",
    ],

    legend: false,
    tooltip: {
      offset: 50,
      reversed: true,
      showCrosshairs: false,
      domStyles: {
        "g2-tooltip": {
          background: "white",
          color: "black",
          opacity: 1,
          borderRadius: "8px",
        },
      },
      customContent: (title, data) => {
        return (
          <TooltipComp
            data={data}
            from="country"
            units={["g/kWh", "%"]}
            fields={["CO2", "Renewable"]}
          />
        );
      },
    },

    xAxis: {
      tickCount: 3,
    },

    yAxis: {
      co2_value: {
        title: {
          text: "g/kwh",
          style: {
            stroke: "#000000",
            fontSize: 10,
            color: "#000000",
            letterSpacing: "1px",
            fontWeight: 300,
            opacity: 0.9,
            shadowBlur: 0,
            strokeOpacity: 0,
          },
        },
      },

      renewable_value: {
        title: {
          text: "%",
          style: {
            stroke: "#000000",
            fontSize: 10,
            color: "#000000",
            letterSpacing: "1px",
            fontWeight: 300,
            opacity: 0.9,
            shadowBlur: 0,
            strokeOpacity: 0,
          },
        },
      },
    },

    geometryOptions: [
      {
        geometry: "line",
        smooth: true,
        color: lineOneColor,
        lineStyle: {
          lineWidth: 3,
        },
      },
      {
        geometry: "line",
        smooth: true,
        color: lineTwoColor,
        lineStyle: {
          lineWidth: 3,
          lineDash: [5, 5],
        },
      },
    ],
  };
  return <DualAxes {...config} />;
};

export default StackedAreaPlot;
