import React from "react";
import { Line } from "@ant-design/plots";
import { ClockCircleFilled } from "@ant-design/icons";

const AverageConsumptionChart = ({
  lineOneColor,
  lineTwoColor,
  chartData,
  yFields,
}) => {
  const config = {
    data: chartData ? chartData : [],
    xField: "time",
    height: 125,
    yField: "value",
    seriesField: "type",
    legend: false,
    color: [lineOneColor, lineTwoColor],
    lineStyle: (type) => {
      if (type?.type === "average") {
        return {
          lineDash: [5, 5],
          lineWidth: 3,
        };
      } else {
        return {
          lineWidth: 3,
        };
      }
    },
    tooltip: {
      offset: 50,
      reversed: true,
      showCrosshairs: false,
      domStyles: {
        "g2-tooltip": {
          background: "white",
          color: "black",
          opacity: 1,
          borderRadius: "8px",
        },
      },
      customContent: (title, data) => {
        return (
          <div className="tooltip-comp">
            <div className="tooltip-bottom" />
            <p
              style={{
                marginBottom: "6px",
                fontSize: "15px",
                color: "black",
              }}
            >
              <>
                <ClockCircleFilled
                  style={{
                    fontSize: "12px",
                    marginRight: "6px",
                  }}
                />
                {data?.[0]?.data?.interval}
              </>
            </p>
            {data.length > 1 && (
              <div className="tooltip-item">
                <div className="tooltip-left">
                  <div
                    className="circle-dot"
                    style={{ backgroundColor: data?.[1]?.color }}
                  ></div>
                  <span className="head">{data?.[1]?.name}:</span>
                </div>
                <span className="span-value">{`${Number(
                  Number(data?.[1]?.value)?.toFixed(2)
                )?.toLocaleString("de-DE")} kg`}</span>
              </div>
            )}
            <div className="tooltip-item">
              <div className="tooltip-left">
                <div
                  className="circle-dot"
                  style={{ backgroundColor: data?.[0]?.color }}
                ></div>
                <span className="head">{data?.[0]?.name}:</span>
              </div>
              <span className="span-value">{`${Number(
                Number(data?.[0]?.value)?.toFixed(2)
              )?.toLocaleString("de-DE")} kg`}</span>
            </div>
          </div>
        );
      },
    },

    xAxis: {
      tickCount: 3,
    },
    yAxis: {
      title: {
        text: "kg/h",
        style: {
          stroke: "#000000",
          fontSize: 10,
          color: "#000000",
          letterSpacing: "1px",
          fontWeight: 300,
          opacity: 0.9,
          shadowBlur: 0,
          strokeOpacity: 0,
        },
      },
    },
  };
  return <Line {...config} />;
};

export default AverageConsumptionChart;
