import { privateAPI, attachToken } from "../../API";

export const getClientMeasNodes = () => {
  return async (dispatch) => {
    try {
      attachToken();
      const res = await privateAPI.get(`/client/getMeasNodes`);
      return res.data;
    } catch (err) {
      console.log("err", err);

    }
  };
};

export const getChannelValues = (payload) => {
  return async (dispatch) => {
    try {
      attachToken();
      const res = await privateAPI.post(`/client/getChannelValues`, payload);
      return res.data;
    } catch (err) {
      console.log("err", err);

    }
  };
};

export const getChannelValuesAverage = (payload) => {
  return async () => {
    try {
      attachToken();
      const res = await privateAPI.post(`/client/getChannelValuesAverage`, payload);
      return res.data.average;
    } catch (err) {
      console.log("err", err);
    }
  }
}
