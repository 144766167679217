/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import Routes from "./Routes";
import "antd/dist/antd.less";
import { switchTheme } from "./defaultTheme";
import "./Styles/styles.css";
import "moment-timezone";
import { notification } from "antd";

function App() {

  useEffect(() => {
    const themeConfig = JSON.parse(localStorage.getItem("themeConfig"));
    if (themeConfig) {
      switchTheme(themeConfig.mode);
    } else {
      switchTheme();
    }
  }, []);


  useEffect(() => {
    if (!navigator.onLine) {
      notification.error({
        message: "No or Slow Internet Connection",
        duration: 5,
      });
    }
  }, [navigator.onLine]);

  return <Routes />;
}

export default App;
