import React from "react";
import { DualAxes } from "@ant-design/plots";
import TooltipComp from "../TooltipComp";

const LoadPeakColumn = () => {
  const data = [
    {
      time: "01",
      value: 50,
      accumulatedSum: 50,
    },
    {
      time: "02",
      value: 22,
      accumulatedSum: 72,
    },
    {
      time: "03",
      value: 57,
      accumulatedSum: 129,
    },
    {
      time: "04",
      value: 139,
      accumulatedSum: 268,
    },
    {
      time: "05",
      value: 61,
      accumulatedSum: 329,
    },
    {
      time: "06",
      value: 63,
      accumulatedSum: 392,
    },
    {
      time: "07",
      value: 107,
      accumulatedSum: 499,
    },
    {
      time: "08",
      value: 41,
      accumulatedSum: 540,
    },
    {
      time: "09",
      value: 85,
      accumulatedSum: 625,
    },
    {
      time: "10",
      value: 146,
      accumulatedSum: 771,
    },
    {
      time: "11",
      value: 36,
      accumulatedSum: 807,
    },

    {
      time: "12",
      value: 79,
      accumulatedSum: 886,
    },
    // {
    //   time: "13",
    //   value: 110,
    //   accumulatedSum: 996,
    // },
    // {
    //   time: "14",
    //   value: 42,
    //   accumulatedSum: 1038,
    // },
    // {
    //   time: "15",
    //   value: 27,
    //   accumulatedSum: 1065,
    // },
    // {
    //   time: "16",
    //   value: 25,
    //   accumulatedSum: 1090,
    // },
    // {
    //   time: "17",
    //   value: 35,
    //   accumulatedSum: 1125,
    // },
    // {
    //   time: "18",
    //   value: 56,
    //   accumulatedSum: 1181,
    // },
    // {
    //   time: "19",
    //   value: 99,
    //   accumulatedSum: 1280,
    // },
    // {
    //   time: "20",
    //   value: 94,
    //   accumulatedSum: 1374,
    // },
    // {
    //   time: "21",
    //   value: 46,
    //   accumulatedSum: 1420,
    // },
    // {
    //   time: "22",
    //   value: 126,
    //   accumulatedSum: 1546,
    // },
    // {
    //   time: "23",
    //   value: 70,
    //   accumulatedSum: 1616,
    // },
    // {
    //   time: "24",
    //   value: 87,
    //   accumulatedSum: 1703,
    // },
    // {
    //   time: "25",
    //   value: 107,
    //   accumulatedSum: 1810,
    // },
    // {
    //   time: "26",
    //   value: 32,
    //   accumulatedSum: 1842,
    // },
    // {
    //   time: "27",
    //   value: 23,
    //   accumulatedSum: 1865,
    // },
    // {
    //   time: "28",
    //   value: 100,
    //   accumulatedSum: 1965,
    // },
    // {
    //   time: "29",
    //   value: 50,
    //   accumulatedSum: 2015,
    // },
    // {
    //   time: "30",
    //   value: 45,
    //   accumulatedSum: 2060,
    // },
    // {
    //   time: "31",
    //   value: 51,
    //   accumulatedSum: 2111,
    // },
  ];
  const config = {
    data: [data, data],
    xField: "time",
    yField: ['value', 'accumulatedSum'],
    geometryOptions: [
      {
        geometry: 'column',
      },
      {
        geometry: 'line',
        lineStyle: {
          lineWidth: 2,
        },
      },
    ],
    tooltip: {
      // position: "top",
      domStyles: {
        "g2-tooltip": {
          background: "white",
          // background: "#131523",
          color: "black",
          opacity: 1,
          borderRadius: "8px",
        },
      },
      customContent: (title, data) => {
        // console.log(data);
        const NewData = [{ ...data[0], color: "#41A6DD" }];
        return (
          <TooltipComp data={NewData} units={["kWh"]} fields={["Load Peak"]} />
        );
      },
    },
    label: false,
    columnStyle: {
      fill: "#41A6DD",
      color: "#F99600",
    },
  };
  return <DualAxes {...config} />;
};

export default LoadPeakColumn;
