/* eslint-disable no-dupe-keys */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Typography, DatePicker, Select } from "antd";
import { Line } from "@ant-design/plots";
import { getPvProductionOnClick } from "../../redux";
import Loader from "../Loader";

const PvProductionChart = ({ pvdata, unit, Alias, val }) => {
  const [data, setData] = useState([]);
  const [start, setStart] = useState(null);
  const [interval, setInterval] = useState(null);
  const [end, setEnd] = useState(null);
  const [fl, setFl] = useState(true);
  const [loading, setLoading] = useState(false);
  const onStartChange = async (date, dateString) => {
    setStart(dateString);
    setLoading(true);
    const res = await getPvProductionOnClick({
      start: dateString,
      end: end,
      interval: interval,
    });
    setFl(false);
    if (res === "Date range error") {
      alert("Please select correct date range");
    } else {
      setData(res);
    }
    setLoading(false);
  };
  const onEndChange = async (date, dateString) => {
    setLoading(true);

    setEnd(dateString);
    const res = await getPvProductionOnClick({
      start: start,
      end: dateString,
      interval: interval,
    });
    setFl(false);
    console.log("res ", res);
    if (res === "Date range error") {
      alert("Please select correct date range");
    } else {
      setData(res);
    }
    setLoading(false);
  };

  const handleChange = async (value) => {
    console.log(`selected ${value}`);
    setInterval(value);
    setLoading(true);
    const res = await getPvProductionOnClick({
      start: start,
      end: end,
      interval: value,
    });
    setFl(false);
    console.log("res ", res);
    if (res === "Date range error") {
      alert("Please select correct date range");
    } else {
      setData(res);
    }
    setLoading(false);
  };

  const config = {
    data: data,
    xField: "type",
    yField: "value",
    color: ["#0098FD", "#DD243B"],
    seriesField: "name",
    slider: {
      start: 0,
      end: 1,
    },

    tooltip: {
      domStyles: {
        "g2-tooltip": {
          minWidth: "240px",
          maxWidth: "240px",
          borderRadius: 0,
          padding: "0",
        },
      },
      customContent: (title, data) => {
        return (
          <>
            <div
              style={{
                filter: "drop-shadow(-1px 6px 3px rgba(50, 50, 0, 0.5))",
              }}
            >
              <div className="tooltip-position" style={{ bottom: "-138px" }} />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                height: 80,
                width: 240,
                color: "rgb(89, 89, 89)",
                backgroundColor: "white",
                fontFamily: "Poppins",
                borderRadius: "4px",
                padding: "0 20px",
                fontSize: "10px",
              }}
            >
              <p
                style={{
                  fontWeight: "bold",
                  fontSize: "13px",
                  color: "black",
                }}
              >
                {data?.[0]?.data?.interval}
              </p>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >

                <p
                  style={{
                    background: data?.[0]?.color,
                    width: 11,
                    height: 11,
                    borderRadius: "50%",
                    marginBottom: 0,
                    marginRight: 10,
                  }}
                />
                <p
                  style={{
                    marginBottom: 0,
                    width: "35px",
                    width: "50%",
                    fontSize: "13px",
                    fontWeight: "500",
                    color: "black",
                  }}
                >
                  {data?.[0]?.name}
                </p>
                <p
                  style={{
                    color: "black",
                    fontSize: "12px",
                    fontWeight: "400",
                    marginBottom: 0,
                  }}
                >
                  {data?.[0]?.value?.toLocaleString("de-DE")}{" "}
                  {unit ? " " + unit : " kW"}
                </p>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: "5px",
                }}
              >
                <p
                  style={{
                    background: data?.[1]?.color,
                    width: 11,
                    height: 11,
                    borderRadius: "50%",
                    marginBottom: 0,
                    marginRight: 10,
                  }}
                />
                <p
                  style={{
                    marginBottom: 0,
                    width: "35px",
                    width: "50%",
                    fontSize: "13px",
                    fontWeight: "500",
                    color: "black",
                  }}
                >
                  {data?.[1]?.name}
                </p>
                <p
                  style={{
                    color: "black",
                    fontSize: "12px",
                    fontWeight: "400",
                    marginBottom: 0,
                  }}
                >
                  {data?.[1]?.value?.toLocaleString("de-DE")}{" "}
                  {unit ? " " + unit : " kW"}
                </p>
              </div>
            </div>
          </>
        );
      },
    },
    meta: {
      sales: {
        alias: Alias ? Alias : "Production",
      },
    },
    yAxis: {
      label: {
        formatter: (v) =>
          `${v}`.replace(/\d{1,3}(?=(\d{3})+$)/g, (s) => `${s},`),
      },
    },
    legend: false,
    yAxis: {
      tickInterval: 10,
      title: {
        text: "kw",
        style: {
          fontWeight: 400,
          fontSize: 14,
        },
      },
    },
  };
  const onPlotClickHandler = (ev) => {
    console.log("DSffds");
    console.log(ev);
  };
  useEffect(() => {
    setData(pvdata);
  }, []);
  return (
    <div className="pv-container">
      <div className="head">
        <Typography.Title
          className="title"
          level={5}
          style={{ fontSize: "18px", fontWeight: 600 }}
        >
          PV Production
        </Typography.Title>
        <div className="date-range">
          <Select
            // defaultValue="lucy"
            style={{
              width: 120,
            }}
            onChange={handleChange}
            options={[
              {
                value: "2m",
                label: "2 Minutes",
              },
              {
                value: "5m",
                label: "5 Minutes",
              },
              {
                value: "15m",
                label: "15 Minutes",
              },
              {
                value: "1h",
                label: "1 Hour",
              },
              {
                value: "6h",
                label: "6 Hours",
              },
              {
                value: "12h",
                label: "12 Hours",
              },
              {
                value: "1d",
                label: "Daily",
              },
              {
                value: "7d",
                label: "Weekly",
              },
              {
                value: "30d",
                label: "Monthly",
              },
            ]}
            placeholder="Intervals"
          />
          <DatePicker placeholder="Start Date" onChange={onStartChange} />
          <DatePicker placeholder="End Date" onChange={onEndChange} />
        </div>
      </div>
      <p>{fl ? val : null}</p>

      <div className="pv-graph">
        {loading ? (
          <Loader />
        ) : (
          <Line
            {...config}
            height={300} // replace 300 with your desired height
            width={500}
            onPlotClick={onPlotClickHandler}
          />
        )}
      </div>
    </div>
  );
};
export default PvProductionChart;
