import { Area, Line } from "@ant-design/plots";
import TooltipComp2 from "../TooltipComp2";

const DemoTinyLine = ({
  color,
  noArea,
  TooltipUnit,
  unit,
  fullArea,
  Data,
  fields,
  fullLine,
}) => {
  // const data = [
  //   264, 417, 438, 887, 309, 397, 550, 575, 563, 430, 525, 592, 492, 467, 513,
  //   546, 983, 340, 539, 243, 226, 192,
  // ];
  // console.log("tiny chart run");
  const data = [
    {
      time: "1",
      value: 25,
    },
    {
      time: "2",
      value: 31,
    },
    {
      time: "3",
      value: 6,
    },
    {
      time: "4",
      value: 42,
    },
    {
      time: "5",
      value: 45,
    },
    {
      time: "6",
      value: 35,
    },
    {
      time: "7",
      value: 38,
    },
  ];

  // const [state, setState] = useState(10000);
  // useEffect(() => {
  //   let interval = setInterval(() => {
  //     setState(Number(Math.random() * (6000 - 8000) + 8000).toFixed());
  //   }, 20000);
  //   return () => {
  //     clearInterval(interval);
  //   };
  // }, []);
  // const data = [25, 31, 6, 42, 45, 35, 38];
  const config = {
    height: 60,
    // xField: Data ? fields?.[0]:'time',
    // yField:  Data ? fields?.[1]:'value',
    // xField: "date",
    // yField: "value",
    xField: fields?.[0],
    yField: fields?.[1],
    autoFit: true,
    color: color.hex,
    data: Data ? Data : data,
    meta: {
      value: {
        alias: "kWh",
        // min: 0,
        // max: 100,
      },
      percentage: {
        alias: TooltipUnit,
      },
    },

    lineStyle: {
      lineDash: fullLine ? [0, 0] : [4, 4],
    },

    tooltip: {
      // position: "relative",
      offset: 50,
      reversed: true,
      showCrosshairs: false,
      domStyles: {
        "g2-tooltip": {
          // background: "#131523",
          background: "white",
          color: "white",
          opacity: 1,
          borderRadius: "8px",
          // position: "absoulute",
          // top: "-50px",
          // left: "50%",
        },
      },
      customContent: (title, data) => {
        // console.log(data);
        return (
          <TooltipComp2
            data={data}
            withoutTitle
            units={[TooltipUnit]}
            fields={["Self Consumption"]}
          />
        );
      },
    },

    // {
    //   line: null,
    //   grid: {
    //     line: {
    //       style: {
    //         stroke: "black",
    //         lineWidth: 0.1,
    //       },
    //     },
    //     alignTick: true,
    //     closed: true,
    //   },
    // }
    // xAxis: {
    // title: {
    //   text: "last week",
    //   style: {
    //     stroke: "#7E84A3",
    //     fontSize: 12,
    //     letterSpacing: "1px",
    //     fontWeight: 300,
    //     opacity: 0.7,
    //     shadowBlur: 0,
    //     strokeOpacity: 0,
    //   },
    // },
    // },
    yAxis: {
      // tickCount: 3,
      title: {
        text: unit ? unit : "%",
        // style: {
        //   stroke: "#7E84A3",
        //   fontSize: 12,
        //   letterSpacing: "1px",
        //   fontWeight: "bold",
        //   opacity: 0.7,
        //   shadowBlur: 0,
        //   strokeOpacity: 0,
        // },
        style: {
          stroke: "#000000",
          fontSize: 10,
          color: "#000000",
          letterSpacing: "1px",
          fontWeight: 300,
          opacity: 0.9,
          shadowBlur: 0,
          strokeOpacity: 0,
        },
      },
      grid: {
        line: {
          style: {
            stroke: "black",
            lineWidth: 0,
          },
        },
        alignTick: true,
        closed: true,
      },
    },

    smooth: true,
    // animation: {
    //   appear: {
    //     easing: "wave-in",
    //     duration: 30000,
    //     delay: 0,
    //     repeat: true,
    //   },
    // },
    animation: false,
    areaStyle: noArea
      ? (value) => {
        return {
          fill: `l(270) 0:rgba(${color.rgb[0]},${color.rgb[1]},${color.rgb[2]},0) 0.5:rgba(${color.rgb[0]},${color.rgb[1]},${color.rgb[2]},0) 1:rgba(${color.rgb[0]},${color.rgb[1]},${color.rgb[2]},0)`,
        };
      }
      : fullArea
        ? {
          fillOpacity: 1,
        }
        : (value) => {
          return {
            fill: `l(270) 0:rgba(${color.rgb[0]},${color.rgb[1]},${color.rgb[2]},1) 0.5:rgba(${color.rgb[0]},${color.rgb[1]},${color.rgb[2]},1) 1:rgba(${color.rgb[0]},${color.rgb[1]},${color.rgb[2]},1)`,
          };
        },
    //   fill: "#d6e3fd",
    //   fill: "#37A1DB",
    //   fillOpacity: 0.5,
    //   stroke: "#37A1DB",
    // },
  };
  return noArea ? <Line {...config} /> : <Area {...config} />;
};

export default DemoTinyLine;
