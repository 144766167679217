/* eslint-disable array-callback-return */
import React, { useEffect } from "react";
import Layout from "../../adminLayout/Layout";
import "../../Styles/pages/adminDynamicSwitches.css";
import { Button, Col, Modal, Row } from "antd";
import { Select } from 'antd';
import { getAllSubnodes, getDynamics, updateDynamic } from "../../redux/actions/adminSwitchesActions";
import { useState } from "react";
import AdminModal from "../../Components/AdminModal";
const { Option } = Select;

const sections = [
    'home', 'live', 'energy', 'loadpeak',
    'digitalization', 'emobility', 'analysis',
    'coemission', 'details', 'co2management'
];

const AdminDynamicSwitches = () => {
    const [data, setData] = useState([]);
    const [selectedValue, setSelectedValue] = useState(0);
    const [visible, setVisible] = useState(false);
    const [modalValue, setModalValue] = useState({});
    const [section, setSection] = useState();
    const [index, setIndex] = useState();
    const [positions, setPosition] = useState(5);
    const [subnodes, setSubnodes] = useState([]);



    const showModal = (sec, id) => {
        setPosition(data[selectedValue][sec]?.length)
        setSection(sec);
        setIndex(id);
        let temp = data[selectedValue][sec].find(item => item?._id === id);
        setModalValue(temp);
        setVisible(true);
    };

    const handleOk = (value) => {
        setVisible(false);
        updateDynamic({ _id: value._id, [section]: value[section] })
        setModalValue({})
        setSection();
        setIndex();
    };

    const handleCancel = () => {
        setVisible(false);
        setModalValue({})
    };


    const handleChange = (value) => {
        setSelectedValue(value);
    };

    const getData = async () => {
        const temp = await getDynamics();
        setData(temp);
        const temp2 = await getAllSubnodes();
        let temp3 = temp2?.map((item, idx) => ({
            label: item?.name,
            value: item?._id,
        }));
        setSubnodes(temp3);

    }

    useEffect(() => {
        getData();
    }, [])


    const colors = [
        '#FF0000',  // Red  
        '#0000FF',  // Blue
        '#FF00FF',  // Fuchsia
        '#800000',  // Maroon
        '#808000',  // Olive
        '#008000',  // Green
        '#008080',  // Teal
        '#000080',  // Navy
        '#800080',  // Purple
        '#FFA500',  // Orange
        '#000000',  // Black
        '#8B0000',  // Dark Red
        '#006400',  // Dark Green
        '#00008B',  // Dark Blue
        '#8B008B'   // Dark Magenta
    ]

    const Section = ({ section }) => (
        <>
            {
                data[selectedValue]?.[section]?.length > 0 &&
                <div style={{ marginTop: '50px' }}>
                    <div className="admin-switces-title">{section === 'co2management' ? 'Co2 Management' : section.charAt(0).toUpperCase() + section.slice(1)}</div>
                    <div className="admin-container-switches-btns">
                        <Row gutter={16}>
                            {
                                data[selectedValue]?.[section]?.sort((a, b) => a.position - b.position)?.map((item, idx) => (
                                    <Col span={8} style={{ marginTop: '16px' }}>
                                        <Button
                                            style={{
                                                border: `1px solid ${colors[idx]}`,
                                                color: `${colors[idx]}`,
                                                background: 'none'
                                            }}
                                            onClick={() => showModal(section, item?._id)}
                                        >
                                            {item?.title.length > 30 ? `${item?.title.slice(0, 30)}...` : item?.title}
                                        </Button>
                                    </Col>
                                ))
                            }
                        </Row>
                    </div>
                </div>
            }
        </>
    );

    return (
        <Layout active="dynamic-switches">
            <Modal
                open={visible}
                onCancel={handleCancel}
                footer={null}
            >
                <AdminModal subnodes={subnodes} Value={modalValue} setValue={handleOk}
                    positions={positions} data={data} id={index} selectedValue={selectedValue} section={section} />
            </Modal>

            <div className="admin-container-switches" >
                <Select defaultValue={0} style={{ width: 200 }} onChange={handleChange}>
                    {data?.map((item, idx) => (
                        <Option value={idx} key={idx}>{item?.clientId?.userName}</Option>
                    ))}
                </Select>

                {sections.map(section => <Section key={section} section={section} />)}
            </div>
        </Layout>
    );
};

export default AdminDynamicSwitches;

