import { notification } from "antd";
import { publicAPI } from "../../API";

export const getDynamics = async () => {
    try {
        const res = await publicAPI.get(`/dynamic/`);
        if (res) {
            return res?.data;
        }
    } catch (err) {
        console.log("err", err);
    }
};

export const updateDynamic = async (payload) => {
    try {
        const res = await publicAPI.put(`/dynamic/update`, payload);
        if (res) {
            return res?.data;
        }
    } catch (err) {
        console.log("err", err);
    }
};

export const getDynamicOfUser = async (payload) => {
    try {
        const res = await publicAPI.post(`/dynamic/user`, payload);
        if (res) {
            return res.data;
        }
    } catch (err) {
        console.log("err", err);

    }
};

export const setDynamic = async (payload) => {
    try {
        const res = await publicAPI.post(`/dynamic/create`, payload);
        if (res) {
            return res.data;
        }
    } catch (err) {
        console.log("err", err);

    }
};

export const deleteDynamic = async (payload) => {
    try {
        const res = await publicAPI.post(`/dynamic/delete`, payload);
        if (res) {
            return res.data;
        }
    } catch (err) {
        console.log("err", err);

    }
};

export const getAllSubnodes = async () => {
    try {
        const res = await publicAPI.get(`/subnodes/all`);
        if (res) {
            return res.data;
        }
    } catch (err) {
        console.log("err", err);

    }
}

export const updateSubNode = async (payload) => {
    try {
        const res = await publicAPI.put(`/subnodes/update`, payload);
        if (res) {
            return res.data;
        }
    } catch (err) {
        console.log("err", err);

    }
}

export const createSubNode = async (payload) => {
    try {
        const res = await publicAPI.post(`/subnodes/createSubNode`, payload);
        if (res) {
            notification.success({
                message: res.data?.message,
                duration: 3,
            });
        }
    } catch (err) {
        console.log("err", err);

    }
}

