import React, { useState, useEffect } from 'react';
import { DualAxes } from '@ant-design/plots';

const ForecastChart = () => {
  const uvBillData = [
    {
      time: '0',
      value: 50,
      type: 'uv',
    },
    {
      time: '3',
      value: 70,
      type: 'uv',
    },
    {
      time: '6',
      value: 30,
      type: 'uv',
    },
    {
      time: '9',
      value: 50,
      type: 'uv',
    },
    {
      time: '12',
      value: 70,
      type: 'uv',
    },
    {
      time: '0',
      value: 20,
      type: 'bill',
    },
    {
      time: '3',
      value: 30,
      type: 'bill',
    },
    {
      time: '6',
      value: 50,
      type: 'bill',
    },
    {
      time: '9',
      value: 20,
      type: 'bill',
    },
    {
      time: '12',
      value: 62,
      type: 'bill',
    },
  ];
  const transformData = [
    {
      time: '0',
      count: 80,
      name: 'a',
    },
    {
      time: '12',
      count: 60,
      name: 'a',
    },
    {
      time: '24',
      count: 40,
      name: 'a',
    },
    {
      time: '0',
      count: 80,
      name: 'a',
    },
    {
      time: '12',
      count: 20,
      name: 'a',
    },
    {
      time: '0',
      count: 70,
      name: 'b',
    },
    {
      time: '3',
      count: 50,
      name: 'b',
    },
    {
      time: '6',
      count: 40,
      name: 'b',
    },
    {
      time: '9',
      count: 40,
      name: 'b',
    },
    {
      time: '12',
      count: 30,
      name: 'b',
    },
    {
      time: '0',
      count: 80,
      name: 'c',
    },
    {
      time: '3',
      count: 60,
      name: 'c',
    },
    {
      time: '6',
      count: 40,
      name: 'c',
    },
    {
      time: '9',
      count: 30,
      name: 'c',
    },
    {
      time: '12',
      count: 20,
      name: 'c',
    },
  ];
  const config = {
    data: [uvBillData, transformData],
    xField: 'time',
    yField: ['value', 'count'],
    legend:false,
    height: 175,
    geometryOptions: [
      {
        geometry: 'line',
        seriesField: 'type',
        lineStyle: {
          lineWidth: 3,
          lineDash: [5, 5],
        },
        smooth: true,
      },
      {
        geometry: 'line',
        seriesField: 'name',
        point: {},
      },
    ],
  };
  return <DualAxes {...config} />;
};
export default ForecastChart