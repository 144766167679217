import React from 'react';
import { Button, Checkbox, Col, Form, Input, Progress, Row, Space, Switch, Tabs } from 'antd';
const { TabPane } = Tabs;
const onChange = (key) => {
  console.log(key);
};
// const items = [
//   {
//     key: '1',
//     label: `Charge Profile`,
//     children: [<div></div>],
//   },
//   {
//     key: '2',
//     label: `Discharge Profile`,
//     children: `Content of Tab Pane 2`,
//   }
// ];


const OperationProfileTabs = () => {
    const onChange = (checked) => {
        console.log(`switch to ${checked}`);
      };
    return (
      <div>
        <div
          className="flex"
          style={{
            fontSize: "10px",
            justifyContent: "space-between",
            alignContent: "center",
            paddingTop: "15px",
          }}
        >
          <p
            style={{ paddingRight: "2px", fontSize: "10px", fontWeight: "400" }}
          >
            Consumption Optimization
          </p>

          <Progress
            percent={99.9}
            strokeWidth={20}
            showInfo={false}
            strokeColor={{ "0%": "#0F5699", "100%": "#37A1DB" }}
            size={[300, 20]}
            style={{ width: "60%", paddingBottom: "10px" }}
          />

          <p
            style={{ paddingLeft: "2px", fontSize: "10px", fontWeight: "400" }}
          >
            Peak Shaving
          </p>
        </div>
        <Tabs defaultActiveKey="1">
          <TabPane tab="Charge Profile" key="1">
            {/* <div className="flex">
              <div style={{ width: "85%" }}>
                <Form>
                  <Row gutter={[10, 5]} style={{ paddingTop: "15px" }}>
                    <Col xs={24}>
                      <Checkbox
                        style={{
                          color: "#000000",
                          fontSize: "10px",
                        }}
                      >
                        Use Only Renewable Energy
                      </Checkbox>
                    </Col>
                  </Row>
                  <Row gutter={[10, 5]} style={{ paddingTop: "15px" }}>
                    <Col xs={24}>
                      <Form.Item name={"renewable"}>
                        <div className="flex-between">
                          <Checkbox
                            style={{
                              color: "#000000",
                              fontSize: "10px",
                            }}
                          >
                            Spot Price charge(start charge)
                          </Checkbox>
                          <Checkbox
                            style={{
                              color: "#000000",
                              fontSize: "10px",
                            }}
                          >
                            Minimal state of charge
                          </Checkbox>
                          <Checkbox
                            style={{
                              color: "#000000",
                              fontSize: "10px",
                            }}
                          >
                            Prefered charging time
                          </Checkbox>
                        </div>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={[10, 5]} style={{ paddingTop: "15px" }}>
                    <Col xs={24} lg={24}>
                      <div className="flex-between">
                        <Input
                          suffix="€/mWh"
                          placeholder="75"
                          style={{
                            width: "170px",
                            height: "24px",
                            fontSize: "10px",
                          }}
                        />
                        <Input
                          suffix={
                            <Switch
                              defaultUnChecked
                              onChange={onChange}
                              size="small"
                            />
                          }
                          placeholder="10"
                          style={{
                            width: "170px",
                            height: "24px",
                            fontSize: "10px",
                          }}
                        />
                        <div>
                          <Input
                            
                            placeholder="Select time"
                            style={{
                              width: "100px",
                              height: "24px",
                              fontSize: "10px",
                            }}
                          />
                          <Input
                            suffix="%"
                          
                            placeholder="Max"
                            style={{
                              width: "100px",
                              height: "24px",
                              fontSize: "10px",
                            }}
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </div>
              <div style={{ width: "15%" }}>
                <Button
                  type="primary"
                  style={{
                    height: "30px",
                    minWidth: "80px",
                    fontSize: "10px",
                    marginLeft: "25px",
                    marginTop: "55px",
                  }}
                >
                  Save
                </Button>
              </div>
            </div> */}
            <Checkbox
                  style={{
                    color: "#000000",
                    fontSize: "10px",
                    // paddingLeft: "8px"
                  }}
                >
                  Spot Price charge(start charge)
                </Checkbox>
            <div className="flex" style={{justifyContent: "space-between", paddingTop: "10px"}}>
              <div style={{textWrap:"nowrap"}}>
                <Checkbox
                  style={{
                    color: "#000000",
                    fontSize: "10px",
                  }}
                >
                  Spot Price charge(start charge)
                </Checkbox>
                <div>
                  <Input
                    suffix="€/mWh"
                    placeholder="75"
                    style={{
                      // width: "170px",
                      height: "24px",
                      fontSize: "10px",
                    }}
                  />
                </div>
              </div>
              <div style={{textWrap:"nowrap"}}>
                <Checkbox
                  style={{
                    color: "#000000",
                    fontSize: "10px",
                    
                  }}
                >
                  Minimal state of charge
                </Checkbox>
                <div>
                  <Input
                    suffix={
                      <Switch
                        defaultUnChecked
                        onChange={onChange}
                        size="small"
                      />
                    }
                    placeholder="10"
                    style={{
                      // width: "170px",
                      height: "24px",
                      fontSize: "10px",
                    }}
                  />
                </div>
              </div>
              <div>
                <Checkbox
                  style={{
                    color: "#000000",
                    fontSize: "10px",
                  }}
                >
                  Prefered charging time
                </Checkbox>
                <div className='flex'>
                  <Input
                    placeholder="Select time"
                    style={{
                      // width: "100px",
                      height: "24px",
                      fontSize: "10px",
                    }}
                  />
                  <Input
                    suffix="%"
                    placeholder="Max"
                    style={{
                      // width: "100px",
                      height: "24px",
                      fontSize: "10px",
                    }}
                  />
                </div>
              </div>
              <div>
                <Button
                  type="primary"
                  style={{ height: "30px", minWidth: "80px", fontSize: "10px" }}
                >
                  Save
                </Button>
              </div>
            </div>
          </TabPane>
          <TabPane tab="Discharge Profile" key="2">
            <div className="flex" style={{justifyContent: "space-between", paddingTop: "10px"}}>
              <div style={{textWrap:"nowrap"}}>
                <Checkbox
                  style={{
                    color: "#000000",
                    fontSize: "10px",
                  }}
                >
                  Spot Price charge(start charge)
                </Checkbox>
                <div>
                  <Input
                    suffix="€/mWh"
                    placeholder="75"
                    style={{
                      // width: "170px",
                      height: "24px",
                      fontSize: "10px",
                    }}
                  />
                </div>
              </div>
              <div style={{textWrap:"nowrap"}}>
                <Checkbox
                  style={{
                    color: "#000000",
                    fontSize: "10px",
                    
                  }}
                >
                  Minimal state of charge
                </Checkbox>
                <div>
                  <Input
                    suffix={
                      <Switch
                        defaultUnChecked
                        onChange={onChange}
                        size="small"
                      />
                    }
                    placeholder="10"
                    style={{
                      // width: "170px",
                      height: "24px",
                      fontSize: "10px",
                    }}
                  />
                </div>
              </div>
              <div>
                <Checkbox
                  style={{
                    color: "#000000",
                    fontSize: "10px",
                  }}
                >
                  Prefered charging time
                </Checkbox>
                <div className='flex'>
                  <Input
                    placeholder="Select time"
                    style={{
                      // width: "100px",
                      height: "24px",
                      fontSize: "10px",
                    }}
                  />
                  <Input
                    suffix="%"
                    placeholder="Max"
                    style={{
                      // width: "100px",
                      height: "24px",
                      fontSize: "10px",
                    }}
                  />
                </div>
              </div>
              <div>
                <Button
                  type="primary"
                  style={{ height: "30px", minWidth: "80px", fontSize: "10px" }}
                >
                  Save
                </Button>
              </div>
            </div>
          </TabPane>
        </Tabs>
      </div>
    );
};
// check
export default OperationProfileTabs;