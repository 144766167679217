import { ClockCircleFilled } from "@ant-design/icons";
const TooltipComp = ({ data, hollow, units, fields, withoutTitle, from }) => {

  return (
    <div className="tooltip-comp">
      <div className="tooltip-bottom" />
      {from === "country" && (
        <p
          style={{
            // fontWeight: "bold",
            marginBottom: "6px",
            fontSize: "15px",
            color: "black",
          }}
        >
          <>
            <ClockCircleFilled
              style={{
                // marginLeft: "10px",
                marginRight: "5px",
                fontSize: "12px",
              }}
            />
            {data?.[0]?.data?.interval}
          </>
        </p>
      )}
      {/* {data?.map((item, index) => (

        <div key={index}>
          <div className="tooltip-item">
            <div className="tooltip-left">
              {console.log("item ", item)}
              <div
                className="circle-dot"
                style={{ backgroundColor: `${item.color}` }}
              >
                {hollow && <div className="hollow-circle" />}
              </div>
              {!withoutTitle && <span className="head">{Object.keys(item.data)[1]}:</span>}
              {console.log("chk",!withoutTitle && Object.keys(item.data))}
            </div>
            <span className="span-value">
              {`${Number(Number(item.value)?.toFixed(2))?.toLocaleString("de-DE")} ${units[index]}`}
              {console.log("chk2",)}
            </span>
          </div>
        </div>
      ))} */}
      
      <div>
          <div className="tooltip-item">
            <div className="tooltip-left">
              {/* {console.log("item ", item)} */}
              <div
                className="circle-dot"
                style={{ backgroundColor: `${data[0].color}` }}
              >
                {hollow && <div className="hollow-circle" />}
              </div>
              {!withoutTitle && <span className="head">date:</span>}
              {/* {console.log("chk",!withoutTitle && Object.keys(item.data))} */}
            </div>
            <span className="span-value">
              {data[0].title}
              {console.log("chk2",)}
            </span>
          </div>
        </div>
        <div>
          <div className="tooltip-item">
            <div className="tooltip-left">
              {/* {console.log("item ", item)} */}
              <div
                className="circle-dot"
                style={{ backgroundColor: `${data[1].color}` }}
              >
                {hollow && <div className="hollow-circle" />}
              </div>
              {!withoutTitle && <span className="head">{data[1].name}</span>}
              {/* {console.log("chk",!withoutTitle && Object.keys(item.data))} */}
            </div>
            <span className="span-value">
            {`${Number(Number(data[1].value)?.toFixed(2))?.toLocaleString("de-DE")} ${units[1]}`}
              {console.log("chk2",)}
            </span>
          </div>
        </div>

    </div>
  );
};

export default TooltipComp;
