import { notification } from "antd";
import { publicAPI } from "../../API";

export const postLanguages = async (payload) => {
    try {
        const res = await publicAPI.post(`/language/set`, payload);
        if (res) {
            return res?.data;
        }
    } catch (err) {
        console.log("err", err);
    }
};

export const getLanguages = async () => {
    try {
        const res = await publicAPI.get(`/language/get`);
        if (res) {
            return res?.data;
        }
    } catch (err) {
        console.log("err", err);
    }
};

export const updateLanguage = async (payload) => {
    try {
        const res = await publicAPI.put(`/language/update`, payload);
        if (res) {
            return res?.data;
        }
    } catch (err) {
        console.log("err", err);
    }
};

export const createLanguage = async (payload) => {
    try {
        const res = await publicAPI.post(`/language/create`, payload);
        if (res) {
            notification.success({
                message: res.data?.message,
                duration: 3,
            });
        }
    } catch (err) {
        console.log("err", err);

    }
}