/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Typography } from "antd";
import { BsArrowDown, BsArrowUp } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import {
  getEnergyCost,
  getClient,
} from "../../redux";
import { useTranslation } from "react-i18next";

const EnergyCostCard = ({ header, height, unit, noWater, fromLive, data }) => {
  const { t } = useTranslation();
  const [Filter, setFilter] = useState({
    value: "Today",
    key: "today",
  });
  let size = 50;

  const circumference = 2 * Math.PI * (size / 2 - 5);

  const colors = useSelector((state) => state.userReducer.colors);

  const dispatch = useDispatch();
  const [energyCost, setEnergyCost] = useState(null);
  const [Data, setData] = useState(null);

  const getData = async () => {
    let result;
    if (data) {
      result = data;
    } else {
      result = await getEnergyCost({ filter: Filter.key });
    }
    let temp = JSON.parse(JSON.stringify(result));
    temp.channels = temp.channels?.filter((obj) => {
      return obj.type !== "water";
    });
    if (noWater) {
      setEnergyCost(temp);
    } else {
      setEnergyCost(result);
    }
  };

  const getClientData = async () => {
    const data = await dispatch(getClient());
    setData(data);
  };

  useEffect(() => {
    getClientData();
  }, []);

  useEffect(() => {
    getData();
  }, [Filter]);

  return (
    <div className="electric-cost-outer" style={{ position: "relative" }}>

      <div className="electric-cost">
        <div className="electric-cost-left">
          {header ? (
            header
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "1rem",
              }}
            >
              <Typography.Title className="title" level={5} style={{ fontWeight: "600", fontSize: "13px" }}>
                {t("total_energy_cost")}
              </Typography.Title>
            </div>
          )}
          <Typography.Title className="sub-title" level={4}>
            {energyCost?.totalCost
              ? Number(energyCost?.totalCost)?.toLocaleString("de-DE")
              : "0"}{" "}
            {unit ? unit : "€"}
          </Typography.Title>
          <div className="percentage-wrapper">
            <span className="percentage" style={{ color: "var(--red)" }}>
              {" "}
              {energyCost?.percentage
                ? energyCost?.percentage > 0
                  ? energyCost?.percentage?.toLocaleString("de-DE")
                  : String(energyCost?.percentage)
                    ?.substr(1)
                    ?.toLocaleString("de-DE")
                : "0"}
              %&nbsp;
              <BsArrowUp style={{ strokeWidth: 2 }} />
            </span>
            &nbsp;
            <span className="text">{t("than_last_day")}</span>
          </div>
        </div>
        <div className="electric-cost-right">
          {energyCost?.channels?.map((channel, ind) => (
            <div className="item" key={ind}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <svg width={size} height={size}>
                  <circle
                    cx={size / 2}
                    cy={size / 2}
                    r={size / 2 - 5}
                    stroke="#CCCCCC"
                    strokeWidth="5"
                    strokeDasharray={`${circumference} ${circumference}`}
                    transform={`rotate(-90 ${size / 2} ${size / 2})`}
                    style={{
                      strokeDashoffset: 0,
                    }}
                    fill="transparent"
                  />

                  <circle
                    cx={size / 2}
                    cy={size / 2}
                    r={size / 2 - 5}
                    stroke={colors?.[ind]}
                    strokeWidth="7"
                    strokeDasharray={`${circumference} ${circumference}`}
                    transform={`rotate(-90 ${size / 2} ${size / 2})`}
                    style={{
                      strokeDashoffset:
                        circumference -
                        ((
                          (channel?.price / energyCost?.totalCost) *
                          100
                        ).toFixed() /
                          100) *
                        circumference,
                    }}
                    fill="transparent"
                  />

                  <text
                    x={size / 2}
                    y={size / 2}
                    textAnchor="middle"
                    dominantBaseline="central"
                    stroke={colors?.[ind]}
                    strokeWidth="1"
                    style={{
                      fontSize: "12px",
                      fontWeight: "100",
                    }}
                  >
                    {((channel?.price / energyCost?.totalCost) * 100).toFixed()}
                    %
                  </text>
                </svg>
              </div>

              <p className="sub-title" style={{ marginBottom: 0 }}>
                {channel?.price?.toLocaleString("de-DE")} {unit ? unit : "€"}
              </p>
              <div style={{ display: "flex", alignItems: "center" }}>
                <span
                  style={{
                    fontSize: "0.75rem",
                    color: "var(--secondaryText)",
                    textTransform: "capitalize",
                  }}
                >
                  {channel?.type === "power" ? t("electricity") :
                    channel?.type === 'gas' ? t('gas') :
                      channel?.type === 'heat' ? t('heat') :
                        channel?.type === 'water' ? t('water') :
                          channel?.type === 'Power' ? t('power') :
                            channel?.type
                  }
                </span>
                &nbsp;
                <BsArrowDown
                  style={{ color: colors?.[ind], fontSize: ".60rem" }}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default EnergyCostCard;
