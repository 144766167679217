import { privateAPI, attachToken } from "../../API";

export const getStationsData = async () => {
  try {
    attachToken();
    const res = await privateAPI.get(`/emobility/get-charging-stations`);
    console.log("station data", res);

    if (res) {
      return res.data;
    }
  } catch (err) {
    console.log("err", err);

  }
};
export const getChargingHeatmap = async () => {
  try {
    attachToken();
    const res = await privateAPI.get(`/emobility/get-heatmap-data`);

    if (res) {
      return res.data;
    }
  } catch (err) {
    console.log("err", err);

  }
};
