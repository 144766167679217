import moment from "moment";
export const getDataArrray = (dataValues, colorss, switches, analysisByDates) => {
    let datasetArray = [];
    let length = 24;

    for (let i = 0; i < dataValues.length; i++) {
        let resultArray = [];
        let temp = [];

        if (analysisByDates === 2) {
            let prevValue = dataValues[i]?.values[0]?.value;
            let prevTime = moment(dataValues[i]?.values[0]?.time)
            for (let j = 1; j < dataValues[i]?.values.length; j++) {
                const currentValue = dataValues[i]?.values[j]?.value;
                const currentTime = moment(dataValues[i]?.values[j]?.time);
                const hoursDifference = currentTime.diff(prevTime, 'seconds') / 3600;
                const diff = (currentValue - prevValue) / hoursDifference;
                temp.push(diff);
                prevValue = currentValue;
                prevTime = currentTime;
            }
            for (let j = 0; j < 96; j++) {
                let sum = 0;
                for (let k = 0; k < 30; k++) {
                    sum += temp[j + k * 96];
                }
                resultArray.push(sum / 30);
            }

        } else if (analysisByDates === 1) {
            let prevValue = dataValues[i]?.values[0]?.value;
            let prevTime = moment(dataValues[i]?.values[0]?.time)
            for (let j = 1; j < dataValues[i]?.values.length; j++) {
                const currentValue = dataValues[i]?.values[j]?.value;
                const currentTime = moment(dataValues[i]?.values[j]?.time);
                const hoursDifference = currentTime.diff(prevTime, 'seconds') / 3600;
                const diff = (currentValue - prevValue) / hoursDifference;
                temp.push(diff);
                prevValue = currentValue;
                prevTime = currentTime;
            }

            for (let j = 0; j < 96; j++) {
                let sum = 0;
                for (let k = 0; k < 7; k++) {
                    sum += temp[j + k * 96];
                }
                resultArray.push(sum / 7);
            }

        } else {
            resultArray = [];
            let prevValue = dataValues[i]?.values[0]?.value;
            let prevTime = moment(dataValues[i]?.values[0]?.time)
            for (let j = 1; j < dataValues[i]?.values.length; j++) {
                const currentValue = dataValues[i]?.values[j]?.value;
                const currentTime = moment(dataValues[i]?.values[j]?.time);
                const hoursDifference = currentTime.diff(prevTime, 'seconds') / 3600;
                const diff = (currentValue - prevValue) / hoursDifference;
                resultArray.push(diff);
                prevValue = currentValue;
                prevTime = currentTime;
            }
        }

        datasetArray.push({
            label: dataValues[i]?.name,
            fill: false,
            backgroundColor: colorss[i].color,
            borderColor: colorss[i].color,
            data: resultArray,
            hidden: !switches[i]?.value
        })
    }

    return { datasetArray, length }
}