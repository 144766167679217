import { privateAPI, attachToken } from "../../API";
import { GET_ANALYSIS_DATA, SET_MEAN_NODES, SET_SUBNODES_VALUES_FROM_INFLUX } from "../types/generalTypes";

export const getAnalysisData = (payload) => {
  return async (dispatch) => {
    try {
      attachToken();
      const res = await privateAPI.post(`/analysis/get-analysis`, payload);

      if (res) {
        dispatch({
          type: GET_ANALYSIS_DATA,
          payload: res.data,
        });
        return res.data;
      }
    } catch (err) {
      console.log("err", err);
    }
  };
};

export const getNodesData = (payload) => {
  return async (dispatch) => {
    try {
      attachToken();
      const res = await privateAPI.post(`/analysis/get-measNodes`, payload);

      if (res) {
        dispatch({
          type: SET_MEAN_NODES,
          payload: res.data.measNodes,
        });
        return res.data;
      }
    } catch (err) {
      console.log("err", err);
    }
  };
};

export const getsubNodesValue = (payload) => {
  return async (dispatch) => {
    try {
      attachToken();
      const res = await privateAPI.post(`/influx/subnodes-data-from-influx`, payload);

      if (res) {
        dispatch({
          type: SET_SUBNODES_VALUES_FROM_INFLUX,
          payload: res.data.data,
        });
        return res.data;
      }
    } catch (err) {
      console.log("err", err);

    }
  };
};
