import { Layout } from "antd";
import MenuDrawer from "./MenuDrawer";

const { Content } = Layout;

const MobileLayout = ({ active, children }) => {

  return (
    <Layout className="m-layout">
      <div className="mobile-header">
        <MenuDrawer active={active} />
      </div>
      <Content className="m-children">{children}</Content>
    </Layout>
  );
};

export default MobileLayout;
