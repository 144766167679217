/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from "react";
import { Row, Col, Typography, Dropdown, Menu, Space, Spin } from "antd";
import { DownOutlined } from "@ant-design/icons";
import Layout from "../Layout/MainLayout";
import Column from "../Components/charts/Column";
import EnergyCostCard from "../Components/cards/EnergyCostCard";
import TotalElectricConsumption from "../Components/cards/TotalElectricConsumption";
import CUSTOMHM from "../Components/charts/CUSTOMHM";
import HorizontalBar from "../Components/charts/HorizontalBar";
import PvProductionModal from "../Components/modal/PvProductionModal";
import {
  getPowerQualtiy,
  getPvProduction,
  getPvProductionOnClick,
  createLog,
  setDisplay,
  getClient,
  getDynamicOfUser,
  clientLogin,
} from "../redux";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import ElectricCardMain from "../Components/cards/ElectricCardMain";
import { SET_DISPLAY_ANNOTATION, SET_DYNAMIC_UI } from "../redux/types/generalTypes";


const Home = () => {
  const heatmapRef = useRef(null);
  const dispatch = useDispatch();
  const restrictions = useSelector((state) => state.userReducer.restrictions);
  const [pvHeight, setPvHeight] = useState(150);
  const [pvProduction, setPvProduction] = useState(null);
  const [pvProductionConsumption, setPvProductionConsumption] = useState(null);
  const [powerQuality, setPowerQualtity] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [login, setLogin] = useState('');
  const dynamic = useSelector(state => state?.userReducer?.dynamicUI?.home)
  const history = useHistory();
  let user = localStorage.getItem("userId");
  let redirectswitch = localStorage.getItem("redirectswitch");

  useEffect(() => {
    logins()
  }, [])

  const logins = async () => {
    setLoading(true)
    const email = localStorage.getItem('enexpertUserEmail')
    if (email === null && window.location.href.indexOf('/public') > -1) {
      await dispatch(clientLogin({ email: 'gemeinden@en-expert.com', password: 'gemeinden' }, history, true));
      history.replace("/public")
    } else if (email === null && window.location.href.indexOf('/industry') > -1) {
      await dispatch(clientLogin({ email: 'demoaccount7@gmail.com', password: '12345678' }, history, true));
      history.replace("/industry")
    } else if (email === null && window.location.href.indexOf('/hotel') > -1) {
      await dispatch(clientLogin({ email: 'demoaccount4@gmail.com', password: '12345678' }, history, true));
      history.replace("/hotel")
    }
    setLoading(false)
    setLogin('Done')
  }

  useEffect(() => {
    i18n.on('languageChanged loaded', () => {
      setLoading(false);
    });

    return () => {
      i18n.off('languageChanged loaded');
    };
  }, [i18n, login]);

  const [heatmapFilter, setHeatmapFilter] = useState({
    value: t("last_day"),
    key: "24h",
  });

  const [pvFilter, setPvFilter] = useState({
    value: t("last_day"),
    key: "lastday",
  });

  const showModal = () => {
    setIsModalOpen(true);
    dispatch(setDisplay());
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    dispatch(setDisplay());
  };

  const getData = async () => {
    setLoading(true);
    const data = await dispatch(getClient());
    const dynamicUi = await getDynamicOfUser({ id: data?._id });
    i18n.changeLanguage(data.language);
    const pvResult = await getPvProduction({ filter: pvFilter.key });
    const res = await getPvProductionOnClick({ filter: pvFilter.key });



    setPvProduction(pvResult);
    setPvProductionConsumption(res);
    dispatch({
      type: SET_DYNAMIC_UI,
      payload: dynamicUi,
    });
  };

  const getPowerQualtiyData = async () => {
    const powerResult = await getPowerQualtiy();
    setPowerQualtity(powerResult);
  };

  useEffect(() => {
    getData();
    getPowerQualtiyData();

  }, [pvFilter, login]);

  useEffect(() => {
    setPvHeight(heatmapRef?.current?.offsetHeight);
    const timeout = setTimeout(() => {
      setPvHeight(heatmapRef?.current?.offsetHeight);
    }, 1000);
    return () => {
      clearTimeout(timeout);
    };
  }, [heatmapRef?.current?.offsetHeight, heatmapFilter]);

  const paths = [
    "/",
    "/live",
    "/energy-production",
    "/load-peak",
    "/emobility",
  ];

  const randomLoop = (index) => {
    if (index > paths.length - 1) {
      return index % paths.length;
    } else {
      return index;
    }
  };

  useEffect(() => {
    if (user === "634a977b42241167ecdf2b90" && redirectswitch === "true") {
      setInterval(() => {
        const currentPath = window.location.pathname;

        const newPath = paths.findIndex((obj) => {
          return obj === currentPath;
        });
        history.push(paths[randomLoop(newPath + 1)]);
        localStorage.setItem("redirectswitch", false);
      }, 15000);
    }
  }, [login]);

  const HeatmapComponent = () => {
    return (
      <div className="electric-consumption-outer" ref={heatmapRef}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "1.5rem",
          }}
          onMouseOver={(e) => {
            dispatch({ type: SET_DISPLAY_ANNOTATION, payload: false });
          }}
        >
          <Typography.Title
            className="title"
            level={5}
            style={{ fontSize: "16px", fontWeight: "600" }}
          >
            {t("heatmap")}
          </Typography.Title>
          <Dropdown
            overlay={
              <Menu
                onClick={(e) => {
                  setHeatmapFilter({
                    value: e.domEvent.target.innerText,
                    key: e.key,
                  });
                  createLog({
                    page: "home",
                    section: "electric consumption heatmap",
                    filter: e.key,
                    description:
                      "Change filter to " + e.domEvent.target.innerText,
                  });
                }}
              >
                <Menu.Item key={"24h"} value={t('last_day')}>
                  {t("last_day")}
                </Menu.Item>
                <Menu.Item key={"6d"} value={t('last_6_day')}>
                  {t("last_6_days")}
                </Menu.Item>
                <Menu.Item key={"24d"} value={t('last_24_day')}>
                  {t("last_24_days")}
                </Menu.Item>
              </Menu>
            }
            trigger={["click"]}
          >
            <div onClick={(e) => e.preventDefault()}>
              <Space className="electric-consumption-outer-dropdown">
                {t(heatmapFilter?.value)}
                <DownOutlined />
              </Space>
            </div>
          </Dropdown>
        </div>
        <div style={{ height: '230px', overflow: 'auto' }}>
          <CUSTOMHM
            filter={heatmapFilter}
          />
        </div>
      </div>
    )
  }

  const PVProductionComponent = () => {
    return (<div
      className="electric-consumption-outer"
      onMouseOver={(e) => {
        dispatch({ type: SET_DISPLAY_ANNOTATION, payload: false });
      }}
      style={{
        height: pvHeight,
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "1.5rem",
        }}
      >
        <PvProductionModal
          pvProduction={restrictions?.pvProduction}
          data={pvProductionConsumption}
          showModal={showModal}
          isModalOpen={isModalOpen}
          handleCancel={handleCancel}
          handleOk={handleOk}
          pvFilter={pvFilter}
        />
        {restrictions?.pvProduction && (
          <Dropdown
            overlay={
              <Menu
                onClick={(e) => {
                  setPvFilter({
                    value: e.domEvent.target.innerText,
                    key: e.key,
                  });
                  createLog({
                    page: "home",
                    section: "PV production",
                    filter: e.key,
                    description:
                      "Change filter to " + e.domEvent.target.innerText,
                  });
                }}
              >
                <Menu.Item key={"lastday"} value={t('last_day')}>
                  {t("last_day")}
                </Menu.Item>
                <Menu.Item key={"lastweek"} value={t("last_week")}>
                  {t("last_week")}
                </Menu.Item>
                <Menu.Item key={"lastmonth"} value={t("last_month")}>
                  {t("last_month")}
                </Menu.Item>
                <Menu.Item key={"lastyear"} value={t("last_year")}>
                  {t("last_year")}
                </Menu.Item>
              </Menu>
            }
            trigger={["click"]}
          >
            <div onClick={(e) => e.preventDefault()}>
              <Space className="electric-consumption-outer-dropdown">
                {t(pvFilter?.value)}
                <DownOutlined />
              </Space>
            </div>
          </Dropdown>
        )}
      </div>
      <div
        className="column-chart"
        style={{
          maxHeight: '230px',
        }}
        onClick={showModal}
      >
        {restrictions?.pvProduction ? (
          <Column
            Data={pvProduction ? pvProduction : []}
            label
            time={pvFilter?.value}
          />
        ) : (
          <HorizontalBar Data={powerQuality ? powerQuality : []} />
        )}
      </div>
    </div>
    )
  }

  const componentMap = {
    "Live Electric Consumption": ElectricCardMain,
    "Total Energy Cost": EnergyCostCard,
    "Total Electric Consumption": TotalElectricConsumption,
    "Electric Consumption Heatmap": HeatmapComponent,
    "PV Production": PVProductionComponent,
  };

  return (<>
    {
      loading ? <div className="spinner-loader"><Spin /></div> :
        <Layout active="dashboard">
          <div className="home-main">
            <Row gutter={[30, 30]}>
              {
                dynamic && dynamic?.sort((a, b) => a.position - b.position)?.map((obj, idx) => {
                  const Component = componentMap[obj.title];
                  return obj.switch && Component ? (
                    <Col sm={obj.width?.sm} md={obj.width?.md} lg={obj.width?.lg} style={{ width: "100%" }}>
                      <Component />
                    </Col>
                  ) : null;
                })
              }
            </Row>
          </div>
        </Layout>
    }
  </>
  );
};

export default Home;
