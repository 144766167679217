/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
import Layout from "../Layout/MainLayout";
import { Row, Typography, Col } from "antd";
import { getAnalysisData, createLog, getNodesData, getsubNodesValue } from "../redux";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import LineCharts from "../Components/charts/LineCharts";
import Switches from "../Components/Switches";
import searchIcon from "../Assets/search-icon.svg"
import carretDown from "../Assets/carretDown.svg"
import { useTranslation } from "react-i18next";

const LoadPeak = () => {
  const [Data, setData] = useState(null);
  const { t } = useTranslation();
  const [analysisByDates, setAnalysisByDates] = useState(0);
  const measNodes = useSelector((state) => state.userReducer.measNodes);
  const subNodesValuesFrom = useSelector((state) => state.analysis.SubNodesValuesFromInflux);
  const load = useSelector((state) => state.analysis.loading);
  const [interval, setIntervals] = useState(24);
  const [startDate, setStartDate] = useState(
    moment().subtract(4, "days").format("YYYY-MM-DD")
  );
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));
  const [switches, setSwitches] = useState([]);
  const [showDropdown, setShowDropDown] = useState(false);
  const [selectedDropdown, setSelectedDropdown] = useState('');
  const [measnodesIndex, setMeasnodesIndex] = useState(0);


  useEffect(() => {
    function createArrayOfObjects(num) {
      const array = [];
      for (let i = 1; i <= num; i++) {
        const obj = {};
        obj[`value`] = true;
        array.push(obj);
      }
      return array;
    }

    const numberOfObjects = measNodes[measnodesIndex]?.subNodes?.length || 1;
    const arrayOfObjects = createArrayOfObjects(numberOfObjects);
    setSwitches(arrayOfObjects);

  }, [measNodes, measnodesIndex])


  const SwitchesColor = [{ color: "#14FD00" }, { color: "#5100FD" }, { color: "#FD0000" }, { color: "#BB00FD" }, { color: "#000000" }, { color: "#F99600" }, { color: "#FF69B4" }, { color: "#1E90FF" }, { color: "#FFFF00" }, { color: "#8A2BE2" }, { color: "#DC143C" }, { color: "#FF8C00" }, { color: "#00FF00" }, { color: "#FF00FF" }, { color: "#00FFFF" }, { color: "#FF1493" }, { color: "#1E90FF" }, { color: "#FFD700" }, { color: "#8A2BE2" }, { color: "#DC143C" }, { color: "#FF8C00" }]


  const analysisData = useSelector((state) => state.analysis.analysis);

  const { electricity, water, heat, gas } = useSelector(
    (state) => state.analysis
  );

  useEffect(() => {
    getData();
    dispatch(getNodesData({ email: localStorage.getItem("enexpertUserEmail") }))
    dispatch(getsubNodesValue({ email: localStorage.getItem("enexpertUserEmail"), range: analysisByDates }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setLoading(false);
  }, [load])

  const analysisByDate = (value) => {
    console.log(value)
    setAnalysisByDates(value);
    setLoading(true);
    dispatch(getsubNodesValue({ email: localStorage.getItem("enexpertUserEmail"), range: value }))
  }

  useEffect(() => {
    if (analysisData) setAxisData(analysisData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [electricity]);

  const setAxisData = async (Data) => {
    let tempData = JSON.parse(JSON.stringify(Data?.axis));

    const index = tempData?.findIndex((data) => {
      return data.title === "Electricity";
    });
    const data = tempData?.find((data) => {
      return data.title === "Electricity";
    });

    let datasets = [];

    electricity.map((elec) => {
      let filtered = data.dataset.filter((obj) => {
        return obj.seriesname === elec;
      });
      datasets.push(...filtered);
    });

    tempData[index].dataset = datasets;
    setData({
      categories: Data?.categories,
      axis: tempData,
    });
  };

  const getData = async () => {

    const analysis = await dispatch(
      getAnalysisData({
        startDate,
        endDate,
        interval,
        aggregation: "mean",
      })
    );
    if (analysis) {
      setAxisData(analysis);
      createLog({
        page: "analysis",
        section: "multi axis chart",
        filter: interval,
        description: "get data by aggregation hours " + interval,
      });
    }
  };

  const switchHandler = (index) => {
    let temp = [...switches];
    if (temp[index] !== undefined) {
      temp[index].value = !switches[index]?.value;
    }
    setSwitches(temp);
  }

  return (
    <Layout active="analysis">
      <Typography.Title level={2}>{t('analysis')}</Typography.Title>
      <p>{t('select_to_compare_charts')}</p>
      <div className="analysis-search" onFocus={() => setShowDropDown(true)} onBlur={() => setShowDropDown(false)}>
        <img src={searchIcon} alt="" />
        <input type="text" value={selectedDropdown} onChange={(e) => setSelectedDropdown(e.target.value)} className="analysis-search-input" />
        <div>All <img src={carretDown} alt="" /></div>
        {showDropdown && <div className="analysis-search-dropDown" onFocus={() => setShowDropDown(true)} >
          {
            measNodes.map((item, idx) => (
              <div key={idx} onClick={(e) => { e.stopPropagation(); setSelectedDropdown(item?.name); setShowDropDown(false); setMeasnodesIndex(idx) }}>
                <div style={{ display: "flex", gap: "10px", alignItems: "center" }} >
                  <div className="colour-square" style={{ background: `${SwitchesColor[idx]?.color}` }}></div>
                  <div >{item?.name}</div>
                </div>
                {
                  item.subNodes.map((item2, idx2) => (
                    <div style={{ display: "flex", gap: "10px", alignItems: "center", marginLeft: "10px" }} key={idx2}>
                      <div className="colour-dot" style={{ background: `${SwitchesColor[idx2]?.color}` }}></div>
                      <div>{item2?.name}</div>
                    </div>
                  ))
                }
              </div>
            ))
          }
        </div>}

      </div>
      <div className="analysis-filters">
        <Row gutter={[30, 30]} justify="space-between" align="middle" >
          <Col xs={24} md={24} lg={24} xl={16} xxl={16}>
            <Row align="middle">
              {measNodes[measnodesIndex]?.subNodes?.map((item, idx) => (
                <Col gutter={[15, 15]} xs={8} md={4} lg={4} sx={{ marginTop: "5px" }}>
                  <Switches name={item.name} switches={switches[idx]?.value} color={SwitchesColor[idx].color} key={idx} index={idx} switchHandler={switchHandler} />
                </Col>
              ))}
            </Row>
          </Col>
          <Col xs={24} md={24} lg={24} xl={8} xxl={8} style={{ width: "fit-content" }}>
            <div className="analysis-by-dates-btn-group">
              <div className={`analysis-by-dates ${analysisByDates === 0 ? "analysis-by-dates-active" : ""} `} onClick={() => analysisByDate(0)}>{t('24_hours')}</div>
              <div className={`analysis-by-dates ${analysisByDates === 1 ? "analysis-by-dates-active" : ""} `} onClick={() => analysisByDate(1)}>{t('last_7_days')}</div>
              <div className={`analysis-by-dates ${analysisByDates === 2 ? "analysis-by-dates-active" : ""} `} onClick={() => analysisByDate(2)}>{t('last_30_days')}</div>
            </div>
          </Col>
        </Row>
      </div>
      <div className="multi-axis-chart">
        {
          loading ? <>{t('loading')}...</> : <LineCharts dataValues={subNodesValuesFrom} colorss={SwitchesColor} switches={switches} analysisByDates={analysisByDates} />
        }

      </div>
    </Layout>
  );
};

export default LoadPeak;
