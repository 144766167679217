/* eslint-disable no-unused-vars */
import Layout from "../Layout/MainLayout";
import { Typography, Progress, Table, Slider, Button, Select } from "antd";
import LoadPeakArea from "../Components/charts/LoadPeakArea";
import LoadPeakColumn from "../Components/charts/LoadPeakColumn";
import Loader from "../Components/Loader";
import moment from "moment";
import { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  getMaxLoad,
  getCurrentMonthLoad,
  getMonthlyLoad,
  getClient,
  updateLoadActions,
  createLog,
} from "../redux";
import { BsArrowDown, BsArrowUp } from "react-icons/bs";
import { useTranslation } from "react-i18next";

const LoadPeak = () => {
  const contributersRef = useRef(null);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [cardHeight, setCardheight] = useState(150);
  ;
  const [loadData, setLoadData] = useState(null);
  const [userData, setUserData] = useState(null);
  const [currentLoad, setCurrentLoad] = useState(null);
  const [monthlyLoad, setMonthlyLoad] = useState(null);

  const [actionLoading, setActionLoading] = useState(false);

  const [loadPeakActions, setLoadPeakActions] = useState([]);

  const colors = useSelector((state) => state.userReducer.colors);

  const onChange = (data, value, type) => {
    const index = loadPeakActions?.findIndex((obj) => {
      return obj.code === data.code;
    });
    if (index === -1) {
      setLoadPeakActions([
        ...loadPeakActions,
        type === "checkbox"
          ? {
            ...loadPeakActions[index],
            name: data.name,
            code: data.code,
            isChecked: value,
            percentage: 0,
          }
          : {
            ...loadPeakActions[index],
            name: data.name,
            code: data.code,
            percentage: value,
            isChecked: false,
          },
      ]);
    } else {
      loadPeakActions[index] =
        type === "checkbox"
          ? {
            ...loadPeakActions[index],
            name: data.name,
            code: data.code,
            isChecked: value,
          }
          : {
            ...loadPeakActions[index],
            name: data.name,
            code: data.code,
            percentage: value,
          };
      setLoadPeakActions([...loadPeakActions]);
    }
  };

  const updateAcions = async () => {
    setActionLoading(true);
    await updateLoadActions(loadPeakActions);
    const data = await dispatch(getClient());
    setUserData(data);
    setActionLoading(false);
  };

  const getData = async () => {
    const load = await getMaxLoad();
    const currentMonthLoad = await getCurrentMonthLoad();
    setLoadData(load);

    const data = await dispatch(getClient());
    setUserData(data);
    setLoadPeakActions(data.controlNodes?.length > 0 ? data.controlNodes : []);

    const nonthlyLoad = await getMonthlyLoad();
    setMonthlyLoad(nonthlyLoad);

    setCurrentLoad(currentMonthLoad);

  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setCardheight(contributersRef?.current?.offsetHeight);
    const timeout = setTimeout(() => {
      setCardheight(contributersRef?.current?.offsetHeight);
    }, 1000);
    clearTimeout(timeout);
  }, [contributersRef?.current?.offsetHeight]);

  return (
    <Layout active="load-peak">
      <div className="load-peak-main" style={{ marginBottom: "30px" }}>
        <div
          className="peak-card white-card max-load-peak-card"
          style={{
            height: cardHeight,
          }}
        >
          <Typography.Title
            className="title main-title"
            level={4}
            style={{ fontWeight: "600" }}
          >
            {t('load_peak')}
            ({moment().tz("Europe/Rome").format("MMMM YYYY")})
          </Typography.Title>
          <div className="inner-wrapper">
            <div className="load-sections">
              <Typography.Title level={4}>{t('autual')}</Typography.Title>

              <Typography.Title
                level={2}
                style={{ fontWeight: "600", margin: "0 auto" }}
              >
                {Number("120.45").toLocaleString("de-DE")}
                <br />
                kW
              </Typography.Title>
              <div className="percent" style={{ margin: "5px 0" }}>
                <span
                  style={{
                    color: "var(--green)",
                    fontWeight: "bold",
                    marginTop: "10px",
                  }}
                >
                  2,5 % <BsArrowDown style={{ strokeWidth: 2 }} />
                </span>
                &nbsp;
                <span className="secondary">{t('than_last_maximum')}</span>
              </div>
              <span
                className="secondary timestamp-wrapper"
              >
                {moment().tz("Europe/Rome").format("DD-MMM-YYYY - LT - ")}
                {moment().tz("Europe/Rome").add(15, "minute").format("LT")}
              </span>
            </div>

            <div className="load-sections">
              <Typography.Title level={4}>{t('max')}</Typography.Title>

              <Typography.Title
                level={2}
                style={{
                  fontWeight: "600",
                  margin: "0 auto",
                  background: "var(--secondary-color)",
                }}
              >
                {Number("240.24").toLocaleString("de-DE")}
                <br />
                kW
              </Typography.Title>

              <div className="percent" style={{ margin: "5px 0" }}>
                <span
                  style={{
                    color: "var(--secondary-color)",
                    fontWeight: "bold",
                  }}
                >
                  3,5 %<BsArrowUp style={{ strokeWidth: 2 }} />
                </span>
                &nbsp;
                <span className="secondary">{t('than_last_month')}</span>
              </div>
              <span
                className="secondary timestamp-wrapper"
              >
                {moment().tz("Europe/Rome").format("DD-MMM-YYYY - LT - ")}
                {moment().tz("Europe/Rome").add(15, "minute").format("LT")}
              </span>
            </div>
          </div>
        </div>

        <div
          ref={contributersRef}
          className="contributor-peak-card peak-card white-card"
        >
          <Typography.Title
            className="title main-title"
            level={4}
            style={{ fontWeight: "600" }}
          >
            {t('contributors_of_max_load_peak')}
          </Typography.Title>
          <div className="electric-consumption-right">
            {loadData?.contributors?.map((data, index) => (
              <div
                key={index}
                className="bar-chart-warpper"
                style={{ justifyContent: "space-around" }}
              >
                <p>{data?.name}</p>
                <Progress
                  strokeLinecap="square"
                  strokeColor={colors?.[index]}
                  percent={data.percentage?.toFixed()}
                  strokeWidth={6}
                />
              </div>
            ))}
          </div>
        </div>

        <div className="white-card peak-area contributor-peak-area">
          <div className="flex-between" style={{ marginBottom: "30px" }}>
            <Typography.Title
              className="title main-title"
              level={5}
              style={{ fontWeight: "600", marginBottom: "0px" }}
            >
              {t('total_grid_consumption')}
            </Typography.Title>
            <div>
              <Select
                style={{ width: 150, marginRight: "10px" }}
                defaultValue="-7d"
                onChange={async (value) => {
                  setCurrentLoad(null);
                  const currentMonthLoad = await getCurrentMonthLoad({
                    timescale: value,
                  });
                  setCurrentLoad(currentMonthLoad);
                  createLog({
                    page: "load peak",
                    section: "line chart timescale",
                    filter: value,
                    description: "get load peak for the last " + value,
                  });
                }}
              >
                <Select.Option value={"-7d"}>{t('last_week')}</Select.Option>
                <Select.Option value={"-30d"}>{t('last_month')}</Select.Option>
                <Select.Option value={"-365d"}>{t('last_year')}</Select.Option>
              </Select>

              <Select
                style={{ width: 150 }}
                defaultValue="15m"
                onChange={async (value) => {
                  setCurrentLoad(null);
                  const currentMonthLoad = await getCurrentMonthLoad({
                    filter: value,
                  });
                  setCurrentLoad(currentMonthLoad);
                  createLog({
                    page: "load peak",
                    section: "line chart interval",
                    filter: value,
                    description: "get load peak for interval " + value,
                  });
                }}
              >
                <Select.Option value={"5m"}>5 {t('minutes')}</Select.Option>
                <Select.Option value={"15m"}>15 {t('minutes')}</Select.Option>
                <Select.Option value={"30m"}>30 {t('minutes')}</Select.Option>
              </Select>
            </div>
          </div>
          {currentLoad ? (
            <div
              style={{
                width: "100%",
                height: "110px",
              }}
            >
              <LoadPeakArea Data={currentLoad ? currentLoad : []} />
            </div>
          ) : (
            <div className="loading" style={{ minHeight: 110 }}>
              <Loader />
            </div>
          )}
        </div>
        <div className="white-card load-peak-bar">
          <Typography.Title
            className="title main-title"
            level={4}
            style={{ fontWeight: "600", marginBottom: "20px" }}
          >
            {t('monthly_load_peak')}
          </Typography.Title>
          <div style={{ width: "100%", height: "200px" }}>
            <LoadPeakColumn />
          </div>
        </div>

        <div className="white-card load-actions">
          <Typography.Title
            className="title main-title"
            level={4}
            style={{ fontWeight: "600" }}
          >
            {t('load_peak_actions')}
          </Typography.Title>
          <Table
            size="small"
            columns={[
              {
                title: "Control Node",
                dataIndex: "code",
              },
              {
                title: "Channel Name",
                dataIndex: "name",
              },
              {
                title: "Percentage Range",
                dataIndex: "range",
              },
            ]}
            scroll={{ x: "auto" }}
            loading={userData?.controlNodes?.length > 0 ? false : true}
            dataSource={userData?.controlNodes?.map((data, ind) => ({
              key: ind,
              code: data.code,
              name: data.name,
              range: (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <span>0</span>
                  <Slider
                    defaultValue={data.percentage}
                    style={{ width: "100%" }}
                    onChange={(value) => {
                      onChange(data, value, "percentage");
                    }}
                  />
                  <span>100</span>
                </div>
              ),
            }))}
            pagination={false}
          />
          <div className="flex">
            <Button
              type="primary"
              loading={actionLoading}
              onClick={updateAcions}
            >
              {t('update_actions')}
            </Button>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default LoadPeak;
