import Layout from "../Layout/MainLayout";
import LiquidChart from "../Components/charts/LiquidChart";
import ChartjsSimpleDonut from "../Components/charts/ChartjsSimpleDonut";
import signalIcon from "../Assets/signal24_red.png";
import { Row, Select, Typography, Col, Button, DatePicker, Input, Tooltip } from "antd";
import {
  getAnalysisData,
  createLog,
  getNodesData,
  getsubNodesValue,
} from "../redux";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { GiCarBattery } from "react-icons/gi";
import { AiFillThunderbolt, AiFillCheckCircle } from "react-icons/ai";
import HorizontalBar from "../Components/charts/HorizontalBar";
import { InfoCircleOutlined } from "@ant-design/icons";
import CUSTOMHM from "../Components/charts/ChargingHeatmap";
import { getPvProductionLivePage } from "../redux";
import DemandProfileChart from "../Components/charts/DemandProfileChart";
import ForecastChart from "../Components/charts/ForecastChart";
import OperationProfileTabs from "../Components/OperationProfileTabs";

const BatteryPage = () => {
    let text = "This box represents the total conusmed energy by the client";
  const { t } = useTranslation();
  const liveConfig = useSelector((state) => state.userReducer.livePageConfig);
  const [batteryPercent, setBatteryPercent] = useState(35);
  const [batteryStatus, setBatteryStatus] = useState("Charging");
  const [batteryForward, setBatteryForward] = useState(true);
  let [pvProduction, setPvProduction] = useState(null);
  const [powerQuality, setPowerQualtity] = useState(null);

  const getData = async () => {
    const pvData = await getPvProductionLivePage();
    setPvProduction(pvData);
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <Layout active="battery">
      <div className="live-main energy-main battery-grid" style={{marginBottom: "30px"}}>
        <div className="white-card battery-level">
          <Typography.Title className="title main-title" level={5}>
            {t("battery_level")}
            <img src={signalIcon} />
          </Typography.Title>
          <div style={{ height: "100%" }}>
            {[
              {
                title: "Battery 1",
                percentage: 0.5,
                status: "Charging...",
              },
            ]?.map((level, index) => (
              <div style={{ height: "11rem" }} key={index}>
                <LiquidChart
                  percentage={batteryPercent / 100}
                  status={batteryStatus}
                  color={liveConfig?.batteryColor}
                />
                <div className="level-section">
                  <Typography.Title
                    level={5}
                    style={{
                      color: "#131523",
                      fontSize: "16px",
                      fontWeight: "600",
                    }}
                  >
                    {level?.title}
                  </Typography.Title>
                  <Typography.Text
                    type="secondary"
                    style={{
                      fontSize: "13px",
                      fontWeight: "600",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {batteryStatus === "Battery in Use" ? (
                      <GiCarBattery
                        style={{
                          marginRight: "5px",
                        }}
                      />
                    ) : (
                      <AiFillThunderbolt
                        size={14}
                        style={{
                          marginRight: "5px",
                        }}
                        color={"green"}
                      />
                    )}{" "}
                    {batteryStatus}
                  </Typography.Text>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div
          className="white-card pv-production"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            position: "relative",
            height: "100%",
          }}
        >
          <Typography.Title className="title main-title" level={5}>
            {t("pv_production")}
            <img src={signalIcon} />
          </Typography.Title>

          <div
            style={{
              height: "200px",
              width: "200px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: "0 auto",
              position: "relative",
            }}
          >
            <ChartjsSimpleDonut
              height="100%"
              Data={[
                pvProduction?.channels?.autoconsumption <= 0
                  ? 3
                  : pvProduction?.channels?.autoconsumption,
                pvProduction?.channels?.grid_feed <= 0
                  ? 3
                  : pvProduction?.channels?.grid_feed,
                pvProduction?.channels?.batteryCharging <= 0
                  ? 3
                  : pvProduction?.channels?.batteryCharging,
              ]}
              width="100%"
              colors={["#F99600", "#DD243B", "#0099FF"]}
            />
            {/* <p
              style={{
                position: "absolute",
                top: "-10%",
                left: "-28%",
                textAlign: "center",
                color: "#0099FF",
                fontSize: "0.75rem",
              }}
            >
              {pvProduction?.channels?.batteryCharging}%
              <br />
              {t('battery_charging')}
            </p> */}
            {/* <p
              style={{
                position: "absolute",
                bottom: "-20%",
                left: "50%",
                transform: "translate(-50%,-50%)",
                textAlign: "center",
                color: "#0099FF",
                fontSize: "0.75rem",
              }}
            >
              {pvProduction?.channels?.batteryCharging}%
              <br />
              {t("battery_charging")}
            </p> */}
            <p
              style={{
                position: "absolute",
                bottom: "-20%",
                left: "50%",
                transform: "translate(-50%,-50%)",
                textAlign: "center",
                color: "#DD243B",
                fontSize: "0.75rem",
              }}
            >
              {t('grid_feed')} {pvProduction?.channels?.grid_feed}%
            </p>
            <p
              style={{
                position: "absolute",
                top: "-10%",
                right: "-28%",
                textAlign: "center",
                color: "#F99600",
                fontSize: "0.75rem",
              }}
            >
              {pvProduction?.channels?.autoconsumption}%
              <br />
              {t("self_consumption")}
            </p>
            <p
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                textAlign: "center",
                width: "fit-content",
                transform: "translate(-50%,-50%)",
              }}
            >
              <span
                style={{
                  fontWeight: "600",
                  fontSize: "1.56rem",
                }}
              >
                {Number(pvProduction?.totalProduction)?.toLocaleString("de-DE")}
              </span>
              <br />
              <span
                style={{
                  color: "var(--secondaryText)",
                  fontSize: "0.87rem",
                }}
              >
                kW
              </span>
            </p>
            {/* <p
              style={{
                position: "absolute",
                bottom: "-20%",
                left: "50%",
                transform: "translate(-50%,-50%)",
                textAlign: "center",
                color: "#DD243B",
                fontSize: "0.75rem",
              }}
            >
              {t('grid_feed')} {pvProduction?.channels?.grid_feed}%
            </p> */}
            <p
              style={{
                position: "absolute",
                top: "-10%",
                left: "-28%",
                textAlign: "center",
                color: "#0099FF",
                fontSize: "0.75rem",
              }}
            >
              {pvProduction?.channels?.batteryCharging}%
              <br />
              {t('battery_charging')}
            </p>
            {/* <p
              style={{
                position: "absolute",
                top: "-10%",
                left: "-28%",
                textAlign: "center",
                color: "#DD243B",
                fontSize: "0.75rem",
              }}
            >
              {t("grid_feed")} {pvProduction?.channels?.grid_feed}%
            </p> */}
          </div>
        </div>
        <div
          className="white-card live-card water-consuption"
          style={{ padding: "5px", position: "relative" }}
        >
          <div style={{ padding: "20px 1rem", zIndex: "100" }}>
            <Typography.Title
              className="title main-title"
              level={5}
              style={{
                fontWeight: "600",
                // marginBottom: "20px",
                height: "10px",
              }}
            >
              <span
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "wrap",
                  fontSize: "16px",
                }}
              >
                Battery Analytics
                <Tooltip title={text} color={"#37A1DB"}>
                  <InfoCircleOutlined style={{ marginLeft: "5px" }} />
                </Tooltip>
              </span>
            </Typography.Title>
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-around",
              position: "absolute",
              top: "75%",
              left: "50%",
              transform: "translate(-50%, -50%)"
            }}
          >
            <div>
              <Typography.Title
                className="title main-title"
                level={5}
                style={{
                  fontWeight: "500",
                  // marginBottom: "20px",
                  fontSize:"16px",
                  height: "10px",
                }}
              >
                Number Cycles
              </Typography.Title>
              <p
                style={{
                  textAlign: "center",
                  fontSize: "1.75rem",
                  fontWeight: "600",
                  margin: 0,
                }}
              >
                245
              </p>
            </div>
            <div>
              <Typography.Title
                className="title main-title"
                level={5}
                style={{
                  fontWeight: "500",
                  // marginBottom: "20px",
                  fontSize:"16px",
                  height: "10px",
                }}
              >
                Service
              </Typography.Title>
              <p
                style={{
                  textAlign: "center",
                  fontSize: "1.75rem",
                  fontWeight: "600",
                  margin: 0,
                  color: "#2EC276",
                }}
              >
                OK <AiFillCheckCircle style={{ paddingTop: "6px" }} />
              </p>
            </div>
            <div>
              <Typography.Title
                className="title main-title"
                level={5}
                style={{
                  fontWeight: "500",
                  // marginBottom: "20px",
                  fontSize:"16px",
                  height: "10px",
                }}
              >
                State of Health
              </Typography.Title>
              <p
                style={{
                  textAlign: "center",
                  fontSize: "1.75rem",
                  fontWeight: "600",
                  margin: 0,
                }}
              >
                89%
              </p>
            </div>
          </div>
        </div>
        <div className="white-card demand-profile" style={{}}>
          <Typography.Title
            className="title main-title"
            level={5}
            style={{ fontWeight: "500", marginBottom: "10px" }}
          >
            <span style={{ display: "flex", alignItems: "center" }}>
              Demand Profile
              <Tooltip title={text} color={"#37A1DB"}>
                <InfoCircleOutlined style={{ marginLeft: "5px" }} />
              </Tooltip>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "3px",
                  marginLeft: "20px",
                }}
              >
                <div
                  style={{
                    background: "#0099FF",
                    width: 10,
                    height: 10,
                    borderRadius: "25%",
                    marginRight: 2,
                  }}
                />
                <span
                  style={{
                    fontSize: "10px",
                    fontWeight: 500,
                  }}
                >
                  Mon
                </span>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "3px",
                  marginLeft: "10px",
                }}
              >
                <div
                  style={{
                    background: "#F99600",
                    width: 10,
                    height: 10,
                    borderRadius: "25%",
                    marginRight: 2,
                  }}
                />
                <span
                  style={{
                    fontSize: "10px",
                    fontWeight: 500,
                  }}
                >
                  Tue
                </span>
              </div>
              {/*  */}
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "3px",
                  marginLeft: "10px",
                }}
              >
                <div
                  style={{
                    background: "#DD243B",
                    width: 10,
                    height: 10,
                    borderRadius: "25%",
                    marginRight: 2,
                  }}
                />
                <span
                  style={{
                    fontSize: "10px",
                    fontWeight: 500,
                  }}
                >
                  Wed
                </span>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "3px",
                  marginLeft: "10px",
                }}
              >
                <div
                  style={{
                    background: "#2EC276",
                    width: 10,
                    height: 10,
                    borderRadius: "25%",
                    marginRight: 2,
                  }}
                />
                <span
                  style={{
                    fontSize: "10px",
                    fontWeight: 500,
                  }}
                >
                  Thu
                </span>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "3px",
                  marginLeft: "10px",
                }}
              >
                <div
                  style={{
                    background: "#BB00FD",
                    width: 10,
                    height: 10,
                    borderRadius: "25%",
                    marginRight: 2,
                  }}
                />
                <span
                  style={{
                    fontSize: "10px",
                    fontWeight: 500,
                  }}
                >
                  Fri
                </span>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "3px",
                  marginLeft: "10px",
                }}
              >
                <div
                  style={{
                    background: "#0F5699",
                    width: 10,
                    height: 10,
                    borderRadius: "25%",
                    marginRight: 2,
                  }}
                />
                <span
                  style={{
                    fontSize: "10px",
                    fontWeight: 500,
                  }}
                >
                  Sat
                </span>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "3px",
                  marginLeft: "10px",
                }}
              >
                <div
                  style={{
                    background: "#BDFF00",
                    width: 10,
                    height: 10,
                    borderRadius: "25%",
                    marginRight: 2,
                  }}
                />
                <span
                  style={{
                    fontSize: "10px",
                    fontWeight: 500,
                  }}
                >
                  Sun
                </span>
              </div>
              {/*  */}
            </span>

            <Select defaultValue={"lastmonth"} style={{ width: "max-content" }}>
              <Select.Option value="thisweek">{t("this_week")}</Select.Option>
              <Select.Option value="thismonth">{t("this_month")}</Select.Option>
              <Select.Option value="thisyear">{t("this_year")}</Select.Option>
              <Select.Option value="lastweek">{t("last_week")}</Select.Option>
              <Select.Option value="lastmonth">30 Days</Select.Option>
              <Select.Option value="lastyear">{t("last_year")}</Select.Option>
            </Select>
          </Typography.Title>
          <DemandProfileChart />
        </div>
        <div className="battery-white-card forecast">
        <Typography.Title
            className="title main-title"
            level={5}
            style={{ fontWeight: "500", marginBottom: "10px" }}
          >
            <span style={{ display: "flex", alignItems: "center" }}>
              Forecast
              <Tooltip title={text} color={"#37A1DB"}>
                <InfoCircleOutlined style={{ marginLeft: "5px" }} />
              </Tooltip>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "3px",
                  marginLeft: "20px",
                }}
              >
                <div
                  style={{
                    background: "#0099FF",
                    width: 10,
                    height: 10,
                    borderRadius: "25%",
                    marginRight: 2,
                  }}
                />
                <span
                  style={{
                    fontSize: "10px",
                    fontWeight: 500,
                  }}
                >
                  Consumption
                </span>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "3px",
                  marginLeft: "10px",
                }}
              >
                <div
                  style={{
                    background: "#F99600",
                    width: 10,
                    height: 10,
                    borderRadius: "25%",
                    marginRight: 2,
                  }}
                />
                <span
                  style={{
                    fontSize: "10px",
                    fontWeight: 500,
                  }}
                >
                  PV
                </span>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "3px",
                  marginLeft: "10px",
                }}
              >
                <div
                  style={{
                    background: "#2EC276",
                    width: 10,
                    height: 10,
                    borderRadius: "25%",
                    marginRight: 2,
                  }}
                />
                <span
                  style={{
                    fontSize: "10px",
                    fontWeight: 500,
                  }}
                >
                  Battery
                </span>
              </div>
            </span>
          </Typography.Title>
          <div 
          // style={{height:"66%"}}
          style={{paddingTop: "20px"}}
          >
            <div style={{ display:"flex", justifyContent:"space-between" , paddingTop: "15px", paddingBottom:"30px"}}>
              <div style={{position:"relative", width:"10%"}}>
              <span style={{position: "absolute", top: "50%"}}>
                Intraday
              </span>
              </div>
              <div style={{width:"80%"}}>
           <ForecastChart className="forest-chart-top"  />
           </div>
           </div>
           <div style={{ display:"flex", justifyContent:"space-between", paddingTop: "15px"}}>
           <div style={{position:"relative", width:"10%"}}>
              <span style={{position: "absolute", top: "50%"}}>
                Day ahead
              </span>
              </div>
              <div style={{width:"80%"}}>
          <ForecastChart className="forest-chart-bottom"/>
          </div>
          </div>
          </div>          
        </div>
        <div className="battery-white-card operation-profile">
        <Typography.Title
            className="title main-title"
            level={5}
            style={{ fontWeight: "500", marginBottom: "10px" }}
          >
            <span style={{ display: "flex", alignItems: "center" }}>
              Operation Profile
              </span>
              </Typography.Title>
              <OperationProfileTabs />
        </div>
        <div className="white-card heat-map">
        <Typography.Title
            className="title main-title"
            level={5}
            style={{ fontWeight: "500", marginBottom: "10px" }}
          >
            <span style={{ display: "flex", alignItems: "center" }}>
              Heatmap
              <Tooltip title={text} color={"#37A1DB"}>
                <InfoCircleOutlined style={{ marginLeft: "5px" }} />
              </Tooltip>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "3px",
                  marginLeft: "20px",
                }}
              >
                <div
                  style={{
                    background: "#DD243B",
                    width: 10,
                    height: 10,
                    borderRadius: "25%",
                    marginRight: 2,
                  }}
                />
                <span
                  style={{
                    fontSize: "10px",
                    fontWeight: 500,
                  }}
                >
                  Discharge
                </span>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "3px",
                  marginLeft: "10px",
                }}
              >
                <div
                  style={{
                    background: "#2EC276",
                    width: 10,
                    height: 10,
                    borderRadius: "25%",
                    marginRight: 2,
                  }}
                />
                <span
                  style={{
                    fontSize: "10px",
                    fontWeight: 500,
                  }}
                >
                  Charge
                </span>
              </div>
              </span>
              <Select defaultValue={"lastweek"} style={{ width: "max-content",fontWeight: 400, }}>
              <Select.Option value="thisweek">{t("this_week")}</Select.Option>
              <Select.Option value="thismonth">{t("this_month")}</Select.Option>
              <Select.Option value="thisyear">{t("this_year")}</Select.Option>
              <Select.Option value="lastweek">last 7 days</Select.Option>
              <Select.Option value="lastmonth">30 Days</Select.Option>
              <Select.Option value="lastyear">{t("last_year")}</Select.Option>
            </Select>
              </Typography.Title>
              <CUSTOMHM />
        </div>
      </div>
    </Layout>
  );
};

export default BatteryPage;
