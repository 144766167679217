import React from 'react'

const Switches = ({ name, switches, color, switchHandler, index }) => {

    return (
        <div className="switches" >
            <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                <div className="colour-dot" style={{ background: `${color}` }}></div>
                <div>{name}</div>
            </div>
            <label className="switch" >
                <input type="checkbox" name={index} checked={switches} onClick={(e) => { switchHandler(index) }} />
                <span className="slider round " style={{
                    justifyContent: `${switches ? "flex-start" : "flex-end"}`
                }}>{switches ? "on" : "off"}</span>
            </label>
        </div>
    )
}

export default Switches