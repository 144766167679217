import React, { useEffect, useState } from 'react';
import { Form, Input, Button, Select, Row, Col } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { updateClient } from '../redux/actions/servicesApi';
import '../Styles/pages/servicesDashboard.css';
import { clientSignup, setDynamic, setSidebar } from '../redux';

const { Option } = Select;

const CustomForm = ({ data, handleClose, subnodes }) => {
    const [formData, setFormData] = useState({
        userName: '',
        email: '',
        password: '',
        buckets: '',
        language: '',
        postalCode: '',
        loadPeakPrice: '',
        maxPowerSupply: '',
        priceElectricDelivery: '',
        priceEnergyInput: '',
        priceGasDelivery: '',
        priceHeatDelivery: '',
        priceWaterDelivery: '',
        measNodes: [
            {
                name: '',
                code: '',
                Type: '',
                subNodes: [],
            },
        ],
    });

    useEffect(() => {
        if (data) {
            setFormData(data)
        }
    }, [data])

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSelectChange = (value, name, index) => {
        if (name === 'language') {
            setFormData({ ...formData, language: value });
        } else {
            const updatedMeasNodes = [...formData?.measNodes];
            updatedMeasNodes[index][name] = value;
            setFormData({ ...formData, measNodes: updatedMeasNodes });
        }
    };

    const addNewMeasNode = () => {
        setFormData((prevState) => ({
            ...prevState,
            measNodes: [
                ...prevState.measNodes,
                {
                    name: '',
                    code: '',
                    Type: '',
                    subNodes: [],
                },
            ],
        }));
    };

    const removeMeasNode = (index) => {
        const updatedMeasNodes = [...formData?.measNodes];
        updatedMeasNodes.splice(index, 1);
        setFormData({ ...formData, measNodes: updatedMeasNodes });
    };

    const handleSubmit = async () => {
        let temp = await clientSignup(formData);
        if (temp) {
            await setDynamic({ id: temp?._id });
            await setSidebar({ id: temp?._id });
        }
        handleClose()
    };

    const updateHandler = async () => {
        await updateClient(formData);
        handleClose()
    };

    return (
        <div className='custom-scrollBar' style={{ overflow: 'auto', maxHeight: '70vh', overflowX: 'none' }}>
            <Form layout="vertical" onFinish={handleSubmit}>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item label="Username">
                            <Input name="userName" value={formData?.userName} onChange={handleChange} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="Email">
                            <Input name="email" value={formData?.email} onChange={handleChange} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    {data ? <>

                    </> :
                        <Col span={12}>
                            <Form.Item label="Password">
                                <Input.Password name="password" value={formData?.password} onChange={handleChange} />
                            </Form.Item>
                        </Col>
                    }
                    <Col span={12}>
                        <Form.Item label="Postal Code">
                            <Input name="postalCode" value={formData?.postalCode} onChange={handleChange} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="Bucket">
                            <Input name="buckets" value={formData?.buckets} onChange={handleChange} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="Language" >
                            <Select onChange={(e) => handleSelectChange(e, 'language')} value={formData?.language}>
                                <Option value="en">English</Option>
                                <Option value="de">German</Option>
                                <Option value="it">Italian</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="Price Water Delivery">
                            <Input name="priceWaterDelivery" value={formData?.priceWaterDelivery} onChange={handleChange} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="Load Peak Price">
                            <Input name="loadPeakPrice" value={formData?.loadPeakPrice} onChange={handleChange} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="Max Power Supply">
                            <Input name="maxPowerSupply" value={formData?.maxPowerSupply} onChange={handleChange} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="Price Electric Delivery">
                            <Input name="priceElectricDelivery" value={formData?.priceElectricDelivery} onChange={handleChange} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="Price Energy Input">
                            <Input name="priceEnergyInput" value={formData?.priceEnergyInput} onChange={handleChange} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="Price Gas Delivery">
                            <Input name="priceGasDelivery" value={formData?.priceGasDelivery} onChange={handleChange} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="price Heat Delivery">
                            <Input name="priceHeatDelivery" value={formData?.priceHeatDelivery} onChange={handleChange} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    {formData?.measNodes?.map((node, index) => (
                        <div
                            key={index}
                            style={{
                                border: '1px solid #ccc',
                                borderRadius: '4px',
                                padding: '16px',
                                marginBottom: '16px',
                            }}
                        >
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <h4 style={{ marginBottom: '16px' }}>MeasNode {index + 1}</h4>
                                {index > 0 && (
                                    <CloseOutlined
                                        style={{ fontSize: '15px', cursor: 'pointer', color: 'grey' }}
                                        onClick={() => removeMeasNode(index)}
                                    />
                                )}
                            </div>
                            <Row gutter={16}>
                                <Col span={8}>
                                    <Form.Item label="Name">
                                        <Input
                                            value={node?.name}
                                            onChange={(e) =>
                                                handleSelectChange(e.target.value, 'name', index)
                                            }
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item label="Code">
                                        <Input
                                            value={node?.code}
                                            onChange={(e) =>
                                                handleSelectChange(e.target.value, 'code', index)
                                            }
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item label="Type">
                                        <Input
                                            value={node?.Type}
                                            onChange={(e) =>
                                                handleSelectChange(e.target.value, 'Type', index)
                                            }
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item label="SubNodes">
                                        <Select
                                            mode="multiple"
                                            allowClear
                                            style={{ width: '100%' }}
                                            placeholder="Please select"
                                            value={node.subNodes}
                                            options={subnodes}
                                            onChange={(value) => handleSelectChange(value, 'subNodes', index)}
                                        />
                                    </Form.Item>
                                </Col>

                            </Row>
                        </div>
                    ))}
                </Row>
                {formData?.measNodes[formData?.measNodes.length - 1]?.name !== '' &&
                    formData?.measNodes[formData?.measNodes.length - 1]?.code !== '' &&
                    formData?.measNodes[formData?.measNodes.length - 1]?.Type !== '' && (
                        <Form.Item>
                            <Button onClick={addNewMeasNode} type="dashed">
                                Add MeasNode
                            </Button>
                        </Form.Item>
                    )}

                {data ? <>
                    <Button type="primary" onClick={updateHandler}>
                        Update
                    </Button>
                </> :
                    <Button type="primary" onClick={handleSubmit}>
                        Save
                    </Button>
                }

            </Form>
        </div>
    );
};

export default CustomForm;

