import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "./Pages/Home";
import Login from "./Pages/Login";
import Signup from "./Pages/Signup";
import Profile from "./Pages/Profile";
import Digitalization from "./Pages/Digitalization";
import Analysis from "./Pages/Analysis";
import LoadPeakNew from "./Pages/LoadPeakNew";
import EnergyProductionNew from "./Pages/EnergyProductionNew";
import Live from "./Pages/Live";
import EmobilityNew from "./Pages/EmobilityNew";
import Compare from "./Pages/Compare";
import Co2Emission from "./Pages/CO2Emission";
import Battery from "./Pages/Battery";

import PrivateRoutes from "./PrivateRoutes";
import Service from "./Pages/Service";
import ServiceSignup from "./Pages/ServiceSignup";
import ServicesDashboard from "./Pages/ServicesDashboard";
import Admin from "./Pages/Admin/Admin";
import AdminDashboard from "./Pages/Admin/AdminDashboard";
import AdminRoute from "./AdminRoutes";
import AdminLanguage from "./Pages/Admin/AdminLanguageManager";
import AdminDynamicSwitches from "./Pages/Admin/AdminDynamicSwitches";
import AdminSubnodes from "./Pages/Admin/AdminSubnodes";
import AdminSidebar from "./Pages/Admin/AdminSidebar";

const Routes = () => {
  return (
    <Router>
      <Switch>
        <Route path="/public" exact component={Home} />
        <PrivateRoutes path="/dashboard" exact component={Home} />
        <Route path="/hotel" exact component={Home} />
        <Route path="/industry" exact component={Home} />
        <PrivateRoutes path="/services-dashboard" exact component={ServicesDashboard} />
        <AdminRoute path="/admin-dashboard" exact component={AdminDashboard} />
        <AdminRoute path="/admin-sidebar" exact component={AdminSidebar} />
        <AdminRoute path="/admin-language" exact component={AdminLanguage} />
        <AdminRoute path="/admin-switches" exact component={AdminDynamicSwitches} />
        <AdminRoute path="/admin-subnodes" exact component={AdminSubnodes} />
        <Route path="/" exact component={Login} />
        <Route path="/login" exact component={Login} />
        <Route path="/service" exact component={Service} />
        <Route path="/service-signup" exact component={ServiceSignup} />
        <Route path="/admin" exact component={Admin} />
        <Route path="/signup" exact component={Signup} />
        <PrivateRoutes path="/settings" exact component={Profile} />
        <PrivateRoutes
          path="/digitalization"
          exact
          component={Digitalization}
        />
        <PrivateRoutes path="/analysis" exact component={Analysis} />
        <PrivateRoutes path="/load-peak" exact component={LoadPeakNew} />
        <PrivateRoutes path="/live" exact component={Live} />
        <PrivateRoutes
          path="/energy-production"
          exact
          component={EnergyProductionNew}
        />
        <PrivateRoutes path="/emobility" exact component={EmobilityNew} />
        <PrivateRoutes path="/details" exact component={Compare} />
        <PrivateRoutes path="/co2emission" exact component={Co2Emission} />
        <PrivateRoutes path="/battery" exact component={Battery} />
      </Switch>
    </Router>
  );
};
export default Routes;
