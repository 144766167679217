import { LogoutOutlined } from "@ant-design/icons";
import { AiOutlineHome } from "react-icons/ai";
import { HiOutlineStatusOnline } from "react-icons/hi";
import { BiLineChart, BiWorld } from "react-icons/bi";
import { Menu } from "antd";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useEffect } from "react";

const MainMenu = ({ active }) => {
  const access = useSelector((state) => state.userReducer.restrictions);

  const history = useHistory();
  useEffect(() => {
  }, [active]);
  return (
    <div>
      <Menu
        mode="inline"
        defaultSelectedKeys={active}
        style={{
          height: "100vh",
          background: "var(--lightBackground)",
          padding: "10px 10px 10px 0",
        }}
      >
        <Menu.Item
          key="dashboard"
          icon={<AiOutlineHome className="icon-size-22" />}
          onClick={() => history.push("/service-dashboard")}
        >
          Dashboard
        </Menu.Item>
        {access?.live && (
          <Menu.Item
            key="live"
            icon={<HiOutlineStatusOnline className="icon-size-22" />}
            onClick={() => history.push("/client-crud")}
          >
            Client
          </Menu.Item>
        )}
        {access?.loadPeak && (
          <Menu.Item
            key="load-peak"
            icon={<BiLineChart className="icon-size-22" />}
          >
            <Link to={"/service-measnodes"}>MeasNodes</Link>
          </Menu.Item>
        )}
        {access?.digitalization && (
          <Menu.Item
            key="digitalization"
            icon={<BiWorld className="icon-size-22" />}
          >
            <Link to={"/service-subnodes"}>SubNodes</Link>
          </Menu.Item>
        )}
        <Menu.Item
          key="logout"
          icon={<LogoutOutlined className="icon-size-22" />}
        >
          <Link
            to="/"
            onClick={() => {
              localStorage.clear();
              // window.location.reload();
            }}
          >
            Logout
          </Link>
        </Menu.Item>
      </Menu>
    </div>
  );
};

export default MainMenu;
