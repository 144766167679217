import { privateAPI, attachToken } from "../../API";

export const getDigitalization = async () => {
  try {
    attachToken();
    const res = await privateAPI.get(`/digitalization/get-digitalization`);

    if (res) {
      return res.data;
    }

  } catch (err) {
    console.log("err", err);

  }
};

export const getHistogramData = async (paayload) => {
  try {
    attachToken();
    const res = await privateAPI.post(
      `/digitalization/get-histogram-data`,
      paayload
    );
    if (res) {
      return res.data;
    }
  } catch (err) {
    console.log("err", err);

  }
};

export const getDigiChartData = async (payload) => {
  try {
    attachToken();
    const res = await privateAPI.post(
      `/digitalization/get30DaysChannelData`,
      payload
    );
    if (res) {
      const res2 = await privateAPI.post(
        `/digitalization/get30DaysMeanData`,
        payload
      );
      return [...res.data, ...res2?.data];
    }
  } catch (err) {
    console.log("err", err);

  }
};
