/* eslint-disable jsx-a11y/alt-text */
import Layout from "../Layout/MainLayout";
import { Typography, Row, Col, Select, Tooltip } from "antd";
import TinyColumn from "../Components/charts/TinyColumn";
import CircleLine from "../Components/charts/CircleLine";
import ColumnLineDualAxis from "../Components/charts/ColumnLineDualAxis";
import { InfoCircleOutlined } from "@ant-design/icons";
import {
  getEnergyGraphData,
  getSelfConsumed,
  getAutonomyGrade,
  getCo2Savings,
  getEarnings,
  getSavings,
} from "../redux";
import { useEffect, useState } from "react";
import DemoTinyLine from "../Components/charts/TinyArea";
import earningIcon from "../Assets/earning.png";
import savingIcon from "../Assets/saving.png";
import Loader from "../Components/Loader";
import { t } from "i18next";

const LoadPeak = () => {
  let [energyGraph, setEnergyGraph] = useState(null);
  let [selfData, setSelfData] = useState(null);
  let [autonomyData, setAutonomyData] = useState(null);
  let [co2Data, setCo2Data] = useState(null);
  let [earningData, setEarningData] = useState(null);
  let [savingData, setSavingData] = useState(null);

  useEffect(() => {
    (async () => {
      const data = await getEnergyGraphData({ filter: t("lastweek") });
      const selfData = await getSelfConsumed({ filter: t("lastweek") });
      const autonomyData = await getAutonomyGrade({ filter: t("lastweek") });
      const co2Data = await getCo2Savings({ filter: t("lastweek") });
      const earnings = await getEarnings({ filter: t("lastweek") });
      const savings = await getSavings({ filter: t("lastweek") });
      setEnergyGraph(data);
      setSelfData(selfData);
      setAutonomyData(autonomyData);
      setCo2Data(co2Data);
      setEarningData(earnings);
      setSavingData(savings);
    })();
  }, []);

  let text = "This box represents the total conusmed energy by the client";

  return (
    <Layout active="energy-production">
      <div className="live-main energy-main" style={{ marginBottom: "30px" }}>
        <Row gutter={[30, 30]}>
          <Col xs={24} md={12} xl={8}>
            <div className="live-card white-card">
              <Typography.Title
                className="title main-title"
                level={5}
                style={{ fontWeight: "500", marginBottom: "20px" }}
              >
                <span
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexWrap: "wrap",
                    fontSize: "13px"
                  }}
                >
                  <span>{t('self_consumed')}</span>
                  <Tooltip title={text} color={"#37A1DB"}>
                    <InfoCircleOutlined
                      style={{ marginLeft: "5px" }}
                    />
                  </Tooltip>
                </span>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginLeft: 0,
                  }}
                >
                  <div
                    style={{
                      background: "#DD243B",
                      width: 10,
                      height: 10,
                      borderRadius: "50%",
                      marginRight: "5px",
                    }}
                  />
                  <span
                    style={{
                      fontSize: "0.75rem",
                      fontWeight: 600,
                      height: "16px",
                    }}
                  >
                    {selfData?.total?.toLocaleString("de-DE")}%
                  </span>
                </div>
                <Select
                  defaultValue="lastweek"
                  style={{ width: "max-content" }}
                  onChange={async (value) => {
                    const selfData = await getSelfConsumed({ filter: value });
                    setSelfData(selfData);
                  }}
                >
                  <Select.Option value='thisweek'>{t('this_week')}</Select.Option>
                  <Select.Option value='thismonth'>{t('this_month')}</Select.Option>
                  <Select.Option value='thisyear'>{t('this_year')}</Select.Option>
                  <Select.Option value='lastweek'>{t('last_week')}</Select.Option>
                  <Select.Option value='lastmonth'>{t('last_month')}</Select.Option>
                  <Select.Option value='lastyear'>{t('last_year')}</Select.Option>
                </Select>
              </Typography.Title>

              <div className="chart-wrapper">
                {selfData ? (
                  <DemoTinyLine
                    TooltipUnit="%"
                    unit={`%`}
                    Data={selfData ? selfData?.data : []}
                    fields={["time", "percentage"]}
                    color={{
                      hex: "#DD243B",
                      rgb: [221, 36, 59],
                    }}
                  />
                ) : (
                  <div
                    className="loading"
                    style={{ minHeight: "auto", height: "100%" }}
                  >
                    <Loader />
                  </div>
                )}
              </div>
            </div>
          </Col>

          <Col xs={24} md={12} xl={8}>
            <div className="live-card white-card">
              <Typography.Title
                className="title main-title"
                level={5}
                style={{ fontWeight: "500", marginBottom: "20px" }}
              >
                <span
                  style={{
                    display: "flex",
                    alignItems: "center",
                    fontSize: "13px"
                  }}
                >
                  <div>{t('autonomy_grade')}</div>

                  <Tooltip title={text} color={"#37A1DB"}>
                    <InfoCircleOutlined
                      style={{ marginLeft: "3px" }}
                    />
                  </Tooltip>
                </span>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      background: "#F99600",
                      width: 8,
                      height: 8,
                      borderRadius: "50%",
                      marginRight: "3px",
                    }}
                  />
                  <span
                    style={{
                      fontSize: "0.75rem",
                      fontWeight: 600,
                      height: "16px",
                    }}
                  >
                    {autonomyData?.total?.toLocaleString("de-DE")}%
                  </span>
                </div>
                <Select
                  defaultValue="lastweek"
                  style={{ width: "max-content" }}
                  onChange={async (value) => {
                    const autonomyData = await getAutonomyGrade({
                      filter: value,
                    });
                    setAutonomyData(autonomyData);
                  }}
                >
                  <Select.Option value='thisweek'>{t('this_week')}</Select.Option>
                  <Select.Option value='thismonth'>{t('this_month')}</Select.Option>
                  <Select.Option value='thisyear'>{t('this_year')}</Select.Option>
                  <Select.Option value='lastweek'>{t('last_week')}</Select.Option>
                  <Select.Option value='lastmonth'>{t('last_month')}</Select.Option>
                  <Select.Option value='lastyear'>{t('last_year')}</Select.Option>
                </Select>
              </Typography.Title>
              <div
                className="flex-between"
                style={{ justifyContent: "center" }}
              >
                <div
                  className="chart-wrapper"
                  style={{
                    width: "100%",
                  }}
                >
                  {autonomyData ? (
                    <DemoTinyLine
                      TooltipUnit="%"
                      Data={autonomyData ? autonomyData?.data : []}
                      fields={["time", "percentage"]}
                      color={{
                        hex: "#F99600",
                        rgb: [249, 150, 0],
                      }}
                      noArea
                    />
                  ) : (
                    <div
                      className="loading"
                      style={{ minHeight: "auto", height: "100%" }}
                    >
                      <Loader />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Col>

          <Col xs={24} md={12} xl={8}>
            <div className="live-card white-card">
              <Typography.Title
                className="title main-title"
                level={5}
                style={{ fontWeight: "500", marginBottom: "20px" }}
              >
                <span
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexWrap: "wrap",
                    fontSize: "13px"
                  }}
                >
                  <div>{t('co2_saving')}</div>
                  <Tooltip title={text} color={"#37A1DB"}>
                    <InfoCircleOutlined
                      style={{ marginLeft: "3px" }}
                    />
                  </Tooltip>
                </span>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      background: "#2EC276",
                      width: 10,
                      height: 10,
                      borderRadius: "50%",
                      marginRight: "5px",
                    }}
                  />
                  <span
                    style={{
                      fontSize: "0.75rem",
                      fontWeight: 600,
                      height: "16px",
                    }}
                  >
                    {co2Data?.total?.toLocaleString("de-DE")} kg
                  </span>
                </div>
                <Select
                  defaultValue='lastweek'
                  style={{ width: "max-content" }}
                  onChange={async (value) => {
                    const co2Data = await getCo2Savings({ filter: value });
                    setCo2Data(co2Data);
                  }}
                >
                  <Select.Option value='thisweek'>{t('this_week')}</Select.Option>
                  <Select.Option value='thismonth'>{t('this_month')}</Select.Option>
                  <Select.Option value='thisyear'>{t('this_year')}</Select.Option>
                  <Select.Option value='lastweek'>{t('last_week')}</Select.Option>
                  <Select.Option value='lastmonth'>{t('last_month')}</Select.Option>
                  <Select.Option value='lastyear'>{t('last_year')}</Select.Option>
                </Select>
              </Typography.Title>
              <div
                className="flex-between"
                style={{ justifyContent: "center" }}
              >
                <div
                  style={{
                    width: "100%",
                  }}
                  className="chart-wrapper"
                >
                  {co2Data ? (
                    <TinyColumn
                      Data={co2Data ? co2Data?.data : []}
                      fields={["time", "value"]}
                      alias={"kg"}
                      unit={"kg"}
                      color={"#2EC276"}
                      radius={[5, 5, 0, 0]}
                    />
                  ) : (
                    <div
                      className="loading"
                      style={{ minHeight: "auto", height: "100%" }}
                    >
                      <Loader />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Col>

          <Col xs={24}>
            <div className="white-card live-big-card">
              <Typography.Title
                className="title main-title"
                level={4}
                style={{ fontWeight: "600", marginBottom: "30px" }}
              >
                <span style={{ display: "flex", alignItems: "center" }}>
                  {t('energy_production_graph')}
                  <Tooltip title={text} placement="right" color={"#37A1DB"}>
                    <InfoCircleOutlined style={{ marginLeft: "5px" }} />
                  </Tooltip>
                </span>

                <Select
                  defaultValue='lastweek'
                  style={{ width: "max-content" }}
                >
                  <Select.Option value='thisweek'>{t('this_week')}</Select.Option>
                  <Select.Option value='thismonth'>{t('this_month')}</Select.Option>
                  <Select.Option value='thisyear'>{t('this_year')}</Select.Option>
                  <Select.Option value='lastweek'>{t('last_week')}</Select.Option>
                  <Select.Option value='lastmonth'>{t('last_month')}</Select.Option>
                  <Select.Option value='lastyear'>{t('last_year')}</Select.Option>
                </Select>
              </Typography.Title>
              <div
                style={{
                  width: "100%",
                }}
              >
                {energyGraph ? (
                  <ColumnLineDualAxis
                    leftUnit="kWh"
                    rightUnit="kWh"
                    isStack
                    Data={
                      energyGraph
                        ? energyGraph
                        : {
                          lineData: [],
                          barData: [],
                        }
                    }
                  />
                ) : (
                  <div
                    className="loading"
                    style={{ minHeight: "auto", height: "100%" }}
                  >
                    <Loader />
                  </div>
                )}
              </div>
            </div>
          </Col>

          <Col xs={24} lg={12}>
            <div className="white-card tiny-column-card">
              <Typography.Title
                className="title main-title"
                level={5}
                style={{ fontWeight: "500", marginBottom: "10px" }}
              >
                <span style={{ display: "flex", alignItems: "center" }}>
                  {t('earnings')}
                  <Tooltip title={text} color={"#37A1DB"}>
                    <InfoCircleOutlined style={{ marginLeft: "5px" }} />
                  </Tooltip>
                </span>
                <div
                  className="flex-between"
                  style={{ alignItems: "flex-start", margin: "10px" }}
                >
                  <Typography.Text
                    style={{
                      fontWeight: "600",
                      color: "#121212",
                      fontSize: "1rem",
                      display: "flex",
                      alignItems: "center",
                      margin: "0",
                      height: "1.2rem",
                    }}
                  >
                    <img src={earningIcon} style={{ marginRight: 5 }} />
                    {earningData?.total?.toLocaleString("de-DE")} €
                  </Typography.Text>
                </div>
                <Select
                  defaultValue='lastweek'
                  style={{ width: "max-content" }}
                >
                  <Select.Option value='thisweek'>{t('this_week')}</Select.Option>
                  <Select.Option value='thismonth'>{t('this_month')}</Select.Option>
                  <Select.Option value='thisyear'>{t('this_year')}</Select.Option>
                  <Select.Option value='lastweek'>{t('last_week')}</Select.Option>
                  <Select.Option value='lastmonth'>{t('last_month')}</Select.Option>
                  <Select.Option value='lastyear'>{t('last_year')}</Select.Option>
                </Select>
              </Typography.Title>

              <div className="tinycolumn">
                {earningData ? (
                  <CircleLine
                    TooltipUnit={"€"}
                    Data={earningData ? earningData?.data : []}
                  />
                ) : (
                  <div
                    className="loading"
                    style={{ minHeight: "auto", height: "100%" }}
                  >
                    <Loader />
                  </div>
                )}
              </div>
            </div>
          </Col>

          <Col xs={24} lg={12}>
            <div className="white-card tiny-column-card">
              <Typography.Title
                className="title main-title"
                level={5}
                style={{ fontWeight: "500", marginBottom: "10px" }}
              >
                <span style={{ display: "flex", alignItems: "center" }}>
                  {t('savings')}
                  <Tooltip title={text} color={"#37A1DB"}>
                    <InfoCircleOutlined style={{ marginLeft: "5px" }} />
                  </Tooltip>
                </span>

                <div
                  className="flex-between"
                  style={{ alignItems: "flex-start", margin: "10px" }}
                >
                  <Typography.Text
                    style={{
                      fontWeight: "600",
                      color: "#121212",
                      fontSize: "1rem",
                      display: "flex",
                      alignItems: "center",
                      margin: "0",
                      height: "1.2rem",
                    }}
                  >
                    <img src={savingIcon} style={{ marginRight: 5 }} />
                    {savingData?.total?.toLocaleString("de-DE")} €
                  </Typography.Text>
                </div>

                <Select
                  defaultValue='lastweek'
                  style={{ width: "max-content" }}
                >
                  <Select.Option value='thisweek'>{t('this_week')}</Select.Option>
                  <Select.Option value='thismonth'>{t('this_month')}</Select.Option>
                  <Select.Option value='thisyear'>{t('this_year')}</Select.Option>
                  <Select.Option value='lastweek'>{t('last_week')}</Select.Option>
                  <Select.Option value='lastmonth'>{t('last_month')}</Select.Option>
                  <Select.Option value='lastyear'>{t('last_year')}</Select.Option>
                </Select>
              </Typography.Title>
              <div className="tinycolumn">
                {savingData ? (
                  <CircleLine
                    TooltipUnit={"€"}
                    Data={savingData ? savingData.data : []}
                    color={"#DD243B"}
                  />
                ) : (
                  <div
                    className="loading"
                    style={{ minHeight: "auto", height: "100%" }}
                  >
                    <Loader />
                  </div>
                )}
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </Layout>
  );
};

export default LoadPeak;
