import { notification } from "antd";
import { privateAPI, attachToken, publicAPI } from "../../API";

export const getAllClients = async (payload) => {
    try {
        attachToken();
        const res = await privateAPI.get(`/client//get-all`);
        if (res) {

            return res?.data;
        }
    } catch (err) {
        console.log("err", err);
    }
};

export const createClient = async (payload, history) => {
    try {
        const res = await publicAPI.post(`/client/create`, payload);
        if (res) {
            notification.success({
                message: res.data.message,
                duration: 3,
            });
        }
    } catch (err) {
        notification.error({
            message: err?.response?.data?.message || "Server Error",
            duration: 3,
        });
    }
};

export const deleteClient = async (payload) => {
    try {
        const res = await publicAPI.post(`/client/delete`, payload);
        if (res) {
            notification.success({
                message: res.data.message,
                duration: 3,
            });
        }
    } catch (err) {
        notification.error({
            message: err?.response?.data?.message || "Server Error",
            duration: 3,
        });
    }
};

export const updateClient = async (payload, history) => {
    try {
        const res = await publicAPI.put(`/client/update`, payload);
        if (res) {
            notification.success({
                message: res.data.message,
                duration: 3,
            });
        }
    } catch (err) {
        notification.error({
            message: err?.response?.data?.message || "Server Error",
            duration: 3,
        });
    }
};
