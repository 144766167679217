import { Typography, Row, Col } from "antd";
import React, { useEffect, useState } from "react";
import { getDataChannels } from "../../redux";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../Loader";
import LineChartsForEC from "../charts/LineChartsForEC";
import Switches from "../Switches";
import { SET_DATA_CHANNELS } from "../../redux/types/homeTypes";

const LiveElectricConsumptionModal = ({ name, code, trigger, index }) => {
  const dataChannels = useSelector((state) => state.homeReducer.dataChannels);
  const [switches, setSwitches] = useState([]);
  const dispatch = useDispatch();
  const [analysisByDates, setAnalysisByDates] = useState(0);

  const SwitchesColor = [
    {
      color: "#14FD00"
    },
    {
      color: "#5100FD"
    },
    {
      color: "#FD0000"
    },
    {
      color: "#BB00FD"
    },
    {
      color: "#000000"
    },
    {
      color: "#F99600"
    },
    {
      color: "#0B1C30"
    },
    {
      color: "#2B2B2B"
    },
    {
      color: "#383838"
    },
    {
      color: "#454545"
    },
    {
      color: "#545454"
    },
    {
      color: "#626262"
    },
    {
      color: "#717171"
    },
    {
      color: "#7F7F7F"
    },
    {
      color: "#8D8D8D"
    },
    {
      color: "#9C9C9C"
    },
    {
      color: "#AAAAAA"
    },
    {
      color: "#B8B8B8"
    },
    {
      color: "#C6C6C6"
    },
    {
      color: "#D5D5D5"
    },
    {
      color: "#E3E3E3"
    }
  ];


  const switchHandler = (index) => {
    let temp = [...switches];
    if (temp[index] !== undefined) {
      temp[index].value = !switches[index]?.value;
    }
    setSwitches(temp);
  }

  useEffect(() => {
    function createArrayOfObjects(num) {
      const array = [];
      for (let i = 1; i <= num; i++) {
        const obj = {};
        obj[`value`] = true;
        array.push(obj);
      }
      return array;
    }
    const numberOfObjects = dataChannels?.length || 1;
    const arrayOfObjects = createArrayOfObjects(numberOfObjects);
    setSwitches(arrayOfObjects);
  }, [dataChannels])

  const analysisByDate = (value) => {
    setAnalysisByDates(value);
    dispatch({
      type: SET_DATA_CHANNELS,
      payload: [],
    });
    dispatch(getDataChannels({
      email: localStorage.getItem("enexpertUserEmail"),
      code: code,
      range: value
    }))
  }
  return (
    <div className="LECM-container">
      <div className="head">
        <div className="flex">
          <div className="blue" />
          <Typography.Title
            className="title"
            level={5}
            style={{ fontSize: "18px", fontWeight: 600 }}
          >
            {name} Live Electric Consumption
          </Typography.Title>
        </div>
      </div>
      <div className="analysis-filters">
        <Row gutter={[30, 30]} justify="space-between" align="middle" >
          <Col xs={24} md={24} lg={24} xl={16} xxl={16}>
            <Row align="middle">
              {dataChannels?.map((item, idx) => (
                <div style={{ marginTop: "5px", display: "flex", justifyContent: "center" }}>
                  <Switches name={item.name} switches={switches[idx]?.value} color={SwitchesColor[idx].color} key={idx} index={idx} switchHandler={switchHandler} />
                </div>
              ))}
            </Row>
          </Col>
          <Col xs={24} md={24} lg={24} xl={8} xxl={8} style={{ width: "fit-content" }}>
            <div className="analysis-by-dates-btn-group">
              <div className={`analysis-by-dates ${analysisByDates === 0 ? "analysis-by-dates-active" : ""} `} onClick={() => analysisByDate(0)}>24 hours</div>
              <div className={`analysis-by-dates ${analysisByDates === 1 ? "analysis-by-dates-active" : ""} `} onClick={() => analysisByDate(1)}>Last 7 Days</div>
              <div className={`analysis-by-dates ${analysisByDates === 2 ? "analysis-by-dates-active" : ""} `} onClick={() => analysisByDate(2)}>Last 30 days</div>
            </div>
          </Col>
        </Row>
      </div>
      <div className="multi-axis-chart">
        {dataChannels?.length > 0 ? (
          <>
            <LineChartsForEC dataValues={dataChannels} colorss={SwitchesColor} switches={switches} analysisByDates={analysisByDates} />
          </>
        ) : (
          <div
            style={{
              height: "400px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Loader />
          </div>
        )
        }
      </div>
    </div>
  );
};

export default LiveElectricConsumptionModal;
