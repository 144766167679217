import React from "react";
import { Area } from "@ant-design/plots";

const LoadPeakArea = ({ Data }) => {
  let color = {
    rgb: [51, 163, 219],
  };
  const config = {
    data: Data,
    padding: "auto",
    xField: "Date",
    smooth: true,
    yField: "scales",
    color: "#33A3DB",
    areaStyle: (value) => {
      return {
        fill: `l(270) 0:rgba(${color.rgb[0]},${color.rgb[1]},${color.rgb[2]},0.7) 0.5:rgba(${color.rgb[0]},${color.rgb[1]},${color.rgb[2]},0.8) 1:rgb(${color.rgb[0]},${color.rgb[1]},${color.rgb[2]})`,
      };
    },
    tooltip: {
      offset: 50,
      reversed: true,
      showCrosshairs: false,
      domStyles: {
        "g2-tooltip": {
          background: "white",
          // background: "#131523",
          color: "black",
          opacity: 1,
          borderRadius: "8px",
        },
      },
      customContent: (title, data) => {
        console.log("data ", data);
        return (
          <div className="tooltip-comp">
            <div className="tooltip-bottom" />
            <p
              style={{
                // fontWeight: "bold",
                marginBottom: "10px",
                fontSize: "15px",
                color: "black",
              }}
            >
              {data?.[0]?.title}
            </p>
            {data?.map((item, index) => (
              <div className="tooltip-item" key={index}>
                <div className="tooltip-left">
                  {/* {console.log("item ", item)} */}
                  <div
                    className="circle-dot"
                    style={{ backgroundColor: `${item.color}` }}
                  >
                    {/* {hollow && <div className="hollow-circle" />} */}
                  </div>
                  <span className="head">{item?.name}:</span>
                </div>
                <span className="span-value">{`${Number(
                  Number(item.value)?.toFixed(2)
                )?.toLocaleString("de-DE")}`}</span>
              </div>
            ))}
          </div>
        );
      },
    },
    xAxis: {
      line: null,
      grid: {
        line: {
          style: {
            stroke: "black",
            lineWidth: 0,
          },
        },
        alignTick: true,
        closed: true,
      },
    },
    yAxis: {
      grid: {
        line: {
          style: {
            stroke: "black",
            lineWidth: 0,
          },
        },
        alignTick: true,
        closed: true,
      },
    },

    meta: {
      scales: {
        alias: "Load (kW)",
      },
    },
    annotations: [
      {
        type: "regionFilter",
        start: ["min", "100"],
        end: ["max", "0"],
        color: "#37A1DB", //below threshold
        //
      },

      {
        type: "text",
        position: ["min", "100"],
        content: "Max Power Supply (kW)",
        offsetY: -4,
        style: {
          textBaseline: "bottom",
          fontSize: 12, //size of threshold text
        },
      },

      // {
      //   type: "text",
      //   position: ["min", "max"],
      //   content: "max load peak",
      //   offsetY: -4,
      //   style: {
      //     textBaseline: "bottom",
      //     fontSize: 12, //size of threshold text
      //   },
      // },

      {
        type: "line",
        start: ["min", "100"],
        end: ["max", "100"],
        style: {
          stroke: "#ff0000",
          lineDash: 0,
          lineWidth: 2,
        },
      },
      // {
      //   type: "line",
      //   start: ["min", "max"],
      //   end: ["max", "max"],
      //   style: {
      //     stroke: "#111221",
      //     lineDash: 0,
      //     lineWidth: 2,
      //   },
      // },
    ],
  };

  return <Area {...config} />;
};

export default LoadPeakArea;
