import React from "react";
import { Route, Redirect } from "react-router-dom";

const AdminRoute = ({ component: Component, ...rest }) => {
  var id = sessionStorage.getItem("adminToken");

  return (
    <Route
      {...rest}
      render={(props) =>
        id === 'qwertyuiopmnbvcxzasdfghjkl' ? (
          <Component {...props} />
        ) : (
          <Redirect to="/admin" />
        )
      }
    />
  );
};

export default AdminRoute;
