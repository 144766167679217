import { Row, Col, Tooltip } from "antd";
import ChartjsDonut from "./charts/ChartjsDonut";
import { useSelector } from "react-redux";
import HoverCard from "./cards/HoverCard";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

const ElectricConsumptionRoundChart = ({ electricConsumption, filter }) => {
  const colors = useSelector((state) => state.userReducer.colors);
  const [trigger, settrigger] = useState(false);
  const { t } = useTranslation();

  const total = electricConsumption?.data.reduce((accumulator, currentValue) => {
    return accumulator + currentValue;
  }, 0);


  return (
    <>
      <div
        style={{
          position: "relative",
        }}
      >
        <div
          style={{
            height: "250px",
            width: "250px",
            display: "flex",
            margin: "0 auto",
          }}
        >
          <ChartjsDonut
            Data={
              electricConsumption
                ? electricConsumption
                : {
                  labels: [],
                  data: [],
                }
            }
          />
        </div>

        <p
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            width: "fit-content",
            transform: "translate(-50%,-50%)",
            // marginLeft: "-45px",
            textAlign: "center",
          }}
        >
          <span
            style={{
              fontWeight: "600",
              fontSize: "25px",
            }}
          >
            {total?.toLocaleString("de-DE")}
          </span>
          <br />
          <span style={{ color: "var(--secondaryText)" }}>kWh</span>
        </p>
      </div>
      <Row
        gutter={[0, 5]}
        justify="end"
        style={{
          marginTop: "1rem",
          width: "100%",
          display: "flex",
          margin: "1rem auto 0 auto",
        }}
      >
        {
          electricConsumption?.labels?.map((label, index) => (
            <Col xs={24} sm={24} md={10} key={index}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "80%",
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div style={{ width: "auto" }}>
                    <p
                      style={{
                        background: colors?.[index],
                        width: "10px",
                        height: "10px",
                        borderRadius: "2px",
                        marginBottom: 0,
                        marginRight: "10px",
                      }}
                    ></p>
                  </div>
                  &nbsp;
                  <Tooltip
                    placement="left"
                    title={
                      <HoverCard
                        label={label}
                        trigger={trigger}
                        total={total}
                        perc={Number(
                          (
                            (electricConsumption?.data[index] /
                              total) *
                            100
                          ).toFixed()
                        )}
                        filter={filter}
                        strokeColor={colors?.[index]}
                      />
                    }
                    color="transparent"
                    onOpenChange={() => settrigger(!trigger)}
                  >
                    <p
                      style={{
                        marginBottom: 0,
                        marginRight: 10,
                        fontSize: 12,
                        width: "65%",
                        cursor: "pointer",
                      }}
                    >
                      {label === 'E-mobility' ? t('e_mobility') :
                        label === 'Laundry' ? t('laundry') :
                          label === 'Total' ? t('total') : label}
                    </p>
                  </Tooltip>
                </div>

                <p
                  style={{
                    marginBottom: 0,
                    fontSize: 12,
                    fontWeight: "bold",
                    width: "20%",
                  }}
                >
                  {(
                    (electricConsumption?.data[index] /
                      total) *
                    100
                  ).toFixed(1)}
                  %
                </p>
              </div>
            </Col>
          ))
        }
      </Row>
    </>
  );
};

export default ElectricConsumptionRoundChart;
