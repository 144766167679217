/* eslint-disable no-dupe-keys */
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
import React, { useState, useRef, useEffect } from "react";
import { getChargingHeatmap } from "../../redux";
import moment from "moment";
import Loader from "../Loader";

export default function CUSTOMHM({ filter }) {
  const ref = useRef(null);
  const [height, setHeight] = useState(50);
  const labelRef = useRef(null);
  const [clientX, setClientx] = useState(0);
  const [clientY, setClientY] = useState(0);
  const [displayAnnotation, setDisplayAnnotation] = useState(false);
  const [selectedItem, setItem] = useState("");

  const [heatMapData, setHeatMapData] = useState([]);
  const [max, setMax] = useState(0);
  const [isData, setIsData] = useState(false);

  const setDate = () => {
    var getDateArray = function (start, end) {
      var arr = [];
      let startDate = new Date(start);
      let endDate = new Date(end);
      while (startDate <= endDate) {
        arr.push(new Date(startDate).toISOString().slice(0, 10));
        startDate.setDate(startDate.getDate() + 1);
      }
      return arr;
    };
    var dateArr = getDateArray(
      moment().subtract(5, "days").format("YYYY-MM-DD"),
      moment().format("YYYY-MM-DD")
    );
    let intervals = ["0-6", "6-12", "12-18", "18-24"];
    let hours = [];
    //Mon 0-6
    dateArr?.map((date) => {
      intervals?.map((interval, ind) => {
        hours.push(moment(date).startOf("day").format("ddd") + " " + interval);
      });
    });
    return hours;
  };

  const getLast24Days = () => {
    var getDateArray = function (start, end) {
      var arr = [];
      let startDate = new Date(start);
      let endDate = new Date(end);
      while (startDate <= endDate) {
        arr.push(moment(startDate).format("DD"));
        startDate.setDate(startDate.getDate() + 1);
      }
      return arr;
    };
    var dateArr = getDateArray(
      moment().subtract(23, "days").format("YYYY-MM-DD"),
      moment().format("YYYY-MM-DD")
    );

    return dateArr;
  };

  const getData = async () => {
    const result = await getChargingHeatmap();
    console.log("result",result)

    setMax(result.maximum);
    setHeatMapData([
      {
        name: "",
        values: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
      },
      ...result?.data,
    ]);
  };

  useEffect(() => {
    setHeatMapData([]);
    getData();
    setHeight(ref?.current?.offsetWidth * 1.33);
  }, []);

  useEffect(() => {
    const timeOut = setInterval(() => {
      setDisplayAnnotation(false);
    }, 500);
    return () => {
      clearInterval(timeOut);
    };
  }, []);

  const Annotation = ({ name, totalLength }) => {
    return (
      <>
        {displayAnnotation && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              flex: 1,
              height: 65,
              width: 250,
              boxShadow: "rgb(174 174 174) 0px 0px 10px",
              transition:
                "left 0.4s cubic-bezier(0.23, 1, 0.32, 1) 0s, top 0.4s cubic-bezier(0.23, 1, 0.32, 1) 0s",
              color: "rgb(89, 89, 89)",
              backgroundColor: "white",
              fontFamily: "Poppins",
              left: clientX - 100,
              top: clientY - 100,
              position: "fixed",
              borderRadius: "4px",
              padding: "0 20px",
              fontSize: "10px",
              paddingTop: "5px",
              opacity: selectedItem.name === "" ? 0 : 1,
              zIndex: 100000,
            }}
          >
            <p
              style={{
                fontWeight: "bold",
                fontSize: "13px",
                marginBottom: "5px",
                color: "black",
              }}
            >
              {selectedItem.title}
            </p>
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <p
                style={{
                  background: "#DD243B",
                  width: 8,
                  height: 8,
                  borderRadius: "50%",
                  opacity: selectedItem.opacity,
                  marginBottom: 0,
                  marginRight: 10,
                }}
              />
              <p
                style={{
                  marginBottom: 0,
                  width: "35px",
                  width: "50%",
                  fontWeight: "400",
                  fontSize: "12px",
                  color: "black",
                }}
              >
                {selectedItem.value?.toLocaleString("de-DE")} kW
              </p>
              <p
                style={{
                  color: "#7E84A3",
                  fontSize: "12px",
                  marginBottom: 0,
                }}
              >
                {selectedItem.name}
              </p>
            </div>
          </div>
        )}
      </>
    );
  };

  const BOX = ({
    item,
    value,
    numberOnly,
    totalLength,
    itemObj,
    arrayLength,
  }) => {
    return (
      <div

        ref={ref}
        style={{
          width: `calc(100%/7)`,
          height: "100%",
          backgroundColor: numberOnly ? "#fff" : "#DD243B",
          opacity: numberOnly ? 1 : item / totalLength,
          borderRadius: "4px",
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "center",
          border: "1px solid white",
          color: "#131523",
          margin: "1px",
          textAlign: "center",
          fontSize: "0.9rem",
        }}
      >
        <span>{numberOnly ? value : ""}</span>
      </div>
    );
  };

  return (
    <>
      {heatMapData?.length > 0 ? (
        <>
          <Annotation />
          <div style={{ overflow: "auto", height: "100%" }}>
            <div style={{ minWidth: "100%", height: "100%" }}>
              {heatMapData?.map(
                (item, index) => {
                  return (
                    <div
                      style={{ display: "flex", height: "calc(85%/7)" }}
                      key={index}
                    >
                      <div
                        ref={labelRef}
                        style={{
                          minWidth: 60,
                          maxWidth: 60,
                          display: "flex",
                          alignItems: "center",
                          color: "#131523",
                          marginRight: "10px",
                          fontSize: "0.9rem",
                          justifyContent: "flex-end",
                        }}
                      >
                        {item.name}
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flex: 1,
                          flexDirection: "row",
                          justifyContent: "space-evenly",
                        }}
                      >
                        {item?.values?.map((itemC, ind) => {
                          return (
                            <>
                              <BOX
                                key={ind}
                                item={itemC._value ? itemC._value : itemC}
                                value={itemC._value ? itemC._value : itemC}
                                numberOnly={item.name === ""}
                                arrayLength={item.values.length}
                                totalLength={max}
                                itemObj={{
                                  name: item.name,
                                  value: itemC._value ? itemC._value : itemC,
                                  title: itemC?._time,
                                }}
                              />
                            </>
                          );
                        })}
                      </div>
                    </div>
                  );
                }
              )}
            </div>
          </div>
        </>
      ) : (
        <div className="loading">
          <Loader />
        </div>
      )}
    </>
  );
}
