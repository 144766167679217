/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from "react";
import { Typography, Progress } from "antd";
import { useSelector } from "react-redux";
import { BsArrowDown, BsArrowUp } from "react-icons/bs";
import { getControlNodes, getDataChannels, getElectricConsumption } from "../../redux";
import { setDisplay } from "../../redux";
import { useDispatch } from "react-redux";
import { SET_DATA_CHANNELS } from "../../redux/types/homeTypes";
import { useTranslation } from "react-i18next";

const ElectricCard = ({
  setIsModalOpen,
  setSelectedName,
  settrigger,
  trigger,
}) => {
  const electricRef = useRef(null);
  const controlNodes = useSelector((state) => state.homeReducer.controlNodes);
  const [electricConsumption, setElectricConsumption] = useState(null);
  const colors = useSelector((state) => state.userReducer.colors);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const showModal = (data, index) => {
    setIsModalOpen(true);
    dispatch({
      type: SET_DATA_CHANNELS,
      payload: [],
    });

    dispatch(getDataChannels({ email: localStorage.getItem("enexpertUserEmail"), code: data?.code }))

    setSelectedName({
      ...data,
      perc: data.percentage,
      index: index
    });
    settrigger(!trigger);
    dispatch(setDisplay());
  };

  const getData = async () => {
    const result = await getElectricConsumption();
    setElectricConsumption(result);
  };

  useEffect(() => {
    getData();
    dispatch(getControlNodes({ email: localStorage.getItem("enexpertUserEmail") }))
  }, []);

  const total = controlNodes.reduce((sum, item) => {
    return sum + Number(item?.percentage);
  }, 0);

  return (
    <div className="electric-consumption-outer" ref={electricRef}>

      <div className="electric-consumption">
        <div className="electric-consumption-left">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "1rem",
            }}
          >
            <Typography.Title
              className="title"
              level={5}
              style={{ fontWeight: "600", fontSize: "13px" }}
            >
              {t("live_electric_consumption")}
            </Typography.Title>
          </div>
          <Typography.Title className="sub-title" level={4}>
            {electricConsumption?.TotalConsumption
              ? Number(
                electricConsumption?.TotalConsumption?.toFixed(2)
              ).toLocaleString("de-DE")
              : "0"}{" "}
            kW
          </Typography.Title>
          <div className="percentage-wrapper">
            <span className="percentage" style={{ color: "var(--green)" }}>
              {" "}
              {electricConsumption?.Last_Hour_Comparison
                ? electricConsumption?.Last_Hour_Comparison > 0
                  ? electricConsumption?.Last_Hour_Comparison?.toLocaleString(
                    "de-DE"
                  )
                  : String(
                    electricConsumption?.Last_Hour_Comparison?.toLocaleString(
                      "de-DE"
                    )
                  )?.substr(1)
                : "0"}
              %&nbsp;
              {electricConsumption?.Last_Hour_Comparison > 0 ? (
                <BsArrowUp style={{ strokeWidth: 2 }} />
              ) : (
                <BsArrowDown style={{ strokeWidth: 2 }} />
              )}
            </span>
            &nbsp;
            <span className="text">{t("than_last_hour")}</span>
          </div>
        </div>
        <div className="electric-consumption-right">
          {controlNodes.length > 0 && controlNodes?.map((data, index) => (
            <div className="bar-chart-warpper" key={index}>
              <p
                style={{ cursor: "pointer", marginRight: "10px", fontSize: "10px", whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                onClick={() => {
                  showModal(data, index);
                }}
              >
                {data?.name === 'E-mobility' ? t('e_mobility') :
                  data?.name === 'Laundry' ? t('laundry') : data?.name}
              </p>
              <Progress
                strokeLinecap="square"
                strokeColor={colors?.[index]}
                percent={parseFloat(((data?.percentage / total) * 100).toFixed(1))}
                strokeWidth={6}
                style={{ width: '200px' }}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ElectricCard;
