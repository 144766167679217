/* eslint-disable no-unused-vars */
import { useState } from "react";
import { Button, Form, Input, Typography } from "antd";
import { useHistory, Redirect } from "react-router-dom";
import logo from "../../Assets/logo2.png";

const Admin = () => {
    const history = useHistory();
    const [loading, setLoading] = useState(false);

    const clientLoginFnc = async (values, demo = false) => {
        const { email, password } = values;

        if (email === 'admin' && password === 'admin') {
            sessionStorage.setItem('adminToken', 'qwertyuiopmnbvcxzasdfghjkl');
            history.push('/admin-dashboard')

        }
    };

    if (localStorage.hasOwnProperty("token")) {
        return <Redirect to="/services-dashboard" />;
    } else {
        return (
            <div className="temp-login-main">
                <div className="login-main">
                    <img src={logo} alt="" />
                    <p>Sign in to your account to continue</p>
                    <Form
                        layout="vertical"
                        onFinish={clientLoginFnc}
                        className="login-form"
                    >
                        <Typography.Title level={2}>Welcome!</Typography.Title>

                        <Form.Item name={"email"} label={"Email"}>
                            <Input />
                        </Form.Item>
                        <Form.Item name={"password"} label={"Password"}>
                            <Input type="password" />
                        </Form.Item>
                        <Form.Item>
                            <Button type="primary" loading={loading} htmlType="submit">
                                Login
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </div>
        );
    }
};

export default Admin;
