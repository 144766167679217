import { DualAxes, G2 } from "@ant-design/plots";
import { useEffect, useState } from "react";
import TooltipComp1 from "../TooltipComp1";

const DemoDualAxes = ({
  leftUnit,
  rightUnit,
  xUnit,
  sliced,
  alias,
  animate,
  dot,
  isStack,
  radius,
  Data,
}) => {
  const data = [
    {
      time: "01",
      value: 50,
      accumulatedSum: 50,
    },
    {
      time: "02",
      value: 22,
      accumulatedSum: 72,
    },
    {
      time: "03",
      value: 57,
      accumulatedSum: 129,
    },
    {
      time: "04",
      value: 139,
      accumulatedSum: 268,
    },
    {
      time: "05",
      value: 61,
      accumulatedSum: 329,
    },
    {
      time: "06",
      value: 63,
      accumulatedSum: 392,
    },
    {
      time: "07",
      value: 107,
      accumulatedSum: 499,
    },
    {
      time: "08",
      value: 41,
      accumulatedSum: 540,
    },
    {
      time: "09",
      value: 85,
      accumulatedSum: 625,
    },
    {
      time: "10",
      value: 146,
      accumulatedSum: 771,
    },
    {
      time: "11",
      value: 36,
      accumulatedSum: 807,
    },

    {
      time: "12",
      value: 79,
      accumulatedSum: 886,
    },
    {
      time: "13",
      value: 110,
      accumulatedSum: 996,
    },
    {
      time: "14",
      value: 42,
      accumulatedSum: 1038,
    },
    {
      time: "15",
      value: 27,
      accumulatedSum: 1065,
    },
    {
      time: "16",
      value: 25,
      accumulatedSum: 1090,
    },
    {
      time: "17",
      value: 35,
      accumulatedSum: 1125,
    },
    {
      time: "18",
      value: 56,
      accumulatedSum: 1181,
    },
    {
      time: "19",
      value: 99,
      accumulatedSum: 1280,
    },
    {
      time: "20",
      value: 94,
      accumulatedSum: 1374,
    },
    {
      time: "21",
      value: 46,
      accumulatedSum: 1420,
    },
    {
      time: "22",
      value: 126,
      accumulatedSum: 1546,
    },
    {
      time: "23",
      value: 70,
      accumulatedSum: 1616,
    },
    {
      time: "24",
      value: 87,
      accumulatedSum: 1703,
    },
    {
      time: "25",
      value: 107,
      accumulatedSum: 1810,
    },
    {
      time: "26",
      value: 32,
      accumulatedSum: 1842,
    },
    {
      time: "27",
      value: 23,
      accumulatedSum: 1865,
    },
    {
      time: "28",
      value: 100,
      accumulatedSum: 1965,
    },
    {
      time: "29",
      value: 50,
      accumulatedSum: 2015,
    },
    {
      time: "30",
      value: 45,
      accumulatedSum: 2060,
    },
    {
      time: "31",
      value: 51,
      accumulatedSum: 2111,
    },
  ];

  const uvBillData = [
    {
      time: "01",
      value: 350,
      type: "Load Peak",
    },
    {
      time: "02",
      value: 900,
      type: "Load Peak",
    },
    {
      time: "03",
      value: 300,
      type: "Load Peak",
    },
    {
      time: "04",
      value: 450,
      type: "Load Peak",
    },
    {
      time: "05",
      value: 470,
      type: "Load Peak",
    },
    {
      time: "06",
      value: 340,
      type: "Load Peak",
    },
    {
      time: "07",
      value: 480,
      type: "Load Peak",
    },
    // {
    //   time: "08",
    //   value: 200,
    //   type: "Load Peak",
    // },
    // {
    //   time: "09",
    //   value: 104,
    //   type: "Load Peak",
    // },
    // {
    //   time: "10",
    //   value: 290,
    //   type: "Load Peak",
    // },
    {
      time: "01",
      value: 220,
      type: "Self Consumption",
    },
    {
      time: "02",
      value: 300,
      type: "Self Consumption",
    },
    {
      time: "03",
      value: 250,
      type: "Self Consumption",
    },
    {
      time: "04",
      value: 220,
      type: "Self Consumption",
    },
    {
      time: "05",
      value: 362,
      type: "Self Consumption",
    },
    {
      time: "06",
      value: 302,
      type: "Self Consumption",
    },
    {
      time: "07",
      value: 150,
      type: "Self Consumption",
    },
    // {
    //   time: "08",
    //   value: 270,
    //   type: "Self Consumption",
    // },
    // {
    //   time: "09",
    //   value: 362,
    //   type: "Self Consumption",
    // },
    // {
    //   time: "10",
    //   value: 120,
    //   type: "Self Consumption",
    // },
  ];
  const transformData = [
    {
      time: "01",
      accumulatedSum: 800,
    },
    {
      time: "02",
      accumulatedSum: 1400,
    },
    {
      time: "03",
      accumulatedSum: 1800,
    },
    {
      time: "04",
      accumulatedSum: 2180,
    },
    {
      time: "05",
      accumulatedSum: 2400,
    },
    {
      time: "06",
      accumulatedSum: 2600,
    },
    {
      time: "07",
      accumulatedSum: 3490,
    },
    // {
    //   time: "08",
    //   accumulatedSum: 3742,
    // },
    // {
    //   time: "09",
    //   accumulatedSum: 4162,
    // },
    // {
    //   time: "10",
    //   accumulatedSum: 4382,
    // },
  ];

  const [state, setState] = useState(15000);
  useEffect(() => {
    let interval = setInterval(() => {
      setState(Number(Math.random() * (15000 - 14000) + 14000).toFixed());
    }, 30000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  // console.log("state", state);

  if (dot) {
    G2.registerShape("point", "breath-point", {
      draw(cfg, container) {
        const data = cfg.data;
        const point = {
          x: cfg.x,
          y: cfg.y,
        };
        const group = container.addGroup();

        if (data.time === "08") {
          const decorator1 = group.addShape("circle", {
            attrs: {
              x: point.x,
              y: point.y,
              r: 10,
              fill: cfg.color,
              opacity: 0.5,
            },
          });
          const decorator2 = group.addShape("circle", {
            attrs: {
              x: point.x,
              y: point.y,
              r: 10,
              fill: cfg.color,
              opacity: 0.5,
            },
          });
          const decorator3 = group.addShape("circle", {
            attrs: {
              x: point.x,
              y: point.y,
              r: 10,
              fill: cfg.color,
              opacity: 0.5,
            },
          });
          decorator1.animate(
            {
              r: 20,
              opacity: 0,
            },
            {
              duration: 1800,
              easing: "easeLinear",
              repeat: false,
            }
          );
          decorator2.animate(
            {
              r: 20,
              opacity: 0,
            },
            {
              duration: 1800,
              easing: "easeLinear",
              repeat: true,
              delay: 600,
            }
          );
          decorator3.animate(
            {
              r: 20,
              opacity: 0,
            },
            {
              duration: 1800,
              easing: "easeLinear",
              repeat: true,
              delay: 1200,
            }
          );
          group.addShape("circle", {
            attrs: {
              x: point.x,
              y: point.y,
              r: 6,
              fill: cfg.color,
              opacity: 0.7,
            },
          });
          group.addShape("circle", {
            attrs: {
              x: point.x,
              y: point.y,
              r: 1.5,
              fill: cfg.color,
            },
          });
        }

        return group;
      },
    });
  }

  const config = {
    data:
      isStack && Data
        ? [Data?.barData, Data?.lineData]
        : sliced
          ? [data.slice(0, sliced), data.slice(0, sliced)]
          : [uvBillData, transformData],

    xField: "time",
    yField: sliced ? ["value", "accumulatedSum"] : ["value", "accumulatedSum"],

    legend: false,
    tooltip: {
      // position: "top",
      domStyles: {
        "g2-tooltip": {
          background: "white",
          // background: "#131523",
          color: "black",
          opacity: 1,
          borderRadius: "8px",
        },
      },
      customContent: (title, data) => {
        console.log("data",data);
        return (
          <TooltipComp1
            data={data}
            units={["kWh", "kWh", ""]}
            fields={["Grid", "Self Production", "Accumulated Consumption"]}
          />
        );
      },
    },
    // xAxis: {
    //   title: {
    //     text: xUnit,
    //     style: {
    //       stroke: "#7E84A3",
    //       fontSize: 12,
    //       letterSpacing: "1px",
    //       fontWeight: 300,
    //       opacity: 0.7,
    //       shadowBlur: 0,
    //       strokeOpacity: 0,
    //     },
    //   },
    // },
    yAxis: {
      value: {
        title: {
          text: leftUnit,
          style: {
            stroke: "#131523",
            fontSize: 10,
            letterSpacing: "1px",
            fontWeight: "normal",
            opacity: 0.9,
            shadowBlur: 0,
            strokeOpacity: 0,
          },
        },
      },
      accumulatedSum: {
        title: {
          text: rightUnit,
          style: {
            stroke: "#131523",
            fontSize: 10,
            letterSpacing: "1px",
            fontWeight: "normal",
            opacity: 0.9,
            shadowBlur: 0,
            strokeOpacity: 0,
          },
        },
      },
      count: {
        title: {
          text: rightUnit,
          style: {
            stroke: "#131523",
            fontSize: 10,
            letterSpacing: "1px",
            fontWeight: "normal",
            opacity: 0.9,
            shadowBlur: 0,
            strokeOpacity: 0,
          },
        },
      },
    },

    animation: animate
      ? {
        appear: {
          easing: "easeSinInOut",
          duration: state,
        },
      }
      : false,
    geometryOptions: [
      {
        geometry: 'column',
        color: "#0099FF",
        columnStyle: {
          radius: [10, 10, 0, 0],
        },
      },
      {
        geometry: 'line',
        color: "#DD243B",
        //     smooth: true,
        //     // point: dot
        //     //   ? {
        //     //       shape: "breath-point",
        //     //     }
        //     //   : false,
        lineStyle: {
          lineWidth: 2,
        },
      },
    ],
    // geometryOptions: [
    //   {
    //     geometry: "column",
    //     // color: "#0099FF",
    //     color: ({ type }) => {
    //       if (type === "grid_feed" || type === "Load Peak") {
    //         return "#37A1DB";
    //       }
    //       return "#F99600";
    //     },
    //     isStack: true,
    //     seriesField: "type",
    //     columnStyle: {
    //       radius: radius ? radius : 10,
    //       // radius: [4, 4, 0, 0],
    //     },
    //   },
    //   {
    //     geometry: "line",
    //     color: "#DD243B",
    //     smooth: true,
    //     // point: dot
    //     //   ? {
    //     //       shape: "breath-point",
    //     //     }
    //     //   : false,
    //     lineStyle: {
    //       lineWidth: 3,
    //     },
    //   },
    // ],
    state: {
      active: {
        style: {
          outline: "none",
          border: "none",
        },
      },
    },
    // interactions: [
    //   {
    //     type: "element-list-highlight",
    //   },
    //   {
    //     type: "element-single-selected",
    //   },
    //   {
    //     type: "element-active",
    //   },
    // ],
  };
  return <DualAxes {...config} />;
};

export default DemoDualAxes;
