/* eslint-disable no-dupe-keys */
import React from "react";
import { Column } from "@ant-design/plots";
import { ClockCircleFilled } from "@ant-design/icons";

const DemoColumn = ({ Data, Alias, label, unit, time }) => {

  const config = {
    data: Data,
    xField: "type",
    yField: "sales",
    autoFit: true,
    color: "#37A1DB",

    tooltip: {
      offset: 80,
      domStyles: {
        "g2-tooltip": {
          background: "transparent",
          minWidth: "250px",
          maxWidth: "250px",
          borderRadius: 0,
          padding: "0",
        },
      },
      customContent: (title, data) => {
        return (
          <>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                filter: "drop-shadow(-1px 6px 3px rgba(50, 50, 0, 0.5))",
                position: "relative",
                color: "rgb(89, 89, 89)",
                backgroundColor: "white",
                fontFamily: "Poppins",
                borderRadius: "4px",
                padding: "0 20px",
                paddingTop: "10px",
                paddingBottom: "10px",
                fontSize: "10px",
                minHeight: "auto",
                minWidth: "auto",
              }}
            >
              <div className="tooltip-polygon" />
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {time === "Last Day" ? (
                  <>
                    <p
                      style={{
                        fontSize: "13px",
                        fontWeight: 500,
                        color: "black",
                        marginTop: "3px",
                      }}
                    >
                      {data?.[0]?.data?.interval.split(",")[0]}
                    </p>
                    <p
                      style={{
                        fontSize: "12px",
                        fontWeight: 400,
                        color: "black",
                      }}
                    >
                      {" "}
                      <ClockCircleFilled
                        style={{
                          marginLeft: "15px",
                          marginRight: "3px",
                        }}
                      />
                      {data?.[0]?.data?.interval.split(",")[1]}
                    </p>
                  </>
                ) : (
                  data?.[0]?.data?.interval
                )}
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <p
                  style={{
                    background: data?.[0]?.color,
                    width: 10,
                    height: 10,
                    borderRadius: "50%",
                    marginBottom: 0,
                    marginRight: 10,
                  }}
                />
                <p
                  style={{
                    marginBottom: 0,
                    width: "35px",
                    width: "45%",
                    fontSize: "13px",

                    fontWeight: "500",
                    color: "black",
                  }}
                >
                  {data?.[0]?.name}
                </p>
                <p
                  style={{
                    color: "black",
                    fontSize: "12px",
                    fontWeight: "400",
                    marginBottom: 0,
                  }}
                >
                  {data?.[0]?.value?.toLocaleString("de-DE")}{" "}
                  {unit ? " " + unit : " kWh"}
                </p>
              </div>
            </div>
          </>
        );
      },
    },

    xAxis: {
      line: null,
      grid: {
        line: {
          style: {
            stroke: "black",
            lineWidth: 0.1,
          },
        },
        alignTick: true,
        closed: false,
        nice: false,
      },
    },

    yAxis: {

      title: {
        text: "kWh",
        style: {
          stroke: "#000000",
          fontSize: 10,
          color: "#000000",
          letterSpacing: "1px",
          fontWeight: 300,
          opacity: 0.9,
          shadowBlur: 0,
          strokeOpacity: 0,
        },
      },
      grid: {
        line: {
          style: {
            stroke: "black",
            lineWidth: 0,
          },
        },
        alignTick: true,
        closed: true,
      },
    },
    meta: {
      sales: {
        alias: Alias ? Alias : "Production",
      },
    },
    interactions: [
      {
        type: "active-region",
        enable: false,
      },
    ],
    minColumnWidth: 4,
    maxColumnWidth: 4,
    columnStyle: {
      fill: "#37A1DB",
      fillOpacity: 1,
      radius: [20, 20, 0, 0],
    },
  };

  return <Column {...config} animation={false} style={{ maxHeight: "230px" }} />;
};

export default DemoColumn;
