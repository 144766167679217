export const Data =
[
    {
      "year": "1850",
      "value": 55,
      "category": "Liquid fuel"
    },
    {
      "year": "1850",
      "value": 54,
      "category": "Solid fuel"
    },
    {
      "year": "1850",
      "value": 45,
      "category": "Gas fuel"
    },
    {
      "year": "1850",
      "value": 35,
      "category": "Cement production"
    },
    {
      "year": "1850",
      "value": 30,
      "category": "Gas flarinl"
    },
    {
      "year": "1851",
      "value": 51,
      "category": "Liquid fuel"
    },
    {
      "year": "1851",
      "value": 54,
      "category": "Solid fuel"
    },
    {
      "year": "1851",
      "value": 49,
      "category": "Gas fuel"
    },
    {
      "year": "1851",
      "value": 46,
      "category": "Cement production"
    },
    {
      "year": "1851",
      "value": 43,
      "category": "Gas flarinl"
    },
    {
      "year": "1852",
      "value": 40,
      "category": "Liquid fuel"
    },
    {
      "year": "1852",
      "value": 57,
      "category": "Solid fuel"
    },
    {
      "year": "1852",
      "value": 37,
      "category": "Gas fuel"
    },
    {
      "year": "1852",
      "value": 34,
      "category": "Cement production"
    },
    {
      "year": "1852",
      "value": 30,
      "category": "Gas flarinl"
    },
    {
      "year": "1853",
      "value": 69,
      "category": "Liquid fuel"
    },
    {
      "year": "1853",
      "value": 59,
      "category": "Solid fuel"
    },
    {
      "year": "1853",
      "value": 49,
      "category": "Gas fuel"
    },
    {
      "year": "1853",
      "value": 39,
      "category": "Cement production"
    },
    {
      "year": "1853",
      "value": 28,
      "category": "Gas flarinl"
    },
    {
      "year": "1854",
      "value": 79,
      "category": "Liquid fuel"
    },
    {
      "year": "1854",
      "value": 69,
      "category": "Solid fuel"
    },
    {
      "year": "1854",
      "value": 59,
      "category": "Gas fuel"
    },
    {
      "year": "1854",
      "value": 49,
      "category": "Cement production"
    },
    {
      "year": "1854",
      "value": 39,
      "category": "Gas flarinl"
    },
    {
      "year": "1855",
      "value": 81,
      "category": "Liquid fuel"
    },
    {
      "year": "1855",
      "value": 71,
      "category": "Solid fuel"
    },
    {
      "year": "1855",
      "value": 61,
      "category": "Gas fuel"
    },
    {
      "year": "1855",
      "value": 51,
      "category": "Cement production"
    },
    {
      "year": "1855",
      "value": 41,
      "category": "Gas flarinl"
    },
    {
      "year": "1856",
      "value": 31,
      "category": "Liquid fuel"
    },
    // { "category": "Liquid fuel"
    // },
    {
      "year": "1868",
      "value": 134,
      "category": "Solid fuel"
    },
    {
      "year": "1868",
      "value": 124,
      "category": "Gas fuel"
    },
    {
      "year": "1868",
      "value": 114,
      "category": "Cement production"
    },
    {
      "year": "1868",
      "value": 104,
      "category": "Gas flarinl"
    },
    {
      "year": "1869",
      "value": 152,
      "category": "Liquid fuel"
    },
    {
      "year": "1869",
      "value": 142,
      "category": "Solid fuel"
    },
    {
      "year": "1869",
      "value": 132,
      "category": "Gas fuel"
    },
    {
      "year": "1869",
      "value": 122,
      "category": "Cement production"
    },
    {
      "year": "1869",
      "value": 102,
      "category": "Gas flarinl"
    },
    {
      "year": "1870",
      "value": 156,
      "category": "Liquid fuel"
    },
    {
      "year": "1870",
      "value": 146,
      "category": "Solid fuel"
    },
    {
      "year": "1870",
      "value": 136,
      "category": "Gas fuel"
    },
    {
      "year": "1870",
      "value": 126,
      "category": "Cement production"
    },
    {
      "year": "1870",
      "value": 106,
      "category": "Gas flarinl"
    },
    {
      "year": "1871",
      "value": 166,
      "category": "Liquid fuel"
    },
    {
      "year": "1871",
      "value": 156,
      "category": "Solid fuel"
    },
    {
      "year": "1871",
      "value": 146,
      "category": "Gas fuel"
    },
    {
      "year": "1871",
      "value": 136,
      "category": "Cement production"
    },
    {
      "year": "1871",
      "value": 126,
      "category": "Gas flarinl"
    },
    {
      "year": "1872",
      "value": 116,
      "category": "Liquid fuel"
    },
    {
      "year": "1872",
      "value": 173,
      "category": "Solid fuel"
    },
    {
      "year": "1872",
      "value": 153,
      "category": "Gas fuel"
    },
    {
      "year": "1872",
      "value": 143,
      "category": "Cement production"
    },
    {
      "year": "1872",
      "value": 162,
      "category": "Gas flarinl"
    }
    
]