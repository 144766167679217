/* eslint-disable react-hooks/exhaustive-deps */
import { Typography, Row, Col } from "antd";
import { useEffect, useState, useRef } from "react";
import { RiChargingPileLine } from "react-icons/ri";
import LiquidChart from "../../Components/charts/LiquidChart";
import { getStationsData } from "../../redux";
import { useTranslation } from "react-i18next";

const ChargingStationCard = ({ height, setHeight }) => {
  const { t } = useTranslation();
  const heightRef = useRef(null);
  const [stationData, setStationData] = useState([]);


  useEffect(() => {
    setHeight(heightRef?.current?.offsetHeight);
  }, [heightRef?.current?.offsetHeight]);

  useEffect(() => {
    (async () => {
      const data = await getStationsData();
      setStationData(data);
    })();
  }, []);

  useEffect(() => {
    const timeoutID = setInterval(async () => {
      const data = await getStationsData();
      setStationData(data);
    }, 10000);

    return () => {
      clearInterval(timeoutID);
    };
  }, []);

  return (
    <div
      className="live-card white-card"
      style={{ height: "100%" }}
      ref={heightRef}
    >
      <Typography.Title
        className="title main-title"
        level={5}
        style={{ fontWeight: "500", fontSize: "15px" }}
      >
        {t('charging_stations')}
      </Typography.Title>

      <Row gutter={[0, 20]}>
        {stationData?.map((battery, index) => (
          <Col xs={12} md={6} key={index}>
            <div className="charging-card">
              <LiquidChart
                percentage={battery?.chargedPercent}
                size={79}
                status={battery.status ? "Charging" : "Completed"}
                content
                time={battery.remainingTime}
              />

              <p
                style={{
                  margin: 0,
                  display: "flex",
                  alignItems: "center",
                  fontWeight: 500,
                  color: battery.status ? "#46C782" : "#D5D7E3",
                  fontSize: "0.65rem",
                }}
              >
                {battery.load} kW
              </p>

              <p
                style={{
                  margin: 0,
                  display: "flex",
                  alignItems: "center",
                  fontWeight: 500,
                  color: battery.status ? "#46C782" : "#D5D7E3",
                  fontSize: "0.65rem",
                }}
              >
                <RiChargingPileLine
                  size={15}
                  style={{ marginRight: 5 }}
                  color={battery.status ? "#46C782" : "#D5D7E3"}
                />
                {battery.name}
              </p>

            </div>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default ChargingStationCard;
