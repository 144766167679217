import { notification } from "antd";
import { publicAPI, attachToken } from "../../API";
import {
  SET_USER_COLORS,
  SET_USER_RESTRICTIONS,
  SET_BAR_COLORS,
  SET_LIVE_PAGE_CONFIG, SET_MEAN_NODES
} from "../types/generalTypes";

export const ManagerLogin = (payload, history, isDemo = false) => {
  return async (dispatch) => {
    try {
      const res = await publicAPI.post(`/manager/loginManager`, payload);
      if (res) {
        localStorage.setItem("token", res.data.token);
        localStorage.setItem("name", res.data.user.userName);
        localStorage.setItem("enexpertUserEmail", res.data.user.email);
        localStorage.setItem("userId", res.data.user._id);
        localStorage.setItem("redirectswitch", true);
        if (isDemo) {
          localStorage.setItem("isDemo", true);
        }
        notification.success({
          message: res.data.message,
          duration: 3,
        });
        attachToken();
        dispatch({
          type: SET_USER_COLORS,
          payload: res.data.user.colors,
        });
        dispatch({
          type: SET_MEAN_NODES,
          payload: res.data.user.measNodes,
        });
        dispatch({
          type: SET_USER_RESTRICTIONS,
          payload: res.data.user.sidebarAccess,
        });
        dispatch({
          type: SET_BAR_COLORS,
          payload: res.data.user.barColors,
        });
        dispatch({
          type: SET_LIVE_PAGE_CONFIG,
          payload: res.data.user.livePageConfig,
        });
        history.replace("/services-dashboard");
      }
    } catch (err) {
      notification.error({
        message: err?.response?.data?.message || "Server Error",
        duration: 3,
      });
    }
  };
};

export const ManagerSignup = async (payload, history) => {
  try {
    const res = await publicAPI.post(`/manager/createManager`, payload);
    if (res) {
      notification.success({
        message: res.data.message,
        duration: 3,
      });
      history.replace("/service");
    }
  } catch (err) {
    notification.error({
      message: err?.response?.data?.message || "Server Error",
      duration: 3,
    });
  }
};
