export const SET_USER_COLORS = "SET_USER_COLORS";
export const SET_USER_RESTRICTIONS = "SET_USER_RESTRICTIONS";
export const SET_BAR_COLORS = "SET_BAR_COLORS";
export const SET_LIVE_PAGE_CONFIG = "SET_LIVE_PAGE_CONFIG";
export const GET_ANALYSIS_DATA = "GET_ANALYSIS_DATA";
export const DISPLAY_MASK = "DISPLAY_MASK";
export const SET_DISPLAY_ANNOTATION = "SET_DISPLAY_ANNOTATION";
export const SET_DISPLAY_ANNOTATION_SUB = "SET_DISPLAY_ANNOTATION_SUB";
export const SET_MEAN_NODES = "SET_MEAN_NODES";
export const SET_SUBNODES_VALUES_FROM_INFLUX = "SET_SUBNODES_VALUES_FROM_INFLUX";
export const SET_DYNAMIC_UI = "SET_DYNAMIC_UI";