import React from "react";
import { Column } from "@ant-design/plots";

const Co2Consumption = ({ data }) => {
  const config = {
    data,
    xField: "days",
    yField: "value",
    isGroup: true,
    isStack: true,
    seriesField: 'type',
    groupField: 'type',
    lineWidth: 1,
    legend: false,
    yAxis: {
      min: 0,
    },
    columnStyle: {
      radius: [4, 4, 0, 0],
    },
    color: ["#75232D", "#025699", "#DD243B", "#37A1DB"],
    label: {
      formatter: (datum) => {
        return datum?.type.split("-")[0] === "Grid"
          ? datum?.type.split("-")[1] + " " + datum?.type.split("-")[0]
          : datum?.type.split("-")[0];
      },
      position: "middle",
      rotate: 80.1,
      style: {
        borderTopLeftRadius: 8,
        borderTopRightRadius: 8,
      },
      layout: [
        {
          type: "interval-adjust-position",
        },
        {
          type: "interval-hide-overlap",
        },
      ],
    },



    tooltip: {
      domStyles: {
        "g2-tooltip": {
          background: "transparent",
          minWidth: "250px",
          maxWidth: "250px",
          borderRadius: 0,
          padding: "0",
        },
      },
      customContent: (title, data) => {
        if (data) {
          return (
            <>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  color: "rgb(89, 89, 89)",
                  backgroundColor: "white",
                  fontFamily: "Poppins",
                  borderRadius: "4px",
                  padding: "0 20px",
                  fontSize: "10px",
                  paddingTop: "10px",
                  paddingBottom: "10px",
                  filter: "drop-shadow(-1px 6px 3px rgba(50, 50, 0, 0.5))",
                  position: "relative",
                  minHeight: "auto",
                  minWidth: "auto",
                }}
              >
                <div className="tooltip-bottom" />

                {data?.map((itm) => (
                  <>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        margin: "5px 0",
                      }}
                    >
                      <p
                        style={{
                          background: itm?.color,
                          width: 12,
                          height: 12,
                          borderRadius: "50%",
                          marginBottom: 0,
                          marginRight: 10,
                        }}
                      />
                      <p
                        style={{
                          marginBottom: 0,
                          width: "100px",
                          fontSize: "15px",
                          fontWeight: "400",
                          color: "black",
                        }}
                      >
                        {itm?.name?.split("-")[0] +
                          " " +
                          itm?.name?.split("-")[1] +
                          ":"}
                      </p>
                      <p
                        style={{
                          color: "black",
                          fontSize: "15px",
                          marginBottom: 0,
                          fontWeight: "500",
                          width: "100px",
                        }}
                      >
                        {itm?.value?.toLocaleString("de-DE")} {" kg"}
                      </p>
                    </div>
                  </>
                ))}
              </div>
            </>
          );
        }
      },
    },
  };

  return <Column {...config} />;
};
export default Co2Consumption;
