/* eslint-disable no-dupe-keys */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from "react";
import { Line } from "@ant-design/plots";
import moment from "moment";
import { ClockCircleFilled } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { getChannelValuesAverage } from "../../redux";
const CompareChart = ({ channel, time, unit }) => {
  const [data, setData] = useState([]);
  const [color, setColor] = useState([]);
  const [chartClick, setChartClick] = useState([]);
  const [clientx, setClientx] = useState(0);
  const [clienty, setClienty] = useState(0);
  const [displaTooltip, setDisplayTooltip] = useState(false);
  const [firstValue, setFirstValue] = useState("");
  const [secondValue, setSecondValue] = useState(null);
  const [value, setValue] = useState()
  const [clickCount, setClickCount] = useState(0);
  const [startX, setStartX] = useState(0);
  const [endX, setEndX] = useState(0);
  const dispatch = useDispatch()
  const divRef = useRef(null);
  const [average, setAverage] = useState(0);

  const handleMouseDown = async (e) => {
    const rect = divRef.current.getBoundingClientRect();
    const offsetX = e.pageX - rect.left;
    if (clickCount === 0) {
      setStartX(offsetX);
      setClickCount(clickCount + 1);
    } else if (clickCount === 1) {
      setEndX(offsetX);
      setClickCount(clickCount + 1);
      let temp = await dispatch(getChannelValuesAverage({ val1: 5, val2: 2 }))
      setAverage(temp)
    } else {
      setClickCount(0);
    }
  };

  useEffect(() => {
    if (chartClick.length === 1 || (chartClick.length - 1) % 3 === 0) {
      setFirstValue(value);
      console.log(chartClick);
    } else if (chartClick.length === 2 || (chartClick.length - 2) % 3 === 0) {
      setSecondValue(value);
      console.log(value);
    }

    if (chartClick.length === 2) {

      chartClick?.[0]?.map((item) => { });
      setChartClick([]);
      console.log("chartClick", chartClick);
      let temparr = [];
      chartClick?.[0]?.map((cc, i) => {
        let obj = {
          name: cc?.name,
          interval: cc?.date + " - " + chartClick?.[1]?.[i]?.date,
          value: Math.abs(cc?.value - chartClick?.[1]?.[i]?.value),
        };
        temparr.push(obj);
      });
    }
  }, [chartClick]);



  useEffect(() => {
    setData([]);
    setColor([]);
    channel?.length > 0 &&
      channel?.map((item, index) => {
        setColor((color) => [...color, item?.color]);

        item?.data?.length > 0 &&
          item?.data?.map((kw) => {
            setData((data) => [
              ...data,
              {
                name: item?.value?.name,
                date:
                  time === "5m" || time === "15m"
                    ? moment(kw?._time)
                      .format("YYYY-MM-DD(HH:mm)")
                    : time === "hourly"
                      ? moment(kw?._time)
                        .format("YYYY-MM-DD(HH:00)")
                      : time === "daily"
                        ? moment(kw?._time)
                          .format("YYYY-MM-DD")
                        : time === "weekly"
                          ? moment(kw?._time)
                            .format("YYYY-MM-DD")
                          : time === "monthly"
                            ? moment(kw?._time)
                              .format("DD-MMM-YYYY")
                            : time === "yearly"
                              ? moment(kw?._time)
                                .format("YYYY")
                              : null,
                value: kw?._value,
                interval: kw?.interval,
              },
            ]);
          });
      });
  }, [channel, time]);

  const config = {
    data: data,
    smooth: true,
    className: "comp_chart",
    xField: "date",
    yField: "value",
    brush: {
      enabled: true,
      action: 'highlight',
    },
    seriesField: "name",
    color: color,
    slider: {
      start: 0,
      end: 1,
    },
    xaXis: {
      tickCount: 13,
    },
    tooltip: {
      customContent: (title, data) => {
        return null;
      },
    },
    tooltip: {
      domStyles: {
        "g2-tooltip": {
          background: "transparent",
          minWidth: "250px",
          maxWidth: "250px",
          borderRadius: 0,
          padding: "0",
        },
      },
      customContent: (title, data) => {
        if (data) {
          return (
            <>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  color: "rgb(89, 89, 89)",
                  backgroundColor: "white",
                  fontFamily: "Poppins",
                  borderRadius: "4px",
                  padding: "0 20px",
                  fontSize: "10px",
                  paddingTop: "10px",
                  paddingBottom: "10px",
                  filter: "drop-shadow(-1px 6px 3px rgba(50, 50, 0, 0.5))",
                  position: "relative",
                  minHeight: "auto",
                  minWidth: "300px",
                }}
              >
                <div className="tooltip-polygon" />

                <p
                  style={{
                    fontWeight: "bold",
                    fontSize: "13px",
                    color: "black",
                  }}
                >
                  {time === "hourly" || time === "15m" || time === "5m" ? (
                    <>
                      {data?.[0]?.data?.interval?.date + " "}
                      <ClockCircleFilled
                        style={{
                          marginLeft: "10px",
                          marginRight: "3px",
                        }}
                      />
                      {data?.[0]?.data?.interval?.time}
                    </>
                  ) : (
                    data?.[0]?.data?.interval
                  )}
                </p>
                {data?.map((itm) => (
                  <>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        margin: "2px 0",
                      }}
                    >
                      <p
                        style={{
                          background: itm?.color,
                          width: 10,
                          height: 10,
                          borderRadius: "50%",
                          marginBottom: 0,
                          marginRight: 10,
                        }}
                      />
                      <p
                        style={{
                          marginBottom: 0,
                          width: "120px",
                          fontSize: "12px",
                          fontWeight: "500",
                          color: "black",
                        }}
                      >
                        {itm?.name}
                      </p>
                      <p
                        style={{
                          color: "black",
                          fontSize: "11px",
                          marginBottom: 0,
                          fontWeight: "500",
                        }}
                      >
                        {itm?.value?.toLocaleString("de-DE")}{" "}
                        {unit ? " " + unit : " kW"}
                      </p>
                    </div>
                  </>
                ))}
              </div>
            </>
          );
        }
      },
    },
    yAxis: {
      label: {
        formatter: (v) =>
          `${v}`.replace(/\d{1,3}(?=(\d{3})+$)/g, (s) => `${s},`),
      },
    },
    legend: false,
  };

  return (
    <div ref={divRef} style={{ position: "relative" }} onMouseDown={handleMouseDown} >
      {clickCount === 2 && (
        <div
          style={{
            position: 'absolute',
            left: startX,
            bottom: 60,
            width: endX - startX,
            height: '80%',
            backgroundColor: 'red',
            opacity: "0.1"
          }}
        />
      )}
      <div>Avergae: {average}</div>

      {displaTooltip && (
        <div
          style={{
            filter: "drop-shadow(-1px 6px 3px rgba(50, 50, 0, 0.5))",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            flex: 1,
            height: 65,
            width: 240,
            boxShadow: "rgb(174 174 174) 0px 0px 10px",
            transition:
              "left 0.4s cubic-bezier(0.23, 1, 0.32, 1) 0s, top 0.4s cubic-bezier(0.23, 1, 0.32, 1) 0s",
            color: "rgb(89, 89, 89)",
            backgroundColor: "red",
            fontFamily: "Poppins",
            left: clientx - 100,
            top: clienty - 100,
            position: "fixed",
            borderRadius: "4px",
            padding: "0 20px",
            fontSize: "10px",
            paddingTop: "5px",
            zIndex: 9,
          }}
        >
          <p>tooltip</p>
        </div>
      )}

      <Line
        {...config}
        autoFit
        onReady={(plot) => {
          plot.on("plot:click", (event) => {
            let a = event.view.ele.textContent;
            let date = a.slice(0, 10);
            let time = a.slice(11, 24);
            let filteredData = event?.view?.filteredData;

            let findData = filteredData?.filter((obj) => {
              return (
                obj?.interval?.date === date && obj?.interval?.time === time
              );
            });

            setValue(findData[0]?.value)

            setChartClick((cc) => [...cc, findData]);
          });
        }}
      />

    </div>
  );
};
export default CompareChart;
