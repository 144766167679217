/* eslint-disable no-unused-vars */
import React, { useState, useContext, useRef, useEffect } from 'react';
import { Table, Input, Button, Form, Modal } from 'antd';
import { SaveOutlined } from '@ant-design/icons';
import Layout from "../../adminLayout/Layout";

import "../../Styles/pages/servicesDashboard.css";
import { getLanguages, updateLanguage } from '../../redux/actions/languagesActions';
import CreateLanguage from '../../Components/admin/CreateLanguage';
const EditableContext = React.createContext();

const EditableRow = ({ index, ...props }) => {
    const [form] = Form.useForm();
    return (
        <Form form={form} component={false}>
            <EditableContext.Provider value={form}>
                <tr {...props} />
            </EditableContext.Provider>
        </Form>
    );
};

const EditableCell = ({
    title,
    editable,
    children,
    dataIndex,
    record,
    handleSave,
    ...restProps
}) => {
    const [editing, setEditing] = useState(false);
    const inputRef = useRef();
    const form = useContext(EditableContext);
    useEffect(() => {
        if (editing) {
            inputRef.current.focus();
        }
    }, [editing]);

    const toggleEdit = () => {
        setEditing(!editing);
        form.setFieldsValue({
            [dataIndex]: record[dataIndex],
        });
    };

    const save = async () => {
        try {
            const values = await form.validateFields();

            toggleEdit();
            handleSave({ ...record, ...values });
        } catch (errInfo) {
            console.log('Save failed:', errInfo);
        }
    };

    let childNode = children;
    if (editable) {
        childNode = editing ? (
            <Form.Item
                style={{
                    margin: 0,
                }}
                name={dataIndex}
            >
                <Input ref={inputRef} onPressEnter={save}
                    onBlur={save}

                />
            </Form.Item>
        ) : (
            <div
                className="editable-cell-value-wrap"
                style={{
                    paddingRight: 24,
                }}
                onClick={toggleEdit}
            >
                {children}
            </div>
        );
    }

    return <td {...restProps}>{childNode}</td>;
};

const AdminLanguage = () => {
    const [dataSource, setDataSource] = useState([]);
    const [editingKey, setEditingKey] = useState("");
    const [data, setData] = useState();
    const [enable, setEnable] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [searchText, setSearchText] = useState('');

    // Function to handle search input changes
    const handleSearch = (e) => {
        const { value } = e.target;
        setSearchText(value);
        if (value !== '') {
            const filtered = data.filter(item =>
                item.key.toLowerCase().includes(value.toLowerCase())
            );

            setDataSource(filtered);
        } else {
            setDataSource(data);
        }

    };

    const getData = async () => {
        const results = await getLanguages();
        const formattedResults = results?.map(result => ({
            ...result,
            key: result.key || '(empty)',
            en: result.en || '(empty)',
            de: result.de || '(empty)',
            it: result.it || '(empty)'
        }));
        setDataSource(formattedResults);
        setData(formattedResults)
    }

    useEffect(() => {
        getData();
    }, [])

    const isEditing = (record) => record._id === editingKey._id;

    const handleSave = async (row) => {
        const newData = [...dataSource];
        const index = newData.findIndex((item) => row._id === item._id);
        const item = newData[index];
        newData.splice(index, 1, { ...item, ...row });
        setDataSource(newData);

        await updateLanguage({ ...item, ...row })
        await getData();
        setEditingKey("");
    };

    const components = {
        body: {
            row: EditableRow,
            cell: EditableCell,
        },
    };

    const columns = [
        {
            title: "Key",
            dataIndex: "key",
            key: "key",
        },
        {
            title: "English",
            dataIndex: "en",
            key: "en",
            editable: true,
        },
        {
            title: "German",
            dataIndex: "de",
            key: "de",
            editable: true,
        },
        {
            title: "Italian",
            dataIndex: "it",
            key: "it",
            editable: true,
        },
        {
            title: 'Operation',
            dataIndex: 'operation',
            render: (_, record) => {
                const editable = isEditing(record);
                return editable ? (
                    <span>
                        <Button icon={<SaveOutlined />} onClick={() => { handleSave(record); setEditingKey(''); setEnable(false) }}>
                            Save
                        </Button>
                    </span>
                ) : (
                    <Button type="link" disabled={editingKey !== ""} onClick={() => { setEditingKey(record); setEnable(true) }}>
                        Edit
                    </Button>
                );
            },
        },
    ].map((col) => {
        if (!col.editable) {
            return col;
        }

        return {
            ...col,
            onCell: (record) => ({
                record,
                editable: isEditing(record),
                dataIndex: col.dataIndex,
                title: col.title,
                handleSave: handleSave,
            }),
        };
    });

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        getData();
        setIsModalOpen(false);
    };

    return (
        <Layout active="language-manager">
            <Modal title="Create Language" open={isModalOpen} onOk={handleOk} onCancel={handleCancel} footer={false} >
                <CreateLanguage handleClose={handleCancel} />
            </Modal>
            <div className="home-main-admin-language">
                <div style={{ display: 'flex', gap: '10px' }}>
                    <Input
                        placeholder="Search by column key"
                        value={searchText}
                        onChange={handleSearch}
                        style={{ marginBottom: 16 }}
                    />
                    <Button onClick={showModal}>Create Language</Button>
                </div>
                <Table
                    components={components}
                    rowClassName={() => 'editable-row'}
                    bordered
                    dataSource={dataSource}
                    columns={columns}
                />
            </div>
        </Layout>
    );
};

export default AdminLanguage;

