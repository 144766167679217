/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Area } from "@ant-design/plots";
import { useSelector } from "react-redux";

const Stacked = ({ Data, filter }) => {
  const colors = useSelector((state) => state.userReducer.colors);

  const [combination, setCombination] = useState([]);
  let channels = [
    "Spa",
    "E-Mobility",
    "Restaurant",
    "Laundry",
    "Rooms",
    "Pool",
  ];

  useEffect(() => {
    let combination = [];
    colors?.map((color, ind) => {
      combination.push({
        place: channels[ind],
        color,
      });
    });
    setCombination(combination);
  }, [colors]);

  const hexToRgb = (hex) =>
    hex
      .replace(
        /^#?([a-f\d])([a-f\d])([a-f\d])$/i,
        (m, r, g, b) => "#" + r + r + g + g + b + b
      )
      .substring(1)
      .match(/.{2}/g)
      .map((x) => parseInt(x, 16));


  const config = {
    data: Data,
    height: 340,
    autoFit: true,
    xField: "date",
    yField: "value",
    seriesField: "place",
    color: colors,
    legend: false,
    tooltip: {
      domStyles: {
        "g2-tooltip": {
          background: "white",
          color: "black",
          opacity: 1,
          borderRadius: "8px",
        },
      },
      customContent: (title, data, idx) => {

        return (
          <div
            style={{
              padding: "10px",
              minWidth: "160px",
              minHeight: "auto",
            }}
          >
            <h4
              style={{
                fontWeight: "700",
                fontSize: "15px",
                marginBottom: "15px",
                color: "black",
              }}
            >
              {data[0]?.data?.date}
            </h4>
            {data?.map((obj, index) => (
              <div
                key={index}
                style={{
                  display: "flex",
                  alignItems: "center",
                  height: "17px",
                  margin: "3px 0",
                }}
              >
                <p
                  style={{
                    background: obj.color,
                    width: 12,
                    height: 12,
                    borderRadius: "50%",
                    marginBottom: 0,
                    marginRight: 10,
                  }}
                ></p>
                <p
                  style={{
                    marginBottom: 0,
                    minWidth: "80px",
                    marginRight: "40px",
                    fontWeight: "400",
                    color: "black",
                    fontSize: "15px",
                  }}
                >
                  {obj.name}
                </p>
                <p
                  style={{
                    color: "black",
                    fontSize: "15px",
                    fontWeight: "500",
                    marginBottom: 0,
                  }}
                >
                  {obj.value.toLocaleString("de-DE")}
                </p>
              </div>
            ))}
            <div
              style={{
                display: "flex",
                alignItems: "center",
                height: "17px",
                marginTop: "10px",
              }}
            >
              <p
                style={{
                  background: "black",
                  width: 12,
                  height: 12,
                  borderRadius: "50%",
                  marginBottom: 0,
                  marginRight: 10,
                }}
              ></p>
              <p
                style={{
                  fontWeight: "700",
                  fontSize: "15px",
                  marginBottom: "0",
                  marginRight: "40px",
                  minWidth: "80px",
                  width: "60px",
                  color: "black",
                }}
              >
                Total
              </p>
              <p
                style={{
                  color: "black",
                  fontSize: "15px",
                  fontWeight: "600",
                  marginBottom: 0,
                }}
              >
                {data
                  .reduce(function (previousValue, currentValue) {
                    return previousValue + Number(currentValue.value);
                  }, 0)
                  .toLocaleString("de-DE")}
              </p>
            </div>
          </div>
        );
      },
    },
    areaStyle: (value) => {
      let place = combination.find((obj) => {
        return obj.place === value.place;
      });
      if (place) {
        let color = hexToRgb(place?.color);
        return {
          fill: `l(270) 0:rgba(${color[0]},${color[1]},${color[2]},1) 1:rgba(${color[0]},${color[1]},${color[2]},1) 1:rgba(${color[0]},${color[1]},${color[2]},1)`,
        };
      }
    },
    isStack: true,
    columnBackground: {
      style: {
        fill: "rgba(0,0,0,0.1)",
      },
    },
    xAxis: {
      line: null,
      grid: {
        line: {
          style: {
            stroke: "black",
            lineWidth: 0.1,
          },
        },
        alignTick: true,
        closed: true,
      },
    },
    yAxis: {
      title: {
        text: "kWh",
        style: {
          stroke: "#000000",
          fontSize: 13,
          color: "#000000",
          letterSpacing: "1px",
          fontWeight: 300,
          opacity: 1,
          shadowBlur: 0,
          strokeOpacity: 0,
        },
      },
      grid: {
        line: {
          style: {
            stroke: "black",
            lineWidth: 0,
          },
        },
        alignTick: true,
        closed: true,
      },
    },
  };

  return <Area {...config} animation={false} x />;
};

export default Stacked;
