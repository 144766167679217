/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { Modal } from "antd";
import LiveElectricConsumptionModal from "./LiveElectricConsumptionModal";
import { getDataChannels } from "../../redux";
import { useDispatch } from "react-redux";

const ElectricConsumptionModal = ({
  name,
  code,
  index,
  isModalOpen,
  handleOk,
  trigger,
  handleCancel,
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    console.log("code", code)
    dispatch(getDataChannels({ email: localStorage.getItem("enexpertUserEmail"), code: code }))

  }, [])

  return (
    <div>
      <Modal
        footer={false}
        width={1200}
        centered
        mask={false}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        maskClosable={true}
        closeIcon={<></>}
        style={{ marginLeft: "200px" }}
      >
        <LiveElectricConsumptionModal
          name={name}
          code={code}
          trigger={trigger}
          index={index}
        />
      </Modal>
    </div>
  );
};
export default ElectricConsumptionModal;
