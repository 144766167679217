/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Row, Col, Typography, Dropdown, Menu, Space } from "antd";
import { DownOutlined } from "@ant-design/icons";
import Stacked from "../charts/Stacked";
import ElectricConsumptionRoundChart from "../ElectricConsumptionRoundChart";
import {
  getElectricConsumptionGraph,
  createLog,
  getRoundChartData,
} from "../../redux";
import { useDispatch } from "react-redux";
import { SET_DISPLAY_ANNOTATION } from "../../redux/types/generalTypes";
import Loader from "../Loader";
import { useTranslation } from "react-i18next";


const TotalElectricConsumption = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [electricConsumption, setElectricConsumption] = useState(null);
  const [roundConsumption, setRoundConsumption] = useState(null);
  const [Filter, setFilter] = useState({
    value: t('last_30_days'),
    key: "30d",
  });
  const getData = async () => {
    const result = await getElectricConsumptionGraph({ filter: Filter.key });
    setElectricConsumption(result);
    const roundResult = await getRoundChartData({ filter: Filter.key });
    setRoundConsumption(roundResult);
  };


  useEffect(() => {
    getData();
  }, [Filter]);

  return (
    <div
      className="stacked-pie-outer"
      onMouseOver={(e) => {
        dispatch({ type: SET_DISPLAY_ANNOTATION, payload: false });
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "1.5rem",
        }}
      >
        <Typography.Title
          className="title"
          level={5}
          style={{ fontSize: "18px", fontWeight: 600 }}
        >
          {t("total_electric_consumption")}
        </Typography.Title>
        <Dropdown
          overlay={
            <Menu
              onClick={(e) => {
                setElectricConsumption(null)
                setFilter({
                  value: e.domEvent.target.innerText,
                  key: e.key,
                });

                createLog({
                  page: "home",
                  section: "total electric consumption",
                  filter: e.key,
                  description:
                    "Change filter to " + e.domEvent.target.innerText,
                });
              }}
            >
              <Menu.Item key={"7d"} value={t("last_7_days")}>
                {t("last_7_days")}
              </Menu.Item>
              <Menu.Item key={"30d"} value={t("last_30_days")}>
                {t("last_30_days")}
              </Menu.Item>
              <Menu.Item key={"12m"} value={t("last_12_months")}>
                {t("last_12_months")}
              </Menu.Item>
            </Menu>
          }
          trigger={["click"]}
        >
          <div onClick={(e) => e.preventDefault()}>
            <Space className="stacked-pie-outer-dropdown">
              {t(Filter.value)}
              <DownOutlined />
            </Space>
          </div>
        </Dropdown>
      </div>

      <Row gutter={[0, 30]}>
        <Col
          sm={24}
          md={24}
          lg={12}
          xl={16}
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <div
            style={{
              width: "100%",
            }}
          >
            {electricConsumption ?
              <Stacked
                Data={electricConsumption ? electricConsumption?.stackChart : []}
                filter={Filter.key}
              /> : <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <Loader />
              </div>}
          </div>
        </Col>
        <Col
          sm={24}
          md={24}
          lg={12}
          xl={8}
          style={{
            width: "100%",
            position: "relative",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <ElectricConsumptionRoundChart
            electricConsumption={roundConsumption}
            filter={Filter.key}
          />
        </Col>
      </Row>
    </div>
  );
};

export default TotalElectricConsumption;
