import { Alert, Button, Input } from 'antd'
import React from 'react'
import { useState } from 'react'
import { createSubNode } from '../../redux';


const CreateSubnode = ({ handleClose }) => {
    const [alert, setAlert] = useState({ type: '', message: '' });
    const [data, setData] = useState({
        name: '',
        code: '',
        Type: ''
    });

    const handleCreate = async () => {
        if (data.name !== '' && data.code !== '' && data.Type !== '') {
            await createSubNode(data);
            handleClose();
        } else {
            setAlert({ type: 'danger', message: 'Fill All Field!' });
        }
    }

    return (
        <>
            <Alert
                message={alert.message}
                type={alert.type}
                closable
            />
            <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
                <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                    <label style={{ width: '50px' }}>Name: </label>
                    <Input style={{ flex: '1' }} value={data?.name} onChange={(e) => setData({ ...data, name: e.target.value })} />
                </div>
                <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                    <label style={{ width: '50px' }}>Code: </label>
                    <Input style={{ flex: '1' }} value={data?.code} onChange={(e) => setData({ ...data, code: e.target.value })} />
                </div>
                <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                    <label style={{ width: '50px' }}>Type: </label>
                    <Input style={{ flex: '1' }} value={data?.Type} onChange={(e) => setData({ ...data, Type: e.target.value })} />
                </div>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Button style={{ background: 'grey', color: 'white', width: '200px' }} onClick={handleCreate}>Create Subnode</Button>
                </div>

            </div>
        </>


    )
}

export default CreateSubnode