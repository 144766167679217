import { privateAPI, attachToken } from "../../API";

export const getActualCo2Emission = async (payload) => {
  try {
    attachToken();
    const res = await privateAPI.post(
      `/c02Emission/getActualCo2Emission`,
      payload
    );
    if (res) {
      return res?.data;
    }
    //   }
  } catch (err) {
    console.log("err", err);
  }
};

export const getCountryCo2Emission = async (payload) => {
  try {
    attachToken();
    const res = await privateAPI.post(
      `/c02Emission/getCountryCo2Emission`,
      payload
    );
    if (res) {
      return res?.data;
    }
  } catch (err) {
    console.log("err", err);
  }
};

export const getAverageConsumption = async (payload) => {
  try {
    attachToken();
    const res = await privateAPI.post(
      `/c02Emission/getAverageConsumption`,
      payload
    );
    if (res) {
      return res?.data;
    }
  } catch (err) {
    console.log("err", err);
  }
};

export const getCo2Saved = async (payload) => {
  try {
    attachToken();
    const res = await privateAPI.post(`/c02Emission/getCo2Saved`, payload);
    if (res) {
      return res?.data;
    }
  } catch (err) {
    console.log("err", err);
  }
};
export const getCo2EmissionConsumption = async (payload) => {
  try {
    attachToken();
    const res = await privateAPI.post(`/c02Emission/getco2Consumption`, payload);
    if (res) {
      return res;
    }
  } catch (err) {
    console.log("err", err);
  }
};
