import React, { useState, useContext, useRef, useEffect } from 'react';
import { Table, Input, Button, Form, Modal } from 'antd';
import { SaveOutlined } from '@ant-design/icons';
import Layout from "../../adminLayout/Layout";
import "../../Styles/pages/servicesDashboard.css";
import { getAllSubnodes, updateSubNode } from '../../redux';
import CreateSubnode from '../../Components/admin/CreateSubnode';
const EditableContext = React.createContext();

const EditableRow = ({ index, ...props }) => {
    const [form] = Form.useForm();
    return (
        <Form form={form} component={false}>
            <EditableContext.Provider value={form}>
                <tr {...props} />
            </EditableContext.Provider>
        </Form>
    );
};

const EditableCell = ({
    title,
    editable,
    children,
    dataIndex,
    record,
    handleSave,
    ...restProps
}) => {
    const [editing, setEditing] = useState(false);
    const inputRef = useRef();
    const form = useContext(EditableContext);

    useEffect(() => {
        if (editing) {
            inputRef.current.focus();
        }
    }, [editing]);

    const toggleEdit = () => {
        setEditing(!editing);
        form.setFieldsValue({
            [dataIndex]: record[dataIndex],
        });
    };

    const save = async () => {
        try {
            const values = await form.validateFields();

            toggleEdit();
            handleSave({ ...record, ...values });
        } catch (errInfo) {
            console.log('Save failed:', errInfo);
        }
    };

    let childNode = children;
    if (editable) {
        childNode = editing ? (
            <Form.Item
                style={{
                    margin: 0,
                }}
                name={dataIndex}
            >
                <Input ref={inputRef} onPressEnter={save} onBlur={save} />
            </Form.Item>
        ) : (
            <div
                className="editable-cell-value-wrap"
                style={{
                    paddingRight: 24,
                }}
                onClick={toggleEdit}
            >
                {children}
            </div>
        );
    }

    return <td {...restProps}>{childNode}</td>;
};

const AdminSubnodes = () => {
    const [dataSource, setDataSource] = useState([]);
    const [editingKey, setEditingKey] = useState("");
    const [enable, setEnable] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {
        getData();
    }, [])

    const getData = async () => {
        const results = await getAllSubnodes();
        const formattedResults = results?.map(result => ({
            ...result,
            name: result.name || '(empty)',
            code: result.code || '(empty)',
            Type: result.Type || '(empty)',
        }));
        setDataSource(formattedResults);
    }

    const isEditing = (record) => record._id === editingKey._id;

    const handleSave = async (row) => {
        const newData = [...dataSource];
        const index = newData.findIndex((item) => row._id === item._id);
        const item = newData[index];
        newData.splice(index, 1, { ...item, ...row });
        setDataSource(newData);
        await updateSubNode({ ...item, ...row })
        await getData();
    };

    const components = {
        body: {
            row: EditableRow,
            cell: EditableCell,
        },
    };

    const columns = [

        {
            title: 'Code',
            dataIndex: 'code',
            width: '30%',
            // editable: true,
        },
        {
            title: 'Name',
            dataIndex: 'name',
            editable: enable,
        },
        {
            title: 'Type',
            dataIndex: 'Type',
            editable: enable,
        },
        {
            title: 'Operation',
            dataIndex: 'operation',
            render: (_, record) => {
                const editable = isEditing(record);
                return editable ? (
                    <span>
                        <Button icon={<SaveOutlined />} onClick={() => { handleSave(record); setEditingKey(''); setEnable(false) }}>
                            Save
                        </Button>
                    </span>
                ) : (
                    <Button type="link" disabled={editingKey !== ""} onClick={() => { setEditingKey(record); setEnable(true) }}>
                        Edit
                    </Button>
                );
            },
        },
    ].map((col) => {
        if (!col.editable) {
            return col;
        }

        return {
            ...col,
            onCell: (record) => ({
                record,
                editable: isEditing(record),
                dataIndex: col.dataIndex,
                title: col.title,
                handleSave: handleSave,
            }),
        };
    });
    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        getData();
        setIsModalOpen(false);
    };
    return (
        <Layout active="admin-subnodes">
            <div className="home-main-admin-language">
                <Modal title="Create Subnode" open={isModalOpen} onOk={handleOk} onCancel={handleCancel} footer={false} >
                    <CreateSubnode handleClose={handleCancel} />
                </Modal>
                <Button onClick={showModal}>Create Subnode</Button>
                <Table
                    components={components}
                    rowClassName={() => 'editable-row'}
                    bordered
                    dataSource={dataSource}
                    columns={columns}
                />
            </div>
        </Layout>
    );
};

export default AdminSubnodes;

