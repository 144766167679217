import React from "react";
import { Line } from "@ant-design/plots";

const DigiChart = ({ chartData }) => {
  console.log("data ", chartData);
  const config = {
    data: chartData,
    smooth: true,
    height: 400,
    xField: "time",
    yField: "_value",
    seriesField: "date",
    legend: false,
    color: (data) => {
      return data.date === "Average" ? "blue" : "grey";
    },
    lineStyle: (data) => {
      return data.date === "Average" ? { lineWidth: 3 } : { lineWidth: 0.5 };
    },
    yAxis: {
      label: {
        formatter: (v) =>
          `${v}`.replace(/\d{1,3}(?=(\d{3})+$)/g, (s) => `${s},`),
      },
    },


    tooltip: {
      title: (datum, data) => {
        return datum;
      },
      formatter: (datum) => {
        return { name: datum.date, value: datum._value + " kW" };
      },
    },

    interactions: [
      {
        type: "element-highlight",
      },
    ],
  };
  return (
    <Line {...config} autoFit animation={false} style={{ marginTop: "10px" }} />
  );
};
export default DigiChart;
