import React from "react";
import { Gauge } from "@ant-design/plots";

const DemoGauge = ({ width = "inherit", height = "inherit" }) => {
  const config = {
    percent: 0.1,
    radius: 0.8,
    range: {
      width: 10,
      color: ["#D9D9D9", "#07A0E0"],
    },
    gaugeStyle: {
      lineCap: "round",
    },
    width: 200,
    height: 200,
    startAngle: Math.PI,
    endAngle: 2 * Math.PI,
    indicator: null,
  };
  return <Gauge {...config} />;
};

export default DemoGauge;
