/* eslint-disable jsx-a11y/alt-text */
import { useState } from "react";
import { Button, Form, Input, notification } from "antd";
import { useHistory, Link } from "react-router-dom";
import { clientSignup, setDynamic, setSidebar } from "../redux";
import logo from "../Assets/logo2.png";

const Login = () => {
  const history = useHistory();

  const [loading, setLoading] = useState(false);

  const Login = async (values) => {
    if (values.password === values.Cpassword) {
      setLoading(true);
      let temp = await clientSignup({ ...values, language: 'de' });
      setLoading(false);
      if (temp) {
        await setDynamic({ id: temp?._id });
        await setSidebar({ id: temp?._id });
        history.push('/')
      }
    } else {
      notification.error({
        message: "Password and Confirm Password Doesnot Matched.",
        duration: 3,
      });
    }
  };

  if (localStorage.hasOwnProperty("token")) {
    localStorage.clear();
  }

  return (
    <div className="temp-login-main signup-main">
      <div className="login-main">
        <img src={logo} />
        <p>Fill below fields to create your account</p>

        <Form layout="vertical" onFinish={Login}>
          <Form.Item name={"userName"} label={"Name"}>
            <Input />
          </Form.Item>
          <Form.Item name={"email"} label={"Email Address"}>
            <Input />
          </Form.Item>
          <Form.Item name={"password"} label={"Password"}>
            <Input type="password" />
          </Form.Item>
          <Form.Item name={"Cpassword"} label={"Confirm Password"}>
            <Input type="password" />
          </Form.Item>
          <Form.Item>
            <Button type="primary" loading={loading} htmlType="submit">
              Submit
            </Button>
          </Form.Item>
          <div className="signup">
            Already have account?{" "}
            <Link to="/login">
              <span>Login</span>
            </Link>
          </div>
        </Form>
      </div>
    </div>
  );

};

export default Login;
