import { SET_CONTROL_NODES, SET_DATA_CHANNELS } from "../types/homeTypes";

const initialState = {
    controlNodes: [],
    dataChannels: [],
};

const homeReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case SET_CONTROL_NODES:
            return {
                ...state,
                controlNodes: payload,
            };
        case SET_DATA_CHANNELS:
            return {
                ...state,
                dataChannels: payload,
            };
        default:
            return state;
    }
};

export default homeReducer;
