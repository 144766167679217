/* eslint-disable react-hooks/exhaustive-deps */
import { Switch } from 'antd';
import React, { useEffect } from 'react'
import { useState } from 'react';

const AdminSwitch = ({ value, handleSwitchChange }) => {
    const [switchValue, setSwitchValue] = useState(value);

    useEffect(() => {
        setSwitchValue(value);
    }, [value])

    const handleChange = (e) => {
        setSwitchValue(e);
        handleSwitchChange(e)
    }

    return (
        <div>
            <Switch checked={switchValue} onChange={handleChange} />
        </div>
    )
}

export default AdminSwitch